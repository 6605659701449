import VisioCaroussel from "./visio-caroussel"
import VisioPicture from "./visio-picture"

const Components = {
    picture:VisioPicture,
    caroussel:VisioCaroussel
}

export default function VisioMedia({type,...props}):JSX.Element | null{
let Component = Components[type];
  
if(Component!==undefined){
        return <Component {...props} />
    }
    return null 
}