import { PGL_PROFILE } from '../../actions/pgl/types'

const profileReducer = (state = [], action) => {
  switch (action.type) {
    case PGL_PROFILE:
      if (action.payload) {
        return action.payload
      } else {
        return state
      }
    default:
      return state
  }
}

export default profileReducer
