import React from 'react';

export const Cse = (props) => {
  return (
<svg version="1.1" id="Calque_1"  x="0px" y="0px"
	 viewBox="0 0 25.6 17.9">
<g id="Groupe_1441" transform="translate(-128.949 -772.264)">
	<g id="Groupe_1440" transform="translate(128.949 772.264)">
		<g id="Groupe_1437">
			<g id="Groupe_1436">
				<path id="Tracé_1849" d="M25.6,9.9c0-0.7-0.7-1.3-2.1-1.7c-1.2-0.3-2.5-0.5-3.8-0.5V2.3c0-0.1,0-0.2-0.1-0.3
					c-0.1-0.6-0.8-1.1-2-1.5C16.2,0.2,14.8,0,13.4,0C12,0,10.6,0.2,9.2,0.5c-0.9,0.3-2,0.8-2,1.7c0,0.1,0,0.1,0,0.2l0.1,2.3
					C3.9,4.1,0.7,6.3,0.1,9.6c-0.6,3.3,1.5,6.5,4.8,7.1c1.8,0.3,3.6-0.1,5-1.3c1.1,0.2,2.2,0.4,3.3,0.4c0.3,1.4,3.3,2.1,6.2,2.1
					c1.4,0,2.8-0.2,4.2-0.5c0.9-0.3,2-0.8,2-1.7L25.6,9.9C25.6,10,25.6,9.9,25.6,9.9z M18.6,5.1c0,0.1-0.2,0.4-1.3,0.8
					c-1.3,0.3-2.6,0.5-3.9,0.5C12.2,6.3,11,6.2,9.9,6c0,0,0,0,0,0C9.4,5.6,8.8,5.3,8.3,5l0-1.4C8.6,3.8,8.9,3.9,9.3,4
					c1.3,0.3,2.7,0.5,4,0.5c1.4,0,2.8-0.2,4.2-0.5c0.4-0.1,0.8-0.3,1.1-0.4v1.3C18.6,4.9,18.6,5,18.6,5.1L18.6,5.1z M9.5,1.5
					C10.8,1.2,12.1,1,13.4,1c1.3,0,2.6,0.1,3.9,0.5c1.1,0.3,1.3,0.7,1.3,0.7S18.4,2.7,17.3,3c-1.3,0.3-2.6,0.5-3.9,0.5
					c-1.3,0-2.5-0.1-3.8-0.5C8.5,2.7,8.2,2.4,8.2,2.3c0,0,0,0,0,0C8.2,2.2,8.4,1.8,9.5,1.5z M9.5,14.5c-2.1,1.9-5.3,1.7-7.2-0.3
					S0.6,8.9,2.7,7c0.9-0.9,2.2-1.3,3.4-1.3c0.5,0,1,0.1,1.5,0.2c1.8,0.6,3.1,2,3.5,3.8c0.1,0.3,0.1,0.7,0.1,1
					C11.2,12.2,10.6,13.5,9.5,14.5L9.5,14.5z M10.8,14.6c0.4-0.5,0.7-1.1,1-1.7c0.4,0,0.9,0.1,1.3,0.1v0.1l0,1.7
					C12.4,14.8,11.6,14.7,10.8,14.6z M12.2,10.1c0.3,0,0.6,0,0.9,0l0,1.8c-0.4,0-0.7,0-1,0c0.1-0.4,0.1-0.8,0.1-1.2
					C12.2,10.5,12.2,10.3,12.2,10.1L12.2,10.1z M13.4,9.2L13.4,9.2c-0.5,0-1,0-1.5-0.1c-0.2-0.7-0.5-1.3-0.9-1.9
					c0.8,0.1,1.5,0.1,2.3,0.1c1.4,0,2.8-0.2,4.2-0.5c0.4-0.1,0.7-0.3,1.1-0.4v1.3C16.5,7.8,14.3,8.2,13.4,9.2L13.4,9.2z M14.1,10
					C14.1,10,14.1,9.9,14.1,10c0.1-0.2,0.4-0.5,1.5-0.8c1.2-0.3,2.4-0.4,3.6-0.4h0.1c1.3,0,2.6,0.1,3.9,0.5c1.1,0.3,1.3,0.7,1.3,0.7
					v0c0,0.1-0.3,0.4-1.4,0.8c-1.3,0.3-2.6,0.5-3.9,0.5c-1.3,0-2.5-0.1-3.8-0.5C14.4,10.4,14.1,10.1,14.1,10L14.1,10z M23.2,16.4
					c-1.3,0.4-2.6,0.5-3.9,0.5c-1.3,0-2.6-0.1-3.9-0.5c-1.1-0.3-1.3-0.7-1.3-0.7v0l0-1.5c0.4,0.2,0.8,0.4,1.3,0.5
					c1.3,0.3,2.5,0.4,3.8,0.4c1.4,0,2.8-0.2,4.2-0.5c0.4-0.1,0.7-0.3,1.1-0.4v1.5h0C24.5,15.7,24.3,16,23.2,16.4L23.2,16.4z
					 M24.6,12.6L24.6,12.6c0,0.1,0,0.1,0,0.2c0,0.1-0.2,0.4-1.3,0.8c-1.3,0.3-2.6,0.5-3.9,0.5c-2.8,0-4.7-0.6-5.1-1.1l0-0.4l0-1.2
					c0.4,0.2,0.7,0.3,1.1,0.4c1.3,0.3,2.7,0.5,4,0.5c1.4,0,2.8-0.2,4.2-0.5c0.4-0.1,0.8-0.3,1.1-0.4L24.6,12.6z"/>
			</g>
		</g>
		<g class="st0">
			<path d="M3.2,10.2c0.1-0.3,0.2-0.6,0.3-1S4,8.7,4.2,8.4C4.5,8.2,4.8,8,5.1,7.9c0.3-0.1,0.7-0.2,1.1-0.2c0.6,0,1.1,0.1,1.5,0.4
				s0.7,0.6,0.9,1L7.9,9.6C7.8,9.4,7.7,9.2,7.6,9.1C7.5,9,7.3,8.9,7.2,8.8S6.9,8.6,6.7,8.6c-0.2,0-0.3,0-0.5,0c-0.3,0-0.5,0-0.7,0.1
				S5.1,8.9,4.9,9.1C4.8,9.2,4.6,9.4,4.5,9.6C4.4,9.8,4.3,10,4.3,10.2h2.6l-0.2,0.6H4.2v0.1c0,0.1,0,0.3,0,0.4h2.3l-0.2,0.6h-2
				c0.1,0.2,0.2,0.4,0.3,0.6s0.3,0.3,0.4,0.4s0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.3,0,0.5-0.1s0.3-0.1,0.5-0.2
				c0.2-0.1,0.3-0.2,0.4-0.3s0.2-0.3,0.3-0.5l0.8,0.5c-0.1,0.2-0.2,0.5-0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.5C7.5,13.9,7.2,14,7,14
				c-0.3,0.1-0.5,0.1-0.8,0.1c-0.4,0-0.7-0.1-1-0.2c-0.3-0.1-0.6-0.3-0.8-0.5c-0.3-0.2-0.5-0.5-0.6-0.7c-0.2-0.3-0.3-0.6-0.4-0.9h-1
				l0.2-0.6h0.6c0-0.1,0-0.3,0-0.4v-0.1H2.5l0.2-0.6H3.2z"/>
		</g>
	</g>
</g>
</svg>
  );
};
