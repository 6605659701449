
type LogoProps ={
    type?:'WHITE' | 'COLOR';
    className:string
}

export default function Logo({type='COLOR',className=''}:LogoProps){


    if(type === 'WHITE'){
      return  <img className={className} src={require(`../../assets/logos/${process.env.REACT_APP_CLIENT_FOLDER}/logo-white.png`).default}/> 
    }else{
        return <img className={className} src={require(`../../assets/logos/${process.env.REACT_APP_CLIENT_FOLDER}/logo-color.png`).default}/> 
    }
}