import {
  SET_TOKEN,
  SIGN_IN,
  RESET_AUTH,
  GET_TEMPORARY_CODE,
  RESET_PASSWORD,
  RESET_AUTH_MESSAGE,
  SET_NEW_PASSWORD,
  ACCEPT_CGU,
  RESET_SIGNING_MESSAGE,
  SET_LOGIN_AUTH,
} from '../../actions/pci/types'
import update from 'immutability-helper'

const INITIAL_STATE = {
  authentication: {
    id:null,
    profil:null,
    nonce:null,
  },
  email: null,
  password: null,
  message: null,
  token: null,
  loading:true,
  type:null
}

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case 'NEW_USER_CONNECTED':
      return{
        ...state,
        message:null,
        loading:false,
        token:action.payload.token || 'no-notif',
        email:action.payload.data.email,
        type:action.payload.data.type,

        authentication:{
          id:action.payload.data.id,
          profil:action.payload.data.profil,
          nonce:action.payload.token || 'no-notif',
         
        },

      }

      case 'NEW_LOG_IN':
        return {
          ...state,
          authentication:{
            ...state.authentication,
            id:action.payload.data.id,
            profil:action.payload.data.profil
          },
          email:action.payload.email,
          password:action.payload.password,
          type:action.payload.data.type,
          loading:false
        }
      case 'NEW_LOG_IN_TEMP':
       return {
        ...state,
          authentication:{
            ...state.authentication,
            id:action.payload.data.id,
            profil:action.payload.data.profil,
            nonce:action.payload.token
          },
          email:action.payload.email,
          password:action.payload.password,
          type:action.payload.data.type,
          loading:false,
      }
    

    case SIGN_IN:
      return {
        ...state,
        authentication: action.payload.response,
        email: action.payload.email,
        password: action.payload.password,
        loading:false
      }
    case SET_LOGIN_AUTH:
      return update(state, {
        authentication: {
          nonce: {
            $set: state.token,
          },
        },
        message: {
          $set: null,
        },
      })
    case RESET_SIGNING_MESSAGE:
      return {
        ...state,
        authentication: [],
      }
    case RESET_AUTH:
      return {
        ...state,
        authentication: [],
        message: null,
      }
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
      }
    case ACCEPT_CGU:
      return {
        ...state,
        authentication: action.payload,
      }

    case GET_TEMPORARY_CODE:
    case RESET_PASSWORD:
      return {
        ...state,
        message: action.payload,
        password: action.password,
      }
    case SET_NEW_PASSWORD:
      return {
        ...state,
        message: action.payload,
      }
    case RESET_AUTH_MESSAGE:
      return {
        ...state,
        message: null,
      }
    default:
      return state
  }
}
export default authReducer
