import { Fragment, useEffect } from "react"
import {useUpdateApp} from './hooks/useUpdateApp';
import GlobalStyle from './styles/GlobalStyle'
import {BrowserRouter} from 'react-router-dom'
import { toast, ToastContainer } from "react-toastify";
import Routing from "./routing";
import './styles/_index.scss';
import { useTokenFCM } from "./hooks/useTokenFcm";
import { useDispatch } from "react-redux";
import { setToken } from "./redux/actions/pci";
import { onMessageListener } from "./service/firebase";
import useWindowDimensions from "./hooks/useWindowDimensions";
import { setUserDeviceType } from "./redux/actions";
import { LastLocationProvider } from 'react-router-last-location';
import UpdateWaiting from "./components/update-waiting/UpdateWaiting";
import 'react-toastify/dist/ReactToastify.css';

let periode_of_inactivity = 0
const MAX_PERIODE_OF_INACTIVITY = 3600
const PTS_RUPTURE = 1200

export default function App(){
    const dispatch = useDispatch();
    /**
     * Hooks useUpdateApp that allows to know 
     * when application have new service worker and update the new cache 
     */
    const {updateWaiting,registration,swListener} = useUpdateApp();

    /**
     * Hooks for get token fcm firebase
     */
    const {tokenFCM} = useTokenFCM()

    /**
     * Hooks for know size screen
     */
    const width = useWindowDimensions(PTS_RUPTURE)
    
    /**
     * Set type screen
     */
    useEffect(() => {
        if (width <= PTS_RUPTURE) {
        dispatch(setUserDeviceType('smartphone'))
        } else {
        dispatch(setUserDeviceType('desktop'))
        }
    })
    
    /**
     *Set token in auth reducer @nonce
     */
    useEffect(()=>{
        if(tokenFCM.isFound)
            dispatch(setToken(tokenFCM.token_fcm));
    },[tokenFCM])

    /**
     * Receipt new push notification and show this in toast box
     */
    if ('serviceWorker' in navigator) {
        onMessageListener().then(payload => {
           toast.info(payload.data.title);
      })
        .catch(err => console.log('failed: ', err));
      }

      const handleUpdate = () => {
        //@ts-ignore
        swListener.skipWaiting(registration?.waiting);
       }

    return(
        <Fragment>
            <GlobalStyle/>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
          />
            <BrowserRouter 
                onUpdate={() => window.scrollTo(0, 0)}
            >
                <LastLocationProvider>
                    <Routing/>
                </LastLocationProvider>
            </BrowserRouter>
            <UpdateWaiting updateWaiting={updateWaiting} handleUpdate={handleUpdate}/>
        </Fragment>
    )
}