import { AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import BlockAuth from '../../components/block-page/block-auth';
import api from '../../config/pci/api';
import { stats } from '../../config/stats';
import EasyButton from '../../easy-ui/button';
import styles from './AcceptCgu.module.scss';
import {useHistory} from 'react-router-dom'
import EasyLink from '../../easy-ui/link';
import { useFavicon, useLocation } from 'react-use';
import ProfileForm from '../../features/profile-validation/profile-form';

export default function AcceptCgu(){

    const {id,nonce} = useSelector(state=>state.auth.authentication) 
    const [data,setData] = useState<any>({error:false,loading:false,data:false});
    const saveData = useRef<any>({});
    const uploadData = useRef<any>({});

    const [isChecked,setIsChecked] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();
    const [errorValidation,setErrorValidation] = useState<string | boolean>(false);



    /**
     * Function Fetch Data Profil
     */
    async function fetchData(){
       setData({error:false,loading:true,data:false})
       try{ 
        const response = await api.get< AxiosResponse<any, any>>('/profile', {
            params: {
            user: id,
            nonce: nonce,
            stat: stats(),
            },
        })
        //@ts-ignore next-line
        if(response.data.hasOwnProperty('error') || response.data.length === 0 ) 
            return  setData({loading:false,error:true,data:false})
        else{
            saveData.current = response?.data;
            return setData({loading:false,error:false,data:response?.data})  
        }
       
            
        }catch(error){
            setData({loading:false,error:true,data:false})
        }

    }


    useEffect(()=>{
        fetchData()
    },[])



    /**
     * Function Back to Connexion Page
     */
    function goToConnectionPage(){
        history.push('/connexion')
    }



   /**
    * Function for update cgu profile
    */
   async function onValidationCgu(){
        if(errorValidation)setErrorValidation(false)
        if(!isChecked){
           return setErrorValidation('Veuillez accepter les CGU si vous souhaitez continuer')
        }
        try{
            const response = await api.get<any,any>('/updateprofile', {
                params: {
                  user: id,
                  nonce: nonce,
                  cgu: true,
                  ...uploadData.current,
                  stat: stats(),
                },
              })
        if(response.data.hasOwnProperty('error')){
           return setErrorValidation('Une erreur est survenue lors de la validation des CGU. Veuillez réessayer')
        }
        history.push('/')
        }catch(error){
            return setErrorValidation('Une erreur est survenue lors de la validation des CGU. Veuillez réessayer')
        }
    }

    /**
     * Handler Change Input Checkbox
     */
    function onChecked(){
        if(errorValidation)setErrorValidation(false)
        setIsChecked(prev=>!prev)
    }

    /**
     * Function for refuse cgu
     */
    function onRefuseCgu(){

    }



    /**
     * Handler onChangeData
     */
    function onChangeData(e){
        setData(prev=>{
            return{
               ...prev,
               data:{
                ...prev.data,
                [e.target.name]:e.target.value
               } 
            }
        })  
        onPushInUploadData(e)
        }
    /**
     * Pushing data to ref object for upload request
     */
    function onPushInUploadData(e){
        uploadData.current[e.target.name] = e.target.value;
        if( uploadData.current[e.target.name] === saveData.current[e.target.name]){
            delete uploadData.current[e.target.name]
        }  
    }

    return(
        <BlockAuth title={"Validation du profil"} subtitle={""} loading={data.loading}>
            {data.error ?
                <div className={styles["error-container"]}>
                    <div className={styles["error-sentence"]}>Vous ne pouvez pas accèder a ce contenu veuillez vous connectez</div>
                    <EasyButton onClick={goToConnectionPage} text={"Connectez vous"} type="primary" name="text" />

                </div>
                :data.data ?
                <div className={styles["container-profil"]}>
                   <ProfileForm setData={onChangeData} dataSave={saveData} data={data.data}/>
                    <div className={styles["cgu"]}>
                        <input checked={isChecked} onChange={onChecked} type="checkbox"/>
                        <span className={styles["form-cgu"]}>
                        J'ai lu et j'accepte les
                        <EasyLink to={{pathname:'/politique-de-confidentialite',state:{background:location}}} type={'primary'}>
                            <span className={styles["cgu-link"]}>CGU</span>
                        </EasyLink>
                        </span>
                    </div>
                     <div className={styles["cgu-action"]}>
                        <EasyButton name='text' type="primary" text="Validation" onClick={onValidationCgu}/>
                      
                    </div>
                    {errorValidation && <div className={styles["error-validation"]}>{errorValidation}</div>}
                </div>
                
                : null
                }
        </BlockAuth>
    )
}

/*
      <div className={styles["user-info"]}>
                            <span>{data.data.prenom}</span>
                            <span>{data.data.nom}</span>
                        </div>
                        <div className={styles["user-fonction"]}>
                            {data.data.fonction}
                        </div>
*/