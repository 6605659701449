import { useState } from 'react';
import { toast } from 'react-toastify';
import Icon from '../../../../icons';
import styles from './CellIcon.module.scss';
import { referenceIcon } from '../../../../icons/referenceIcon';
export default function CellIcon({element}){

    const [hover,setHover] = useState(false);

    function onClipBoard(text){
        navigator.clipboard.writeText(text).then(() => {
            toast.success(`${text} copied in clipboard`)
          }, () => {
            toast.error(`error clipboard`)
          });
    }
    

    return(
    <div title={referenceIcon[element].join(' ')} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} onClick={()=>onClipBoard(element)} className={styles["cell-icon"]}>
        <div className={styles["icon"]}><Icon name={element}/></div>
        <div className={styles["title-icon"]}>{element}</div>
    </div>
    )
}