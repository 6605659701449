// @ts-nocheck
import { useFetchProtected } from '../useFetchProtected';
import styles from './ProtectedModal.module.scss';
import {useHistory} from 'react-router-dom';
import Portal from '../../../components/common/portal/Portal';
import { Cross } from '../../../icons/Cross';
import parse from 'html-react-parser';
import { useSpring,animated } from 'react-spring';
import { useMedia,useLockBodyScroll } from 'react-use';

export default function ProtectedModal({type}){
    const {data,loading,error,fullfiled} = useFetchProtected(type)
    const history = useHistory();
    const isMobile = useMedia('(max-width:768px)')
    useLockBodyScroll(true);

    const [springModal,api] = useSpring(()=>({
        opacity:fullfiled? 1 : 0,
        transform:isMobile ? 'none':fullfiled?'translate3d(-50%,-50%,0)' : 'translate3d(-50%,-150%,0)',
     
    }),[fullfiled])

    function onClose(){
        api.start({
            transform:isMobile ? 'none' :'translate3d(-50%,-200%,0)',
            opacity:0,
            onRest:()=>history.goBack()
        })
    }

    if(loading){
        return null
    }
    else if(error){
        return <div>{error}</div>
    }
    else if(typeof(data)==='object'){
    return(
        <Portal id={`modal-${type}`}>
        <animated.div style={{opacity:springModal.opacity}} className={styles["block-protected-background"]}>
            <animated.div style={{transform:springModal.transform}} className={styles["block-protected-container"]}>
                <button onClick={onClose} className={styles["close-block-protected"]}><Cross/></button>
                <h1 className={styles["title"]}>{data.title}</h1>
            <div className={styles["contentContainer"]}>{parse(data?.content)}</div>
            </animated.div>
        </animated.div>
   </Portal> 
    )}
    else return null
}