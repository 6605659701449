import styles from './ContentTvShow.module.scss';
import { useSpring,animated, useTransition } from 'react-spring';
import { Fragment, useEffect, useState } from 'react';
import Teasing from './teasing';
import { useInterval, useMeasure } from 'react-use';
import { easeCubicInOut } from 'd3-ease';
import parse from 'html-react-parser'
export default function ContentTvShow({elements}){
    console.log(elements)

    const [ref,{width}]= useMeasure<HTMLDivElement>();
    const [index,setIndex] = useState(0);
    
    const transitionContent = useTransition(index,{
        config:{
            duration:2000,
            easing:easeCubicInOut
        }, initial:{
            opacity:1,
            filter:'blur(0rem)'
        },
        from:{
            opacity:0,
            filter:'blur(4rem)'
        },
        enter:{
            
            opacity:1,
            filter:'blur(0rem)'
        },
        leave:{
            opacity:0,
            filter:'blur(4rem)'
           
        }
       
    })

    useInterval(()=>{
        setIndex(prev=>(prev+1)%elements.length) 
    },10000)
    

    return(
        <div className={styles["content-tv-show-container"]}>
             <div className={styles["title-wrapper"]}>{transitionContent((style,item)=>(<animated.div style={style} className={styles["title"]}>{elements[item].titre}</animated.div>))}</div>
                <div className={styles['content-wrapper']}>
                    {transitionContent((style,item)=>{
                    return(
                        <animated.div style={style} 
                            className={styles["content"]}>
                            <div>{parse(elements[item].text)}</div>
                    </animated.div>)
                    })}
            </div>
            <div className={styles["footer"]}>
                <div ref={ref} className={styles["teasing"]}>
                    {width> 0 && <Teasing width={width}/>}
                </div>
            </div>
        </div>
    )
}