import {useEffect,useRef,useState} from 'react';
import { useClickAway } from 'react-use';
import {disableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';
import Portal from '../common/portal/Portal';
import styles from './Modal.module.scss';
import ModalContainer from './modal-container';
import { SpringValue } from 'react-spring';

type Modal = {
    onClose?:()=>void,
    onValidation?:()=>void,
    id:string,
    type:string,
    message?:string,
    style?:{ opacity: SpringValue<number>; transform: SpringValue<string>; },
}   

export default function Modal({onClose,onValidation=undefined,id,type,message,children=undefined,style}){
        //Ref Modal
        const modalRef = useRef(null);
        const targetRef = useRef(null);
        useEffect(()=>{
            targetRef.current && disableBodyScroll(targetRef.current)
           return ()=>{
              clearAllBodyScrollLocks();
            }
          },[])
        
          useClickAway(modalRef,()=>{
            if(onClose !==undefined && modalRef.current) return  onClose()
            return false
          })
          

    return(
        <Portal id={id}>
           <div  ref={targetRef} className={styles['modal-background']}>
                <ModalContainer style={style} onValidation={onValidation} message={message} onClose={onClose} ref={modalRef} type={type}>
                  {children}
                </ModalContainer>
           </div>
        </Portal>
    )
}
