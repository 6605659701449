import { CSSProperties, useRef, useState } from 'react';
import Icon from '../../../icons';
import styles from './SlideTvShow.module.scss';
import Caroussel from '../../../components/caroussel';
import ContentTvShow from './../content-tv-show';



export default function SlideTvShow({data}){
    const [isMoving, setIsMoving] = useState(false);
    const timeoutRef = useRef();

    
    const styleButtonFullScreen = {
        '--opacity-screen':isMoving?1:0
    } as CSSProperties

    //Set coord when mouse move
        function onFullScreen(){
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
              } else if (document.exitFullscreen) {
                document.exitFullscreen();
              } 
        }
    
    //Set  when mouse move
    function handleMouseMove() {
        setIsMoving(true);

        clearTimeout(timeoutRef.current);
        //@ts-ignore
        timeoutRef.current = setTimeout(() => {
            setIsMoving(false);
        }, 1000);
    
        }

    return(
        <div onMouseMove={handleMouseMove} className={styles["slide-tv-show-container"]}>
            <button style={styleButtonFullScreen}  onClick={onFullScreen} className={styles["full-screen"]}>
                <Icon name={"fullscreen"}/>
            </button>
             {/*@ts-ignore */}
             <Caroussel
                    animationConfig={{
                        //@ts-ignore
                        initial:{
                            position:'absolute',
                            opacity:1,
                            //filter:'blur(0rem)'
                        },
                        //@ts-ignore
                        from:{
                            position:'absolute',
                            opacity:0,
                            //filter: 'blur(4rem)'
                        },
                        //@ts-ignore
                        enter:{
                            position:'absolute',
                            opacity:1,
                            //filter:'blur(0rem)'
                        },
                        //@ts-ignore
                        leave:{
                            position:'absolute',
                            opacity:0,
                            //filter:'blur(4rem)'
                        }
                    }}
                    duration={10000}
                    className={styles["caroussel"]} 
                    size={{width:'100%',height:'100%'}}
                    renderItems={
                        data.map(({photo})=>({style,className})=>
                            <img alt="" className={className}  
                                 src={photo ?? 'https://images.unsplash.com/photo-1610513320995-1ad4bbf25e55?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'} 
                                 style={{...style}}
                            />)}
                            />
                    <div  className={styles["content-tv-show"]}>
                        <ContentTvShow elements={data}/>
                    </div>
        </div>
    )
}