import { Fragment } from 'react';

import styles from './ItemTuto.module.scss';
import parse from 'html-react-parser';
import Bulle from './bubble-tuto';

export default function  ItemTuto({num,content,icon}:{num:number,content:string,icon?:string}){


    return(
        <div className={styles["item-wrapper"]}>
            <div className={`${
                num === 1 
                ? styles["wrapper-icon-1"]
                : num===2 
                ? styles["wrapper-icon-2"]
                : styles["wrapper-icon-3"]}`}
            >
                <Bulle name={icon}/></div>
            <div className={styles["item-tuto-container"]}>
                <div className={styles["item-tuto-num"]}>{num}</div>
                <div className={styles["item-tuto-content"]}>{parse(content)}</div>
            </div>
        </div>
    )
}
