import Logo from '../../../components/logo';
import EasyInput from '../../../easy-ui/input';
import styles from './AuthTvShow.module.scss';
import EasyButton from '../../../easy-ui/button/index'
import { useState } from 'react';
 
export default function AuthTvShow({onAuth,error,loading}){

    const [password,setPassword] = useState<string>('');

    

    return(
        <div className={styles["auth-tv-show-container"]}>
               <Logo className={styles["logo"]} type="COLOR"/>
              <h3>Veuillez renseigner le mot de passe pour acceder à ce contenu</h3>
              <div className={styles["form-tv-show"]}>
                <EasyInput onchange={(e)=>setPassword(e.target.value)} categorie={"password"}/>
              <div className={styles["action-form"]}>
                    {/*@ts-ignore*/}
                    <EasyButton isFetch={loading} onClick={()=>onAuth(password)} name={'text'} text="Valider"/>
              </div>
                {error &&<div className={styles["error-tv-show"]}>{error}</div>}
              </div>
        </div>
    )
}