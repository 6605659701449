import React from 'react';

export default ({...props}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
      <g id="bulle">
        <path
          fill={props.bg}
          d="M20,1a18.86,18.86,0,0,0-9.4,2.5A12.93,12.93,0,0,0,2,1.6a1.06,1.06,0,0,0-.9.8,1,1,0,0,0,.4,1.2A7.2,7.2,0,0,1,4.3,9.3,19,19,0,1,0,20,1Z"
          transform="translate(-1 -1)"
        />
      </g>
      <g id="Like">
        <g id="picto-copy">
          <path
            fill={'var(--ui-primary-color)'}
            fillRule="evenodd"
            d="M30.54,18.76a1.3,1.3,0,0,1-1.2.8h-4.7a1.3,1.3,0,0,1-1.2-.8,1.31,1.31,0,0,1,.3-1.2,1.61,1.61,0,0,1,.9-.3h4.7a.91.91,0,0,1,.8.4,1,1,0,0,1,.4,1.1Zm-.4,3.3a1.3,1.3,0,0,1-1.2.8h-5.4a1.3,1.3,0,0,1-1.2-.8,1.31,1.31,0,0,1,.3-1.2,1.23,1.23,0,0,1,.8-.4h5.5a.91.91,0,0,1,.8.4,1.09,1.09,0,0,1,.4,1.2Zm-.8,3.2a1.3,1.3,0,0,1-1.2.8h-5.4a1.3,1.3,0,0,1-1.2-.8,1.31,1.31,0,0,1,.3-1.2,1.23,1.23,0,0,1,.8-.4h5.5a.91.91,0,0,1,.8.4,1.09,1.09,0,0,1,.4,1.2Zm-2,3.2a1.3,1.3,0,0,1-1.2.8H23a1.3,1.3,0,0,1-1.2-.8,1.31,1.31,0,0,1,.3-1.2,1.23,1.23,0,0,1,.8-.4h3.2a.91.91,0,0,1,.8.4h0a.87.87,0,0,1,.4,1.2Zm-5.7-1.8a2.08,2.08,0,0,0-.5,2l.3.6h-4s-2.7,0-4.1-1.7a.1.1,0,0,0-.1-.1,1.14,1.14,0,0,0-1.2-.6H9.14c-.4,0-.6-.3-.6-.7v-8.3a.67.67,0,0,1,.6-.7h1.7a5.29,5.29,0,0,0,3.2-.8,3,3,0,0,0,.8-.8,17.94,17.94,0,0,1,5.4-3.5,19,19,0,0,0,5.5-3.5c.3-.3.6-.4.8-.3a1.1,1.1,0,0,1,.6,1c0,.83-3.9,4.4-3.9,4.4a15.37,15.37,0,0,0-1.7,1.9,1.44,1.44,0,0,1-.2.3,1.39,1.39,0,0,0,0,.9.91.91,0,0,0,.8.4h.8a1.94,1.94,0,0,0-.3,1.8c.1.2.2.5.4.6a1.6,1.6,0,0,0-1.3.9,1.84,1.84,0,0,0-.2,1.6,1.61,1.61,0,0,0,.4.7,2.06,2.06,0,0,0-1.2,2.5,2.45,2.45,0,0,0,.9,1.4Zm9.7-7.9a1.83,1.83,0,0,0-.6-1.7,2,2,0,0,0-1.4-.6h-7.1a.1.1,0,0,1-.1-.1v-.1a.31.31,0,0,0,.1-.2,6.12,6.12,0,0,1,1.5-1.7s4.2-3.6,4.2-4.9a2,2,0,0,0-1.1-1.8,1.73,1.73,0,0,0-1.7.4,16.94,16.94,0,0,1-5.3,3.3,20.17,20.17,0,0,0-5.6,3.7,4.62,4.62,0,0,1-.7.7,4.39,4.39,0,0,1-2.7.7H9.14a1.39,1.39,0,0,0-1.5,1.4v8.3A1.45,1.45,0,0,0,9,27.66h2.9c.4,0,.4.1.5.3a.22.22,0,0,0,.2.2,6.86,6.86,0,0,0,4.7,1.9h8.8a2,2,0,0,0,1.9-1.4,1.94,1.94,0,0,0-.3-1.8H28a2,2,0,0,0,1.9-1.4,2.39,2.39,0,0,0-.4-2,2,2,0,0,0,1.2-1.3,2.08,2.08,0,0,0-.5-2l-.1-.1a1.6,1.6,0,0,0,1.2-1.3Z"
            transform="translate(-1 -1)"
          />
        </g>
      </g>
    </svg>
  );
};
