import { SyntheticEvent, useCallback } from "react";

export function BindHover(handleHover:(e: SyntheticEvent)=>void){
    
    const bindHover = useCallback(() => {
        return {
          onMouseEnter: handleHover,
          onMouseLeave: handleHover,
        };
    }, [handleHover]);

    return bindHover
}
