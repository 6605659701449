import styles from './CarousselSwiper.module.scss';
import { CSSProperties, Fragment, useLayoutEffect, useRef, useState } from 'react'
import { useSprings, animated } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import _ from 'lodash'
import { useMeasure } from 'react-use';
import { ChevronRight } from '../../icons/ChevronRight';
import Swiper from './swiper';

export default function CarousselSwiper({medias,onClick,type}){

    const [refCaroussel,{width}] = useMeasure<HTMLDivElement>();

    return(
        <div className={styles["container-caroussel-swiper"]} ref={refCaroussel}> 
             {width > 0 && <Swiper type={type} width={width} medias={medias} onClick={onClick} />  }
        </div>  
    )
}