import React from 'react';

export const Referencement = () => {
  return (
<svg version="1.1" id="Calque_1" x="0px" y="0px"
	 viewBox="0 0 17.8 17.8">
<path id="Icon_material-photo-filter" d="M15.9,6.9v8.9H2V2h8.9V0H2C0.9,0,0,0.9,0,2v13.9c0,1.1,0.9,2,2,2h13.9c1.1,0,2-0.9,2-2V6.9
	H15.9z M13.9,6.9l0.9-2l2-0.9l-2-0.9l-0.9-2l-0.9,2l-2,0.9l2,0.9L13.9,6.9z M10.1,7.7L8.9,5L7.7,7.7L4.9,8.9l2.7,1.2l1.2,2.7
	l1.2-2.7l2.7-1.2L10.1,7.7z"/>
</svg>


  );
};
