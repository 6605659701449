import React from 'react';

export default ({...props}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 39.49 38">

      <g id="bulle">
        <path
          fill={props.bg}
          d="M20.74,1A19,19,0,0,0,4.19,29.31,7.07,7.07,0,0,1,.81,35.2a1.17,1.17,0,0,0-.54,1.16,1.15,1.15,0,0,0,.85,1A12.56,12.56,0,0,0,10.46,36l.06,0A19,19,0,1,0,20.74,1Z"
          transform="translate(-0.26 -1)"
        />
      </g>
      <g id="safari-1">
        <g id="Group-7">
          <g id="Group-3">
            <g mask="url(#mask)">
              <path
                id="Fill-1"
                fill={'var(--ui-primary-color)'}
               
                d="M28.5,15.24h-4a.78.78,0,1,0,0,1.55h4a.79.79,0,0,1,.79.77V27.63a.78.78,0,0,1-.79.77H12.63a.78.78,0,0,1-.8-.77V17.56a.79.79,0,0,1,.8-.77h4a.78.78,0,1,0,0-1.55h-4a2.35,2.35,0,0,0-2.38,2.32V27.63A2.35,2.35,0,0,0,12.63,30H28.5a2.35,2.35,0,0,0,2.38-2.32V17.56a2.35,2.35,0,0,0-2.38-2.32"
                transform="translate(-0.26 -1)"
              />
            </g>
          </g>
          <g id="Group-6">
            <g mask="url(#safari-1-mask-2)">
              <path
                id="Fill-4"
                fill={'var(--ui-primary-color)'}
              
                d="M25.3,11.7,21.15,7.78a.86.86,0,0,0-1.18,0L15.82,11.7a.75.75,0,0,0,0,1.11.85.85,0,0,0,1.15,0l2.74-2.58v13a.83.83,0,0,0,1.66,0v-13l2.74,2.58a.85.85,0,0,0,1.17,0,.75.75,0,0,0,0-1.09"
                transform="translate(-0.26 -1)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>


  );
};
