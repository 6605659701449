import styles from './CardSearchLite.module.scss';
import parse from 'html-react-parser';

type cardSearchLitProps = 
{title:string,
 className:string,
 type:{id:string,label:string},
 id:string,
 url:string
 onGoToPageSearch:(url:string,id_rub:string)=>void
}

export default function CardSearchLite({title,className,type,id,onGoToPageSearch,url}:cardSearchLitProps):JSX.Element{

    return(
       
        <div onClick={()=>onGoToPageSearch(url,id)} className={`${styles['card-search-lite']} ${className}`}>
            <div className={styles["label-search"]}>
                {type && type.label ? type.label : 'Divers'}
            </div>
            <div className={styles["title-search"]}>
                {title && parse(title)}
            </div>
        </div>
    )
}