import { Cross } from '../../../icons/Cross';
import styles from './ModalSearchError.module.scss';

export default function ModalSearchError({onCloseSearch}){
    return(
        <div className={styles["modal-search-error"]}>
           <span>Aucun résultat trouvé</span>
           <button 
                className={styles["button-close"]}
                onClick={onCloseSearch}
                >
                <Cross/>
            </button>
        </div>
    )
}