import illustration from '../../img/illustration/illustration2.png';
import illustration2 from '../../img/illustration/illustration3.png';
import illustration3 from '../../img/illustration/illustration4.png';
import illustration4 from '../../img/illustration/illustration5.png';

export const list_prez =  [
    {
         image:illustration,
         text:'Échangez avec vos collaborateurs en toute simplicité'
     },
     {
         image:illustration2,
         text:'Contribuez à la valorisation de votre entreprise'
     },
     {
         image:illustration3,
         text:'Faites vivre la culture de votre entreprise'
     },
     {
         image:illustration4,
         text:'Accédez aux outils externes'
     }
 ];