import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import SearchBarDesktop from "./search-bar-desktop/SearchBarDesktop";
import SearchBarMobile from "./search-bar-mobile/SearchBarMobile";
export default function SearchBar({menu_active,type}){


    /*@ts-ignore next-line*/
    const authentication = useSelector(state=>state.auth.authentication);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' })
    if(authentication.profil !=='partenaire')
        if(isTabletOrMobile){
            return <SearchBarMobile menu_active={menu_active}/>
        }
        else
        return <SearchBarDesktop type={type??"home"}/>
    else return null
} 