import { combineReducers } from 'redux'

// COMMUN
import authReducer from './pci/authReducer'
import sessionReducer from './pci/sessionReducer'
import contextReducer from './pci/contextReducer'
import adminReducer from './pci/adminReducer'
import deviceReducer from './deviceReducer'

// PCI
import postsReducer from './pci/postsReducer'
import postReducer from './pci/postReducer'
import profileReducer from './pci/profileReducer'
import calendarReducer from './pci/calendarReducer'
import contactsReducer from './pci/contactsReducer'
import partnersReducer from './pci/partnersReducer'
import pollsReducer from './pci/pollsReducer'
import navbarReducer from './pci/navbarReducer'
import groupsReducer from './pci/groupsReducer'
import socialsReducer from './pci/socialsReducer'
import socialReducer from './pci/socialReducer'
import shoppingReducer from './pci/shoppingReducer'
import linkedinReducer from './pci/linkedinReducer'
import caReducer from './pci/caReducer'
import notificationReducer from './pci/notificationReducer'
import fluxReducers from './pci/fluxReducers'
import breadcrumbReducer from './pci/breadcrumbReducer';

// PGL
import pgl_profileReducer from './pgl/profileReducer'
import pgl_leadsReducer from './pgl/leadsReducer'
import pgl_leadReducer from './pgl/leadReducer'
import pgl_companiesReducer from './pgl/companiesReducer'
import pgl_sellersReducer from './pgl/sellersReducer'
import pgl_qualifReducer from './pgl/qualifReducer'
import pgl_popinReducer from './pgl/popinReducer'
import pgl_callbackReducer from './pgl/callbackReducer'
import pgl_callReducer from './pgl/callReducer'
import pgl_alerteReducer from './pgl/alerteReducer'
import pgl_email from './pgl/emailReducer';
import socialsSlice from '../../features/socials/socialsSlice'
import documentSlice from '../../features/document-board/documentSlice'


import { publicationSlice } from '../../features/publications/publicationSlices'
import { LifePostSlice } from '../../features/post-picture/postPictureSlice'
import { homeSlice } from '../../features/home/homeSlice'
import { postStandardSlice } from '../../features/post-standard/postStandardSlice'
import { socialNetwork } from '../../features/social-network/socialNetworkSlice'

const appReducer = combineReducers({
  deviceInfos: deviceReducer,
  // PCI
  home:homeSlice.reducer,
  life:LifePostSlice.reducer,
  standard:postStandardSlice.reducer,
  publications:publicationSlice.reducer,
  socialsNetwork:socialNetwork.reducer,
  auth: authReducer,
  documents:documentSlice,
  breadcrumb:breadcrumbReducer,
  posts: postsReducer,
  events: calendarReducer,
  post: postReducer,
  admin: adminReducer,
  profile: profileReducer,
  contacts: contactsReducer,
  partners: partnersReducer,
  polls: pollsReducer,
  navbar: navbarReducer,
  context: contextReducer,
  session: sessionReducer,
  socials: socialsSlice,
  groups: groupsReducer,
  linkedin: linkedinReducer,
  social: socialReducer,
  shopping: shoppingReducer,
  ca: caReducer,
  notification: notificationReducer,
  flux: fluxReducers,
  // PGL
  pgl_profile: pgl_profileReducer,
  pgl_companies: pgl_companiesReducer,
  pgl_sellers: pgl_sellersReducer,
  pgl_popin: pgl_popinReducer,
  pgl_call: pgl_callReducer,
  pgl_callback: pgl_callbackReducer,
  pgl_leads: pgl_leadsReducer,
  pgl_lead: pgl_leadReducer,
  pgl_alerte: pgl_alerteReducer,
  pgl_qualif: pgl_qualifReducer,
  pgl_email:pgl_email
})

const rootReducer = (state,action)=>{
  if(action.type == "RESET_AUTH"){
    localStorage.removeItem('persist:root')
    return appReducer(undefined,action)
    
  }
  return appReducer(state,action)
}

export default rootReducer;




export type RootState = ReturnType<typeof appReducer>;