import InstagramsPage from "./instagrams-page";
import FacebookPage from "./facebook-page";
import LinkedinPage from "./linkedin-page";

let Components = {
    facebook:FacebookPage,
    instagram:InstagramsPage,
    linkedin:LinkedinPage
}

export default function SocialPage({type,...props}):JSX.Element | null{
    let Component = Components[type];
    if(Components!==undefined) return <Component {...props} />
    return null

}