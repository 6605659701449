import Caroussel from '../../components/caroussel';
import styles from './LayoutAuth.module.scss';
import { useLocation } from 'react-router-dom';
import { list_prez } from './prez-screen';
import Icon from '../../icons';
import {useState} from 'react';
import config from '../../config';

export default function  LayoutAuth({children}):JSX.Element{

    const [itsGrey,setItsGrey] = useState(false);

    const styled = {
        '--filter-img': itsGrey ?'grayscale(100%)' : 'grayscale(0%)'
    }

    return(
        <div className={styles["layout-auth-container"]}>
            <div className={styles["left-side-auth"]}>
               {config.name == 'DevApp' &&  <button onClick={()=>setItsGrey(prev=>!prev)} className={styles['configuration']}><Icon name={'theme'}/></button>}
                <div className={styles["circle"]}></div><div className={styles["circle-inside"]}/>
                <Caroussel
                    className={styles["caroussel"]} 
                    size={{width:'100%',height:'20rem'}}
                    renderItems={
                        list_prez.map(({image})=>({style,className})=><img className={className}  src={image} style={{...style,...styled}}/>)   
                    }
                    renderTexts={
                        list_prez.map(({text})=>({style,className})=><div className={className} style={style}>{text}</div>)
                }/>
            </div>
            <div className={styles["right-side-auth"]}>
                {children}
            </div>
          
        </div>
    )
}