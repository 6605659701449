// import {
//   CHECK_AUTH,
//   SIGN_IN,
//   GET_TEMPORARY_CODE,
//   SEND_MAIL,
//   SEND_EMAIL_TO_ADMIN,
//   GET_NAVBAR,
//   GET_POSTS,
//   GET_CONTACTS,
//   GET_PARTNERS,
//   GET_ADMINS,
//   GET_PROFILE,
//   SET_PROFILE,
//   SET_PROFILE_PICTURE,
//   SET_NEW_PASSWORD,
//   GET_POST,
//   SET_LIKE,
//   SET_COMMENT,
//   GET_PDF,
//   ADMIN_SET_POST,
//   ADMIN_DELETE_POST,
//   ADMIN_GET_GROUPS,
//   ADMIN_GET_CATEGORIES,
//   ADMIN_GET_POSTS,
//   ADMIN_GET_POST_FOR_UPDATE
// } from "reduxactions/pci/types";
import { GET_SOCIAL_POST, RESET_POST, ADMIN_DELETE_SOCIAL_POST,GET_SOCIAL_POST_LOADING,GET_SOCIAL_POST_ERROR,EDIT_CAPTION_SOCIAL_NETWORK, REMOVE_HASTAGS,ADD_HASTAGS } from '../../actions/pci/types'

const socialReducer = (state = {loading:false,error:false}, action) => {
  switch (action.type) {
    case ADMIN_DELETE_SOCIAL_POST:
      return false

    
    case GET_SOCIAL_POST_LOADING:{
      return {
        ...state,
        loading:true,
        error:false,
      }
    }
    case GET_SOCIAL_POST:
      return {...state,
              ...action.payload,
              loading:false,
              error:false
            }
    case GET_SOCIAL_POST_ERROR:
      
    return{
        ...state,
        loading:false,
        error:true
      }

    case EDIT_CAPTION_SOCIAL_NETWORK:
      return{
        ...state,
        texte:action.payload
      }
      case  ADD_HASTAGS:
        const existingTags = Array.isArray(state.tags) ?  state.tags.includes(action.payload) : false
        return{
          ...state,
          tags: state.tags && Array.isArray(state.tags) ? existingTags ? state.tags : [...state.tags,action.payload] : [action.payload]
        }
      
      case REMOVE_HASTAGS:
        
        return{
          ...state,
          tags:[...state.tags.filter(i=>i!==action.payload)]
        }

    case RESET_POST:
      return false
    default:
      return state
  }
}

export default socialReducer
