import { hexToCSSFilter } from 'hex-to-css-filter';

export function getColorsByType(type){
    switch(type){
        case 'primary':
            return '--primary-color'
        case 'secondary':
            return '--secondary-color'
        case 'alert':
            return '--alert-color'
        case 'success':
            return '--success-color'
        case 'grey':
            return '--grey-color'
        case 'black':
            return '--black-color'
        default:
            return  '--primary-color'
    }
}

export function  getColorRgbaByType(type){
    return  hexToCSSFilter(getComputedStyle(document.documentElement).getPropertyValue(getColorsByType(type))).rgb.join(',');
}