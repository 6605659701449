import styles from './Navbar.module.scss'
import { memo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';
import Engrenage from '../../../../icons/Engrenage'
import { CSSProperties } from 'react';
// TYPES
import { IRootState } from '../../../../types/state'

// CONFIG CLIENT
import config from '../../../../config'

// ICONS

import { Curve } from '../../../../icons/CurveTest'
import { Home } from '../../../../icons/Home'
import { useNavbarPgl } from '../../../../hooks/useNavbarPgl'

import ArrowRight from '../../../../icons/ArrowRight';
import EyeIcon from '../../../../icons/EyeIcon';



export function ToggleIcon({route,history}){

  if (route!=="/leads"){
    return(
      <button className={styles.iconLeft} style={{background:'none',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={(e)=>history.goBack()}>
        <ArrowRight height={'30px'} width={'30px'}/>
      </button>
    )
  }
    else{
      return(
      <div></div>
  )
    }
}




const Navbar = () => {
  const authentication = useSelector((state: IRootState) => state.auth.authentication)
  const { navbarTitle, withoutNavbar } = useNavbarPgl()
  const deviceInfos = useSelector((state: IRootState) => state.deviceInfos);
  const profile = useSelector(state=>state.pgl_profile);
  const history = useHistory()




  return (
    <nav className={!withoutNavbar ? styles['navbar'] : styles['nonavbar']}>
      <div className={styles['content']}>
        {/* <Link className={styles['content_left']} to="/">
          <Home />
        </Link> */}
        {/* <Link className={styles['content_center']} to="/leads"> */}
        <div className={styles['content_center']}>
          <img className={styles['logo']} alt={`Logo ${config.name}`} src={config.logo_connexion.default} />
        </div>
        {/* </Link> */}
     
        <Link className={styles['content_right_2']} to="/">
          <Home />
        </Link>
        {
        profile.role !=="adherent" &&
        <Link className={styles['content_right']} to="/leads/chalances">
         <Engrenage fill={'white'} height={'30px'} width={'30px'}/>
        </Link>
        }

{
        profile.role !=="adherent" &&
        <Link className={styles['content_right_3']} to={{ pathname: "https://leads-mondial.cleverapps.io/" }} target="_blank" >
         <EyeIcon fill={'white'} height={'40px'} width={'40px'}/>
        </Link>
        }
        
      </div>

      <div style={{
            zIndex:4,
            marginBottom:0,
            bottom:'-48px',
            position:'absolute',
            pointerEvents: 'none',
          '--background': `${deviceInfos =="smartphone" ? 'var(--linear-gradient-primary)': 'var(--theme-color-primary)'}`,
      } as CSSProperties
    } 
      
        className={styles['tab']}>
        <span className={styles['tab_left']}>
        <Curve id="curve-left" top_color={deviceInfos === "smartphone"?config.colors.navbar.top :'var(--theme-color-primary)'} bottom_color={deviceInfos === "smartphone"?config.colors.navbar.bottom :'var(--theme-color-primary)'} />
        </span>

        <div className={styles['tab_center']}>
          <h1 className={styles['page-title']}>{navbarTitle ?? process.env.REACT_APP_NAME}</h1>
          {process.env.REACT_APP_DEBUG && process.env.REACT_APP_DEBUG === '1' && (
            <div className="debug-zone">
              {authentication.profil && (
                <span className={styles['version-label']}>
                  {authentication.profil.slice(0, 1).toUpperCase()} - {process.env.REACT_APP_VERSION}
                </span>
              )}
            </div>
          )}
        </div>
        <span className={styles['tab_right']}>
          <Curve id="curve-right" top_color={deviceInfos === "smartphone"?config.colors.navbar.top :'var(--theme-color-primary)'} bottom_color={deviceInfos === "smartphone"?config.colors.navbar.bottom :'var(--theme-color-primary)'} />
        </span>
      </div>

      <div className={styles['tab-quick-icon']}>


      <ToggleIcon history={history} route={history.location.pathname}/>
      
      <Link className={styles.iconRight} to="/">
          <Home />
        </Link>

   
  
      
        


      </div>
    </nav>
  )
}

export default memo(Navbar)
