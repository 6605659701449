import * as React from "react"
const AfdfSiteVente = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.2 18.4"
    style={{
      enableBackground: "new 0 0 19.2 18.4",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M7.25 15.35c.9 0 1.6.7 1.6 1.6 0 .9-.7 1.6-1.6 1.6-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6zM16.05 15.35c.9 0 1.6.7 1.6 1.6 0 .9-.7 1.6-1.6 1.6-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6zM15.65 13.55h-7.9c-1.1 0-2.1-.8-2.4-1.9l-1.3-6.7v-.1l-.7-3.3H.75c-.4 0-.8-.3-.8-.8s.4-.7.8-.7h3.2c.4 0 .7.3.7.6l.7 3.4h13.1c.4 0 .8.3.8.7v.1l-1.3 6.7c-.2 1.2-1.2 2-2.3 2zm-7.9-1.5h7.9c.4 0 .8-.3.8-.7l1.1-5.8H5.65l1.2 5.8c.1.4.5.7.9.7z" />
  </svg>
)

export default AfdfSiteVente
