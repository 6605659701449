import { useTransition,animated } from 'react-spring';
import {useLocation,Route,Switch} from 'react-router-dom';
import LayoutAuth from '../../layout/layout-auth';
import routes from '../../routes';
import styles from './AuthRouter.module.scss';
import { useLastLocation } from 'react-router-last-location';
import NoMatch from '../../pages/NoMatch';
import PrivacyPoliCy from '../../features/privacy-policy';


export default function AuthRouter(){
  
    const location= useLocation();
    const lastLocation = useLastLocation();
    const routesConnection = routes.filter(item=> item.type=="connexion");
    const pathConnection = routesConnection.map(i=>i.path)

    /**
     * Animation des pages d'authentificaton
     */
    const authTransitionPage = useTransition(location, {
      from: { transform: 'translate3d(100%,0,0)',opacity:0},
      enter: { transform: 'translate3d(0%,0,0)',opacity:1, },
      leave: { transform: 'translate3d(-100%,0,0)',opacity:0 },
    })
  return(
        <LayoutAuth >
            <Switch location={location}>
                {routesConnection.map(({path,component},key)=>(
                  <Route  key={`connection-${key}`} path={path} exact component={component}></Route>))}
                </Switch>
         </LayoutAuth>
    )
}



            {/*authTransitionPage((stylePage,item)=>{
             return(
             <animated.div className={styles['auth-route']} style={style}>
                <Switch location={item}>
                {routesConnection.map(({path,component},key)=>(
                  <Route  key={`connection-${key}`} path={path} exact component={component}></Route>))}
                </Switch>
            </animated.div>)
        })
      */}