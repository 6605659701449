import { CSSProperties } from 'react';
import styles from './ItemCategory.module.scss';

interface IItemCategory{
    name:string,
    id:number,
    categorie:number,
    onClick:any
}

export default function ItemCategory({name,id,categorie,onClick}:IItemCategory){

    const styled ={
        container:{
            '--size-select-tabs': id == categorie ? '1.2rem' : '0.9rem',
            '--color-select-tabs':  id == categorie ? 'var(--primary-color)' : 'grey',
            '--border-select-tabs': id == categorie ?'2px solid var(--primary-color)' : '2px solid transparent',
            '--font-weight-select-tabs' : id == categorie ? '600' : '400'
        } as CSSProperties
    }


    function handlerClick(){
        onClick(id)
    }

    
    return(
        <div onClick={handlerClick} style={styled.container} className={styles["item-category"]}>
           {name}
        </div>
    )
}
