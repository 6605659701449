import { useEffect, useState } from 'react';
import GridIcons from './grid-icons';
import HeaderGallery from './header-gallery';
import styles from './IconsGallery.module.scss';
import { icons } from '../../icons';
import {searchIconHaveRef} from '../../icons/referenceIcon';
export default function IconsGallery(){


    const listFullIcons = Object.keys(icons);

    const [listIcon,setListIcon] = useState<string[] | never[]>(listFullIcons)

    function onSelect(val:string){
        if(val === ''){
          return setListIcon(listFullIcons);
        }
       setListIcon(searchIconHaveRef(val))
    }

    return(
        <div className={styles["icons-gallery-page"]}>
            <HeaderGallery onSelect={onSelect}/>
            <GridIcons listIcon={listIcon}/>
        </div>
    )
}