// TODO: Add styled components
import styled from 'styled-components'

// import Loader from '../../components/Loader'

const EasyLoader = ({ darker = false }) => {
  return (
    <LoaderContainer>
      <Loader>
        <div />
        <div />
      </Loader>
    </LoaderContainer>
  )
}

const LoaderContainer = styled.div`
  z-index: 9999;
  max-width: 100%;
  flex: 100% 1;
`

const Loader = styled.div`
  margin: auto;
  width: 50px;
  height: 50px;

  & div {
    border-radius: 50%;
    border: 2px solid;
    border-color: ${({ darker }) => (darker ? 'transparent #002e5a' : 'transparent #002e5a')};
    animation: load 1s infinite ease-in-out;
  }

  & div:first-of-type {
    margin: 30% 0 0 30%;
    width: 100%;
    height: 100%;
  }

  & div:not(div:first-of-type) {
    margin: 40% 0 0 40%;
    margin-top: -88.8%;
    width: 84%;
    height: 84%;
    animation-direction: reverse;
    animation-delay: -0.5s;
  }

  @keyframes load {
    0% {
      transform: rotate(0) scale(1);
    }
    50% {
      transform: rotate(180deg) scale(0.6);
    }
    100% {
      transform: rotate(360deg) scale(1);
    }
  }
`

export default EasyLoader
