import { useEffect, useRef } from 'react'
import { useClickAway, useLockBodyScroll } from 'react-use'
import Portal from '../../../components/common/portal/Portal'
import Avatar from '../../../easy-ui/avatar'
import Icon from '../../../icons'
import styles from './LikeList.module.scss';
import {useHistory} from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
type LikeListProps = {
  onHide: () => void
  list: {
    [key: string]: {
      avatar: string | boolean | null
      firstname: string
      lastname: string
    }
  }
}

export default function LikeList({ onHide, list }: LikeListProps) {

  const history = useHistory();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    disableBodyScroll(ref.current)
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])
  
 //useLockBodyScroll()
  useClickAway(ref, () => {
    onHide()
  })
  return (
    <Portal id="like-list">
      <div  className={styles['like-list-wrapper']}>
        <div ref={ref} className={styles['like-list-modal']}>
          <div className={styles['sticky-exit']}>
            <button onClick={onHide} className={styles['button-exit']}>
              <Icon name="cross" />
            </button>
          </div>
          <div className={styles['title-modal']}>Mentions J’aime</div>
          <div style={{marginTop:'1rem'}}>
          {Object.keys(list).map((key) => {
          
            return (
              <div onClick={()=> history.push(`/user/${key}`)} className={styles['user-container']}>
                <Avatar
                  width={'2rem'}
                  height={'2rem'}
                  avatar={list[key].avatar}
                  prenom={list[key].firstname}
                  nom={list[key].lastname}
                  type={'round'}
                />
                <div className={styles['user-text']}>
                  <div>{list[key].firstname}</div>
                  <div>{list[key].lastname}</div>
                </div>
              </div>
            )
          })}
          </div>
        </div>
      </div>
    </Portal>
  )
}
