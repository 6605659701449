// import {
//   CHECK_AUTH,
//   SIGN_IN,
//   GET_TEMPORARY_CODE,
//   SEND_MAIL,
//   SEND_EMAIL_TO_ADMIN,
//   GET_NAVBAR,
//   GET_POSTS,
//   GET_CONTACTS,
//   GET_PARTNERS,
//   GET_ADMINS,
//   GET_PROFILE,
//   SET_PROFILE,
//   SET_PROFILE_PICTURE,
//   SET_NEW_PASSWORD,
//   GET_POST,
//   SET_LIKE,
//   SET_COMMENT,
//   GET_PDF,
//   ADMIN_SET_POST,
//   ADMIN_DELETE_POST,
//   ADMIN_GET_GROUPS,
//   ADMIN_GET_CATEGORIES,
//   ADMIN_GET_POSTS,
//   ADMIN_GET_POST_FOR_UPDATE
// } from "reduxactions/pci/types";
import { GET_CONTACTS, TOGGLE_FAVORITE_CONTACT } from '../../actions/pci/types'
import update from 'immutability-helper'

const INITIAL_STATE = {
  data: [],
  r: '',
  breadcrumb: false,
  loading:false,
}

const contactReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case 'GET_CONTACTS_LOADING':
      return{
        ...state,
        loading:{$set:false}
      }
    case GET_CONTACTS:
      return update(state, {
        r: { $set: action.payload.r },
        data: {
          $set: action.payload.data.error ? [] : action.payload.data.posts,
        },
        breadcrumb: {
          $set: action.payload.data.breadcrumb,
        },
        loading:{$set:false}
      })
    case TOGGLE_FAVORITE_CONTACT:
      const contact_to_update = state.data.findIndex((contact) => contact.id === action.contact_id)
      return update(state, {
        data: {
          [contact_to_update]: { inFav: { $set: action.payload.value } },
        },
      })
    default:
      return state
  }
}

export default contactReducer
