import { forwardRef } from 'react';
import { useLockBodyScroll } from 'react-use';
import Icon from '../../icons';
import Portal from '../common/portal/Portal';
import styles from './VisioPicture.module.scss';



type VisioPictureProps = {
    media:string,
    onClose?:()=>void
}

export default forwardRef(function VisioPicture({media,onClose}:VisioPictureProps,ref:any){
    
    useLockBodyScroll(true)

    return(
        <Portal  id='visio-picture'>
            <div ref={ref} className={styles["background-visio"]}>
                <button onClick={onClose} className={styles["button-close"]}>
                    <Icon name='cross'/>
                </button>
                <div className={styles["container-visio"]}>
                    <img src={media}/>
                </div>
            </div>
        </Portal>
    )
})