import React from 'react';

export const Talents = (props) => {
  return (
<svg version="1.1" id="Calque_1"  x="0px" y="0px"
	 viewBox="0 0 15.4 17.5" >
<g id="Groupe_1432" transform="translate(-198.227 -181.955)">
	<g id="Groupe_1431" transform="translate(198.727 182.455)">
		<path id="Icon_awesome-address-book" d="M1.5-0.5h10.3c1.1,0,2,0.9,2,2v1.1h0.1c0.5,0,0.9,0.4,0.9,0.9v1.3c0,0.5-0.4,0.9-0.9,0.9
			h-0.1v1.1h0.1c0.5,0,0.9,0.4,0.9,0.9v1.3c0,0.5-0.4,0.9-0.9,0.9h-0.1v1.1h0.1c0.5,0,0.9,0.4,0.9,0.9V13c0,0.5-0.4,0.9-0.9,0.9
			h-0.1v1c0,1.1-0.9,2-2,2H1.5c-1.1,0-2-0.9-2-2V1.5C-0.5,0.4,0.4-0.5,1.5-0.5z M13.9,3.6h-1V1.5c0-0.6-0.5-1-1-1H1.5
			c-0.6,0-1,0.5-1,1V15c0,0.6,0.5,1,1,1h10.3c0.6,0,1-0.5,1-1v-2h1v-1.1h-1V8.8h1V7.7h-1V4.7h1V3.6z"/>
	</g>
	<path id="Icon_material-star-border" d="M210.6,189.6l-3.5-0.3l-1.4-3.2l-1.4,3.2l-3.5,0.3l2.6,2.3l-0.8,3.4l3-1.8l3,1.8l-0.8-3.4
		L210.6,189.6z M205.8,192.6l-1.8,1.1l0.5-2.1l-1.6-1.4l2.1-0.2l0.8-2l0.8,2l2.1,0.2l-1.6,1.4l0.5,2.1L205.8,192.6z"/>
</g>
</svg>
  );
};
