export default function DownloadImport(props){
  return(
    <svg width={props.width} height={props.height} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 17.1 17.1">

        <g>
          <path  d="M16.3,10.4c-0.4,0-0.8,0.3-0.8,0.8v3.5c0,0.5-0.4,1-1,1H2.5c-0.5,0-1-0.4-1-1v-3.5c0-0.4-0.3-0.8-0.8-0.8
            S0,10.7,0,11.1v3.5c0,1.4,1.1,2.5,2.5,2.5h12.1c1.4,0,2.5-1.1,2.5-2.5v-3.5C17.1,10.7,16.7,10.4,16.3,10.4z"/>
          <path  d="M8,11.7c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.2-0.2l4.3-4.3
            c0.3-0.3,0.3-0.8,0-1.1s-0.8-0.3-1.1,0l-3,3V0.8C9.3,0.3,9,0,8.5,0S7.8,0.3,7.8,0.8v8.6l-3-3C4.4,6,4,6,3.7,6.3S3.4,7,3.7,7.3
            L8,11.7z"/>
        </g>
</svg>
  )
}