import * as React from "react"

const ChevronRight = (props) => (
  <svg
    fill="none"
    height={props?.height}
    width={props?.width}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      transform="translate(-2,0)"
      d="m19.704 12-8.492-8.727a.75.75 0 1 1 1.075-1.046l9 9.25a.75.75 0 0 1 0 1.046l-9 9.25a.75.75 0 1 1-1.075-1.046L19.705 12Z"
      fill={props.fill}
    />
  </svg>
)

export default ChevronRight
