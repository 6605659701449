import styles from './PostPictureDesktop.module.scss'
import { Comments } from '../postPictureSlice'
import Icon from '../../../icons'
import { ChangeEvent, CSSProperties, forwardRef, Fragment, RefObject, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useClickAway } from 'react-use'
import CarousselSwiper from '../../../components/caroussel-swiper'
import VisioPicture from '../../../components/visio-picture'
import { useLockBodyScroll } from 'react-use'
import { useAppSelector } from '../../../redux/hooks'
import LikeList from '../../post-standard/like-list/LikeList'
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { transformFactory } from '../../../utils/html_helpers'


type PostPictureDesktopProps = {
  texte: string
  galerie: Array<{ h: number; w: number; url: string }>
  breadcrumb: Array<{ id: number; slug: string; nom: string }>
  titre: string
  auteur: { id: number | string; nom: string }
  date: string
  handleLife: () => void
  likes: {
    count: number
    me: boolean
    list: { [key: string]: { avatar: string | boolean; firstname: string; lastname: string } }
  }
  coms: Comments
  handleComment: (e: ChangeEvent<HTMLTextAreaElement>) => void
  onSendComment: () => void
  comment: string
  handleKeyDownComments: any
}

export default forwardRef(function PostPictureDesktop(
  {
    texte,
    galerie,
    breadcrumb,
    titre,
    auteur,
    date,
    handleLife,
    likes,
    coms,
    comment,
    handleComment,
    onSendComment,
    handleKeyDownComments,
  }: PostPictureDesktopProps,
  refContent: any
) {
  useLockBodyScroll(true)
  const refModal = useRef<HTMLDivElement>(null)
  const history = useHistory()
  const [imgFullScreen, setImagFullScreen] = useState<false | number>(false)
  const profil = useAppSelector(state=>state.auth.authentication.profil);
  const isModerateur = profil !== 'adherent'
  const [showLiker,setShowLiker] = useState(false);
  const transform = transformFactory(styles);
  useClickAway(refModal, () => {
    if (typeof imgFullScreen !== 'number' && !showLiker) history.goBack()
  })

  function onShowMedia(val: number) {
    setImagFullScreen(val)
  }

  function goPushComment() {
    onSendComment()
  }

  const number_of_comments = (coms && coms.length>0) ? coms.filter(i=>i.status == 'approved').length : '0';

  function onViewLiker(){
    setShowLiker(true)
  }


  const styled = {
    '--height-content':coms ? 'calc(100% - 3rem - 3rem)' : '100%'
  } as CSSProperties
  
  
  return (
    <Fragment>
      <div style={styled} ref={refModal} className={styles['modal-container']}>
        <div className={styles['galerie-life']}>
          {/*<img className={styles["image-galerie-life"]} src={galerie[0].url}/>*/}
          {galerie.length > 1 ? (
            <CarousselSwiper medias={galerie.map((i) => i.url)} onClick={onShowMedia} type={'cover'} />
          ) : (
            <img onClick={()=>onShowMedia(0)} className={styles['image-galerie-life']} src={galerie[0].url} />
          )}
        </div>
        <div className={styles['life-content']}>
          <button onClick={()=>history.goBack()} className={styles['button-cross']}>
            <Icon name="cross" />
          </button>
          <div ref={refContent} className={styles['content-life']}>
            <div className={styles['life-categorie']}>{breadcrumb[0].nom}</div>

            <div className={styles['title-life']}>{titre}</div>
            <div className={styles['description-life']}>{ReactHtmlParser(texte, { transform })}</div>
            {auteur?.nom ? (
              <div className={styles['author-life']}>
                <span className={styles['author-life-prefix']}>Publié par </span>
                <span className={styles['author-life-name']}>{auteur.nom}</span>
              </div>
            ) : null}
            {date ? (
              <div className={styles['date-life']}>
                <span className={styles['date-life-prefix']}>Le </span>
                <span className={styles['date-life-datetime']}>{date}</span>
              </div>
            ) : null}
           {(likes  || coms) &&  <div className={styles['actions-life']}>
             {likes  &&  <div onClick={handleLife} className={styles['like-life']}>
                <Icon name={likes?.me ? 'like-plain' : 'like'} />
                <span>{likes?.count}</span>
              </div>
              }
              {(likes && coms) &&  <div className={styles['actions-separation']} /> }
              {coms && <div className={styles['comments-life']}>
                <Icon name="comments" />
                <span>{number_of_comments}</span>
              </div>
              }
            </div>
            }
            {likes && Object.keys(likes?.list).length > 0 ? (
              <div className={styles['likes-persons-life']}>
                <span>Aimé par </span>
                {Object.keys(likes?.list)
                  .slice(0, 2)
                  .map((item) => {
                    return (
                      <span onClick={()=>history.push(`/user/${item}`)} className={styles['user-like-life']}>
                        <span>{likes.list[item].firstname}</span>
                        <span>{likes.list[item].lastname}</span>
                      </span>
                    )
                  })}
                {likes.count >= 3 ? <span className={styles["more-viewer"]} onClick={onViewLiker}>{` et par ${likes.count - 2} autre(s) personne(s)`}</span> : null}
              </div>
            ) : null}
            {coms && coms.length > 0 ? (
              <div className={styles['comments-container']}>
                {coms.map((com, key) => {
                       const styled = {
                        '--border-bottom-comment' : com.status === 'hold' ? '0px' : '0.4rem'
                      } as CSSProperties
                  return (
                    <div>
                      <div style={styled} key={key} className={styles['comment-container-life']}>
                        <div className={styles['comment-user-life']}>
                          <span>{com.user}</span>
                        </div>
                        <div className={styles['comment-date-life']}>
                          <span>Le {com?.date}</span>
                        </div>
                        <div className={styles['comment-content-life']}>{com.content}</div>
                      </div>
                      {com.status == 'hold'
                      ? <div className={styles["comment-statut"]}>{isModerateur ? 'A modérer' : 'En cours de modération'}</div>
                      : null
                    }
                    </div>
                  )
                })}
              </div>
            ) : null}
          </div>
         {coms && <div className={styles['comment-editor-wrapper-life']}>
            <textarea
              value={comment}
              onKeyDown={handleKeyDownComments}
              onChange={handleComment}
              placeholder="Ajouter un commentaire"
            />
            <button onClick={goPushComment} className={styles['button-send-comments-life']}>
              <Icon name="send" />
            </button>
          </div>
          }
        </div>
      </div>
      {typeof imgFullScreen === 'number' ? (
        <VisioPicture onClose={() => setImagFullScreen(false)} media={galerie[imgFullScreen as number].url} />
      ) : null}
      {showLiker && <LikeList list={likes.list} onHide={()=>setShowLiker(false)}/>}
    </Fragment>
  )
})
