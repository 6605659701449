import { CSSProperties, useEffect, useState } from 'react';
import { useSpring,animated,SpringValue } from 'react-spring';

import Icon,{icons} from '../../../../icons';
import FastSearchIcons from './fast-search-icons';
import styles from './SearchBarIcon.module.scss';
import { allRef } from '../../../../icons/referenceIcon';
export default function SearchBarIcon({onSelect}:{onSelect:(val:string)=>void}){
    const [focus,setFocus] = useState<boolean>(false);
    const [textSearch,setTextSearch] = useState<string>('');
    const [listFastSearch,setListFastSearch] = useState<Array<never|string>>([])

    const spring = useSpring({val:focus? "0px 0px 0px 2px #000000" : "0px 0px 0px 1px var(--grey-lighter)"});
    
    useEffect(()=>{
      
        if(textSearch!=''){
            setListFastSearch(
                allRef.filter(name=>{
                    return name.startsWith(textSearch)
                }));
        }else{
            setListFastSearch([])
        }
    },[textSearch]);

    function onSelectIcon(val:string){
        setListFastSearch([]);
        onSelect(val)
    }

    function onSearch(){
        setListFastSearch([]);
        onSelect(textSearch)  
    }

    function onKeyPress(e){
        if(e.keyCode =='13'){
            onSearch()
        }
    }

    const styled = {
        '--border-search-bar': spring.val
    } as CSSProperties

    return(
        <animated.div style={styled}  className={styles["search-bar-icon-container"]}>
            <div className={styles["input-container"]}>
                <input onKeyDown={(e)=>onKeyPress(e)}  onChange={(e)=>setTextSearch(e.target.value)} onFocus={()=>setFocus(true)} onBlur={()=>setFocus(false)} className={styles["input-search"]}/>
            </div>
            <button onClick={onSearch} className={styles["search-button"]}>
                <Icon name='search'/>
            </button>
            {listFastSearch.length > 0 && textSearch!='' &&  <FastSearchIcons onSelectIcon={onSelectIcon} text={textSearch} list={listFastSearch} /> }
        </animated.div>
    )
}