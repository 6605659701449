import { Cross } from "../../../icons/Cross"
import styles from './ButtonClose.module.scss';

type ButtonCloseProps = {
    onClick:()=>void,
    className:string
}

export default function ButtonClose({onClick,className=''}:ButtonCloseProps){
    return <button className={`${styles["button-close"]} ${className}`} onClick={onClick}><Cross/></button>
}