import React from 'react'

export const Curve = (props) => {
  return (
    <svg width="80" height="50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 50">
      <defs>
        <linearGradient id={props.id} x1="40" y1="50" x2="40" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M80,50C56,50,80,4,35,4H0V0H80Z" />
    </svg>
  )
}
