import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../config/pci/api'
import { stats } from '../../config/stats'

type checkOutStateType = 'LOADING' | 'ERROR' | 'READY'

export type Comments = Array<{
  content: string
  date: string
  id_com: string
  id_user: string
  parent: string
  status: 'hold' | 'approved'
  user: string
}>

export type Standard = {
  auteur: { id: number | string; nom: string }
  breadcrumb: Array<{ id: number; slug: string; nom: string }>,
  mega_menu:boolean,
  date: string
  galerie: Array<{ h: number; w: number; url: string }>
  texte: string
  titre: string,
  docs:Array<{file:string,name:string}>,
  url:string,
  likes: {
    count: number
    me: boolean
    list: { [key: string]: { avatar: string | boolean; firstname: string; lastname: string } }
  }
  coms: Comments
}

type StandardState = {
  post: Standard | {}
  error: string,
  checkOutState: checkOutStateType
  checkOutComsState: checkOutStateType
  checkOutLikeState: checkOutStateType
}

let initStandardPost: StandardState = {
  post: {},
  error: '',
  checkOutState: 'LOADING',
  checkOutComsState: 'READY',
  checkOutLikeState: 'READY',
}

export const getPostStandard = createAsyncThunk(
  '/standard/post',
  async ({ id_post }: { id_post: string | number }, { rejectWithValue, getState }) => {
    const {
      auth: {
        authentication: { id, nonce },
      },
    } = getState() as any
    try {
      const response = await api.get('/onepost', {
        params: {
          user: id,
          nonce: nonce,
          post: id_post,
          stat: stats(),
        },
      })
      //@ts-ignore
      if (response.data.hasOwnProperty('error'))
        //@ts-ignore
        return rejectWithValue(response.data.error)
      //@ts-ignore
      return response.data
    } catch (err: any) {
      if (!err.response) {
        throw err
      } else if (err.statut == 404) {
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
      return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
    }
  }
)

export const postComment = createAsyncThunk(
  '/standard/postComment',
  async ({ com, id_post,lastContent }: { com: string; id_post: string | number,lastContent:any }, { rejectWithValue, getState }) => {
    const {
      auth: {
        authentication: { id, nonce },
      },
    } = getState() as any
    try {
      const response = await api.get('/newcom', {
        params: {
          user: id,
          nonce: nonce,
          post: id_post,
          com: com,
          stat: stats(),
        },
      })
      //@ts-ignore
      if (response.data.hasOwnProperty('error'))
        //@ts-ignore
        return rejectWithValue(response.data.error)
      //@ts-ignore
      return response.data
    } catch (err: any) {
      if (!err.response) {
        throw err
      } else if (err.statut == 404) {
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
      return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
    }
  }
)

export const postLike = createAsyncThunk(
  '/standard/postLike',
  async ({ id_post,lastContent }: { id_post: string | number,lastContent:any }, { rejectWithValue, getState }) => {
    const {
      auth: {
        authentication: { id, nonce },
      },
    } = getState() as any
    try {
      const response = await api.get('/newlike', {
        params: {
          user: id,
          nonce: nonce,
          post: id_post,
          stat: stats(),
        },
      })
      //@ts-ignore
      if (response.data.hasOwnProperty('error'))
        //@ts-ignore
        return rejectWithValue(response.data.error)
      //@ts-ignore
      return response.data
    } catch (err: any) {
      if (!err.response) {
        throw err
      } else if (err.statut == 404) {
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
      return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
    }
  }
)

export const postStandardSlice = createSlice({
  name: 'standard',
  initialState: initStandardPost,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getPostStandard.pending, (state, { payload, meta }) => {
      state.checkOutState = 'LOADING'
    })

    builder.addCase(getPostStandard.rejected, (state, { payload, meta }) => {
      state.checkOutState = 'ERROR'
    })

    builder.addCase(getPostStandard.fulfilled, (state, { payload, meta }: { payload: any; meta: any }) => {
      state.checkOutState = 'READY'
      state.post = payload
    })

    //POST COMMENT
    builder.addCase(postComment.pending, (state, { payload, meta }) => {
      state.checkOutComsState = 'LOADING'
    })

    builder.addCase(postComment.fulfilled, (state, { payload, meta }: { payload: any; meta: any }) => {
      state.checkOutComsState = 'READY'
      ;(state.post as Standard).coms = payload.comments as Comments
    })

    //POST LIKE
    builder.addCase(postLike.pending, (state, { payload, meta }) => {
      state.checkOutLikeState = 'LOADING'
    })
    builder.addCase(postLike.fulfilled, (state, { payload, meta }: { payload: any; meta: any }) => {
      state.checkOutLikeState = 'READY'
      ;(state.post as Standard).likes = payload
    })
  },
})

export default postStandardSlice.reducer
