import { CSSProperties, Fragment,useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDidMountEffect from '../../../hooks/useDidmountEffect';
import { Cross } from '../../../icons/Cross';
import CrossClose from '../../../icons/CrossClose';
import { ADD_HASTAGS, REMOVE_HASTAGS } from '../../../redux/actions/pci/types';
import { useAppSelector } from '../../../redux/hooks';
import styles from './HastagList.module.scss';

export default function HastagList({isEdit}):JSX.Element | null{
    const dispatch = useDispatch();
    const {tags} = useAppSelector(state=>state.social);
    const [edit,setEdit] = useState(false);
    const [newHastag,setNewHastag] = useState<string | boolean>(false);

    const styledAddTag = {
        '--bg-btn-add-tag': edit ? 'var(--success-color)' : 'var(--grey-color)'
    } as CSSProperties

    function onAddTag(){
        if(newHastag) dispatch({type:ADD_HASTAGS,payload:newHastag});
        setEdit(false)
        
    }

    function onRemoveTag(value){
        dispatch({type:REMOVE_HASTAGS,payload:value}) 
    }

    useDidMountEffect(()=>{
        if(!edit){
            setNewHastag(false)}
    },[edit])

    function handlerKeyPress(e){
        if(e.key === 'Enter'){
            if(newHastag) dispatch({type:ADD_HASTAGS,payload:newHastag});
            setEdit(false) 
        }
        else return false
    }


    return(
        <Fragment>
        {tags && <div className={styles["hastag-list-container"]}>
            {tags.map((tag,key)=>{
                return <div key={key}  className={styles["hastag"]}>{`${!tag.startsWith('#') ? '#' : ''}${tag}`} 
                            {isEdit && <button className={styles["button-remove-tag"]} onClick={()=>onRemoveTag(tag)}><CrossClose/></button>}
                        </div>
            })}
           
        </div>}
         {isEdit && <div className={styles["container-add-tag"]}>
            {edit ? <input onKeyPress={handlerKeyPress} onChange={(e)=>setNewHastag(e.target.value)} placeholder='hastags' /> : null}
            {!edit ? <div style={styledAddTag} onClick={()=>setEdit(true)} className={styles["hastag-more"]}>{'+ Ajouter un hastag'}</div> 
            :<div className={styles["action-tag"]}>
                <div onClick={onAddTag} className={styles["add-tag"]}>Valider</div>
                <div onClick={()=>setEdit(false)} className={styles["remove-tag"]}>Annuler</div>
            </div>
                }
         </div>}
        </Fragment>
    )

}