import {Link} from 'react-router-dom';
import Icon from '../../../../icons';
import styles from './CellLink.module.scss';
export default function CellLink({slug,value}){
    return(
        <Link  className={styles["cell-link"]} to={slug}>
            <Icon name='arrow-right'/>
            <span>{value}</span>
        </Link>
    )
}