import React from 'react'

export const MarkerIcon = () => {
  return (
    <svg  viewBox="0 0 72 72"  xmlns="http://www.w3.org/2000/svg">
    <path 
    fill="#FFF"
                    d={
                        `M36,3.9c14.3,0,26,11.7,26,26c0,18-20.9,34.3-26,38c-5.1-3.7-26-19.9-26-38C10,15.6,21.7,3.9,36,3.9 M36,41.1
                        c6.2,0,11.3-5.1,11.3-11.3S42.2,18.5,36,18.5s-11.3,5.1-11.3,11.3S29.8,41.1,36,41.1 M36,1.9c-15.5,0-28,12.5-28,28
                        c0,21.8,28,40.4,28,40.4s28-18.7,28-40.4C64,14.4,51.5,1.9,36,1.9L36,1.9z M36,39.1c-5.1,0-9.3-4.2-9.3-9.3c0-5.2,4.2-9.3,9.3-9.3
                        c5.2,0,9.3,4.2,9.3,9.3C45.3,35,41.1,39.1,36,39.1L36,39.1z`
                         

                    }
                    />
    </svg>
  )
}