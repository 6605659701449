import Portal from '../../components/common/portal/Portal';
import styles from './Tutoriel.module.scss';
import { Fragment, useEffect, useState } from 'react';
import {animated,config,useSpring} from 'react-spring';
import EasyButton from '../../easy-ui/button';
import Logo from '../../components/logo';
import ItemTuto from './item-tuto';
import { isBrowser } from '../../utils/isBrowser';
import axios, { AxiosResponse } from 'axios';
import {useHistory} from 'react-router-dom'


export default function Tutoriel():JSX.Element{
    const currentBrowser = isBrowser();
    const history = useHistory();
    const [data,setData] = useState<any>(null);
    const [isVisible,setIsVisible] = useState<boolean>(false)

    const transition = useSpring({
        transform:isVisible ? 'translateY(0%)' : 'translateY(-100%)' ,
        config:config.slow,
    
        onRest:()=>{!isVisible && history.goBack()}
    });

    //Recupere les differentes information 
    useEffect(()=>{
        const fetchData = async()=>{
            var response = await axios.get<AxiosResponse<any, any>>('/data/tuto.json')
        if(response){
            //@ts-ignore next-line
            setData(response.data.tuto[currentBrowser])
            setIsVisible(true)
        }
    }
        fetchData()
    },[])
    

    function onLeave(){
            setIsVisible(false);
        }
    
    return(
        <Portal id='tutoriel'>
            <div className={styles["background-tutoriel"]}>
                <animated.div style={transition} className={styles["modal-container"]}>
                <EasyButton className={styles['button-close-tutoriel']} name='close' onClick={onLeave}/>
                    <div className={styles["tutorial-container"]}>
                        <div className={styles["header-tutotiel"]}>
                          
                            <Logo type="WHITE" className={styles["logo-company"]}/>
                            <h3>Comment télécharger votre application</h3>
                        </div>
                        <Fragment>
                        {
                            data && data.part.map((item:{num:number,content:string,icon:string},key:string)=>{
                                return <ItemTuto num={item?.num} content={item?.content} icon={item?.icon} />
                            })
                            } 
                        </Fragment>
                        <div className={styles["action-leave"]}>
                            <EasyButton 
                                name='text' 
                                type={'primary'} 
                                className={styles["button-valid-tuto"]}  
                                onClick={onLeave}
                            >
                                <span>J'ai Compris</span>
                            </EasyButton>
                        </div>
                    </div>
                </animated.div>
            </div>
        </Portal>
    )
}