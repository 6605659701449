import { useEffect, useState } from 'react';
import BlockAuth from '../../components/block-page/block-auth';
import { useQuery } from '../../hooks/useQuery';
import styles from './VerifConnection.module.scss';
import {useHistory,Redirect} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import api from '../../config/pci/api';
import EasyButton from '../../easy-ui/button';
import { useSpring,animated } from 'react-spring';
import Loading from '../../components/pgl/Loading';


export default function VerifConnection({}){

    const query = useQuery();
    const code = query.get('code');
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState<boolean | string>(false);
    const dispatch = useDispatch();
    const history = useHistory();
    //@ts-ignore
    const token = useSelector(state=>state.auth.token)    

    

    async function fetchData(){
        try{
            const response =  await api.get<unknown, any>('/decode',{
                params:{
                    code:code
                }
                })
            if(!response?.data.error && response.data){
                dispatch({type:'NEW_USER_CONNECTED',payload:{data:response.data,token:token}})
                setLoading(false);
                
                if(response.data.type === 'prem') 
                return history.replace({pathname:'/bienvenue',
                    state:{
                        user:{
                            prenom:response.data.prenom,
                            nom:response.data.nom
                    }
                }});
                else if(response.data.type === 'mdp') return history.replace('/nouveau-mot-de-passe')
                else return false
            }
            else{
                setLoading(false) 
               return setError(response?.data.error ?? 'Erreur serveur')
            }
        }
        catch(error){
            console.log(error)
            setError('Erreur serveur')
            setLoading(false)
        }
    }


    useEffect(()=>{
        fetchData();
    },[]);


    return(
        <BlockAuth title={""} loading={loading} subtitle={""}>
            {
                loading 
                    ? <Loading/> 
                :error 
                    ? <div className={styles["error-first-connecion"]}>{error}</div>
                :null
            }
        </BlockAuth>
    )

}