import * as React from "react"

const AtomIcon = (props) => (
  <svg
    fill="none"
    height={props?.height}
    width={props?.width}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={props.fill}
      d="M16 12a5 5 0 1 0-4.337-2.51l-2.714 1.808a4 4 0 1 0 .23 5.13l3.887 1.943a3 3 0 1 0 .671-1.341l-3.886-1.943a4.004 4.004 0 0 0-.113-2.513l2.863-1.907A4.982 4.982 0 0 0 16 12Zm0-1.5a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Zm-10 6a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5ZM17.5 19a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
    />
  </svg>
)

export default AtomIcon
