import styles from './OptionMenu.module.scss'
import React, { ForwardedRef, ForwardRefExoticComponent, RefObject, SyntheticEvent, useRef } from 'react'
import { useClickAway } from 'react-use'
import Icon from '../../../../icons'
import { Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router-dom'
import config from '../../../../config'
type optionMenuProps = {
  onClose: Dispatch<SetStateAction<boolean>>
  onUpdateAdminPublication: () => void
  onDeleteAdminPublication: () => void
  draft: boolean
}

export type { optionMenuProps }

const OptionMenu = React.forwardRef<HTMLDivElement, optionMenuProps>(
  (
    { onClose, onUpdateAdminPublication, onDeleteAdminPublication, draft },
    ref: ForwardedRef<HTMLDivElement>
  ): JSX.Element => {
    const optionsRef = useRef(null)
    const history = useHistory()

    function onClickInside(e: SyntheticEvent) {
      e.stopPropagation()
    }

    useClickAway(ref as RefObject<HTMLDivElement>, () => onClose(false))

    return (
      <div ref={ref} className={styles['option-card-container']}>
        <div className={styles['option-list']} onClick={onClickInside}>
          <div className={styles['item-list']}>
            <div>
              <Icon name="new-edit" />
            </div>
            <div className={styles['item-text']}>
              <div onClick={onUpdateAdminPublication} className={styles['description']}>
                Modifier la publication
              </div>
            </div>
          </div>
        </div>
        {draft && (
          <div className={styles['option-list']} onClick={onClickInside}>
            <div className={styles['item-list']}>
              <div>
                <Icon name="moderate" />
              </div>
              <div className={styles['item-text']}>
                <div onClick={onUpdateAdminPublication} className={styles['description']}>
                  Modérer la publication
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={styles['option-list']} onClick={onClickInside}>
          <div className={styles['item-list']}>
            <div>
              <Icon name="trash" />
            </div>
            <div className={styles['item-text']}>
              <div onClick={onDeleteAdminPublication} className={styles['description']}>
                Supprimer la publication
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default OptionMenu
