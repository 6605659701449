import LoadingUser from "./loading-user";
import LoadingApp from "./loading-app";


const Components ={
    user:LoadingUser,
    app:LoadingApp,
}


type LoadingPageProps = {
    type:'user' | 'app'
}

export default function LoadingPage({type,...props}:LoadingPageProps):JSX.Element| null{
    let Component = Components[type];

    if(Component!==undefined){
        return <Component {...props} />
    }
    return null
}