import React from 'react';

export const Realisation = () => {
  return (
<svg version="1.1" id="Calque_1"  x="0px" y="0px"
	 viewBox="0 0 11.6 16.6" >
<path id="Icon_material-lightbulb-outline" d="M3.3,15.8c0,0.5,0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8V15h-5V15.8z M5.8,0
	C2.6,0,0,2.6,0,5.8c0,1.9,0.9,3.7,2.5,4.8v1.9c0,0.5,0.4,0.8,0.8,0.8h5c0.5,0,0.8-0.4,0.8-0.8v-1.9c2.6-1.8,3.3-5.5,1.5-8.1
	C9.5,0.9,7.7,0,5.8,0z M8.2,9.2L7.5,9.7v1.9H4.2V9.7L3.4,9.2c-1.9-1.3-2.4-3.9-1-5.8s3.9-2.4,5.8-1s2.4,3.9,1,5.8
	C8.9,8.6,8.6,8.9,8.2,9.2L8.2,9.2z"/>
</svg>

  );
};
