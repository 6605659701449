import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../config/pci/api";
import { stats } from "../../config/stats";

export const getFirstRowSocialNetwork = createAsyncThunk(
    'social-network/firstrow',
    async ({thematic}:{thematic:string}, { rejectWithValue, getState }) => {
      const {
        session: { lastconnect },
        auth: {
          authentication: { id, nonce },
        },
      } = getState() as any
  
      try {
        const [responseData, responseCategorie] = await Promise.all([
          api.get('/filposts', {
            params: {
              user: id,
              nonce: nonce,
              page: 1,
              cat: 'partage',
              lastconnect: lastconnect,
              stat: stats(),
              thematique:thematic === 'all' ? false : thematic
            },
          }),
          api.get('/listthematiquesrs', {
            params: {
              user: id,
              nonce: nonce,
            },
          }),
        ])
  
        const firtRowResponse = [responseData, responseCategorie].map((response) => {
          //@ts-ignore
          if (response.data.hasOwnProperty('error') && (response.data.posts.length === 0 || !response.data.posts)){
            
            //@ts-ignore
            return rejectWithValue(response.data.error)
          } 
          //@ts-ignore
          return response.data
        })
  
        if(! (firtRowResponse as any)[0].hasOwnProperty('posts')){
          return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
        }
  
        return firtRowResponse
      } catch (err: any) {
        if (!err.response) {
          throw err
        } else if (err.statut == 404) {
          return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
        }
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
    }
  )


  export const getSocialNetWork = createAsyncThunk(
    'social-network/getSocialNetWork',
    async ({ thematic }: {thematic:string }, { rejectWithValue, getState }) => {
      const {
        session: { lastconnect },
        auth: {
          authentication: { id, nonce },
        },
      } = getState() as any
  
      try {
        const response = await api.get('/filposts', {
          params: {
            user: id,
            nonce: nonce,
            page: 1,
            cat: 'partage',
            lastconnect: lastconnect,
            stat: stats(),
            thematique:thematic === 'all' ? false : thematic
          },
        })
  
        //@ts-ignore
        if (response.data.hasOwnProperty('error') && (response.data.posts.length === 0 || !response.data.posts))
          //@ts-ignore
          return rejectWithValue(response.data.error)
        //@ts-ignore
        return response.data
      } catch (err: any) {
        if (!err.response) {
          throw err
        } else if (err.statut == 404) {
          return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
        }
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
    }
  )


  export const getMoreSocialNetwork = createAsyncThunk(
    'social-network/getMoreSocialNetWork',
    async ({ thematic,page }: {thematic:string,page:number }, { rejectWithValue, getState }) => {
      const {
        session: { lastconnect },
        auth: {
          authentication: { id, nonce },
        },
      } = getState() as any
  
      try {
        const response = await api.get('/filposts', {
          params: {
            user: id,
            nonce: nonce,
            page: page,
            cat: 'partage',
            lastconnect: lastconnect,
            stat: stats(),
            thematique:thematic === 'all' ? false : thematic
          },
        })
  
        //@ts-ignore
        if (response.data.hasOwnProperty('error') && (response.data.posts.length === 0 || !response.data.posts))
          //@ts-ignore
          return rejectWithValue(response.data.error)
        //@ts-ignore
        return response.data
      } catch (err: any) {
        if (!err.response) {
          throw err
        } else if (err.statut == 404) {
          return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
        }
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
    }
  )


  export const deleteSocialNetwork = createAsyncThunk(
    'social-network/delete',
    async ({ id_publication,thematic }: {id_publication:string| number,thematic:string }, { rejectWithValue, getState }) => {
      const {
        auth: {
          authentication: { id, nonce },
        },
      } = getState() as any
      try{
        const response = await  api.get('/addelpost', {
          params: {
            user: id,
            nonce: nonce,
            post: id_publication,
            stat: stats(),
          },
        })
         //@ts-ignore
        if(response.data.hasOwnProperty('error')){
           //@ts-ignore
          return rejectWithValue(response.data.error)
        }
      }
      catch(err:any){
        if (!err.response) {
          throw err
        } else if (err.statut == 404) {
          return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
        }
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
    })