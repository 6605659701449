import styles from './Swiper.module.scss'
import { Fragment, CSSProperties, useRef, useState, useLayoutEffect, useEffect, useCallback } from 'react'
import { animated, useSprings } from 'react-spring'
import { useDrag } from '@use-gesture/react'
import { ChevronRight } from '../../../icons/ChevronRight'
import _ from 'lodash'
export default function Swiper({ width, medias, onClick, type = 'cover' }) {
  const index = useRef(0)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isResizing, setIsResizing] = useState(false)
  const [coord, setCoord] = useState(0)

  useLayoutEffect(() => {
    let timeoutId

    function handleResize() {
      onSpringAnimate(false, 0)
      onSpringAnimate(true, 0)
      clearTimeout(timeoutId)
      setIsResizing(true)
      timeoutId = setTimeout(() => {
        setIsResizing(false)
      }, 500)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  /**
   * Spring Caroussel
   */

  const [springs, api] = useSprings(medias.length, (i) => ({
    x: i * width,
    display: 'block',
  }))

  /**
   * Animation Slide
   * @param active
   * @param mx
   */
  function onSpringAnimate(active, mx) {
    api.start((i) => {
      if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
      const x = (i - index.current) * width + (active ? mx : 0)
      return { x, display: 'block' }
    })
  }
  /**
   * drag slide
   */

  const bind = useDrag(({ active, movement: [mx], direction: [xDir], cancel, down }) => {
    if (active && Math.abs(mx) > width / 2) {
      index.current = _.clamp(index.current + (xDir > 0 ? -1 : 1), 0, medias.length - 1)
      setCurrentIndex(index.current)
      cancel()
    }
    onSpringAnimate(active, mx)
  })

  /**
   * Dots Control
   * @param indexSelected
   */

  function goToSlide(indexSelected) {
    index.current = indexSelected
    setCurrentIndex(indexSelected)
    onSpringAnimate(false, 0)
  }

  /**
   * Control Prev and Next
   * @param xDir
   */

  function onChangeSlide(xDir) {
    index.current = _.clamp(index.current + (xDir > 0 ? -1 : 1), 0, medias.length - 1)
    setCurrentIndex(index.current)
    onSpringAnimate(false, 0)
  }

  const ref = useRef<HTMLDivElement>(null)

  const onStart = useCallback((e) => {
    setCoord(e.clientX)
  }, [])

  const onEnd = useCallback(
    (e, i) => {
      if (e.clientX == coord) {
        onClick(i)
      }
    },
    [coord]
  )
  /*function  onEnd(e,i){
      if(e.clientX == coord ){
        onClick(i)
      }
    }*/

  return (
    <Fragment>
      {springs.map(({ x, display }, i) => (
        <animated.div
          ref={ref}
          onMouseDown={onStart}
          onMouseUp={(e) => onEnd(e, i)}
          className={styles['slide']}
          {...bind()}
          key={i}
          style={{ display, x, zIndex: medias.length - i }}
        >
          <animated.div style={{ backgroundImage: `url(${medias[i]})`, backgroundSize: type }} />
        </animated.div>
      ))}
      {isResizing && <div className={styles['resize-slide']}>Redimensionnement en cours...</div>}
      <Fragment>
        {/*currentIndex !== 0 &&*/ (
          <button onClick={() => onChangeSlide(1)} className={`${styles['control']} ${styles['left']}`}>
            <ChevronRight />
          </button>
        )}
        {/*currentIndex !== medias.length - 1 &&*/ (
          <button onClick={() => onChangeSlide(-1)} className={`${styles['control']} ${styles['right']}`}>
            <ChevronRight />
          </button>
        )}
      </Fragment>

      {(medias.length > 0 && medias.length < 7) &&
      <div className={styles['dots-container']}>
        {medias.map((i, key) => {
          const styledDots = {
            '--dots-color': key == currentIndex ? `rgba(255,255,255,1.0)` : `rgba(255,255,255,0.5)`,
            pointerEvents: 'none'
          } as CSSProperties
          return <button key={key} onClick={() => goToSlide(key)} style={styledDots} className={styles['dots']} />
        })}
      </div>}
    </Fragment>
  )
}
