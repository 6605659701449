import styles from './TabsCategory.module.scss';
import {useState} from 'react';
import ItemCategory from './item-category';

interface ITabsCategory{
    onChange:any,
    homeRubrique:any,/*{type:string,id:number,slug:string,screen:string,nom:string},*/
    categorie:number,
    onChoose:any
}


export default function TabsCategory({onChange,homeRubrique,categorie,onChoose}:ITabsCategory):JSX.Element{
    return(
        <div className={styles["tabs-category-container"]}>
           {homeRubrique.map((i:any,key: any)=>{
               return <ItemCategory key={key} onClick={onChoose} categorie={categorie} name={i.nom} id={i.id}/>
           })}
            <div className={styles["bar-category"]}></div>   
        </div>
    )
}
