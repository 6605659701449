//@ts-nocheck
import styles from './Login.module.scss';
import { useState } from 'react';
import config from '../../config';
import BlockAuth from '../../components/block-page/block-auth';
import InputStandard from '../../easy-ui/input/input-standard';
import Icon from '../../icons';
import EasyInput from '../../easy-ui/input';
import EasyButton from '../../easy-ui/button';
import {Link} from 'react-router-dom';
import EasyLink from '../../easy-ui/link';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../config/pci/api';
import { stats } from '../../config/stats';
import { AxiosResponse } from 'axios';
import {useHistory} from 'react-router-dom'
import ModalConnexion from '../../features/connexion/modal-connexion';
import { handlerEnterKeyPress } from '../../utils/handlerFunction';

export default function Login(){
  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const {token} = useSelector(state=>state?.auth);
  const [error,setError] = useState<string | false>(false);
  const {type:typeAuth} = useSelector(state=>state.auth);
  const [loadingLogin,setLoadingLogin] = useState(false);


  async function onLogin(){
    setLoadingLogin(true);
    setError(false);
    try{
    const response = await api.get<AxiosResponse<unknown, any>>('/login', {
      params: {
        log: email,
        pass: password,
        noken: token || 'no-notif',
        stat: stats(),
      },
    })
    //@ts-ignore next-line
    if(response.data.error || response.data?.length === 0){
      setLoadingLogin(false)
      return setError('Vos identifiants ne sont pas corrects. Veuillez r\u00e9essayer.')
    }
    else{
      if(response.data.type === 'prem'){
        dispatch({
          type: 'NEW_LOG_IN_TEMP',
          payload: { data: response.data, email: email, password: password,token: token || 'no-notif' },
        })
        setLoadingLogin(false)
       return history.push({pathname:'/bienvenue',state:{user:response.data}})
      }
      else if(response.data.type === 'log'){

      dispatch({
        type: 'SIGN_IN',
        payload: { response: response.data, email: email, password: password },
      })
        setLoadingLogin(false)
        return history.replace('/')
    }
    else if(response.data.type === 'mdp'){
      dispatch({
        type: 'NEW_LOG_IN',
        payload: { data: response.data, email: email, password: password },
      })
      setLoadingLogin(false)
      return history.push({pathname:'/nouveau-mot-de-passe',state:{user:response.data}})
    }
    else{
      dispatch({
        type: 'SIGN_IN',
        payload: { response: response.data, email: email, password: password },
      })
      setLoadingLogin(false)
        return history.replace('/')
      }
    }
  }catch(error){
      setLoadingLogin(false)
      setError("Une erreur lors de l'authentification s'est produite")
    }
  }

    return(
      <BlockAuth title={'Connexion'}  subtitle={'Veuillez vous identifier.'}>
          <div className={styles["form"]}>
            <EasyInput onKeyHandler={(e)=>handlerEnterKeyPress(e,onLogin)} categorie={'standard'} placeholder={'JohnDoo@plus.fr'} value={email} name={'email'} id={'email'} label={'Identifiant'} onchange={(e) => setEmail(e.target.value)} type={'text'} icon={<Icon name={"identifiant"}/>} />
            <EasyInput onKeyHandler={(e)=>handlerEnterKeyPress(e,onLogin)} categorie={'password'} placeholder={'Entrer votre mot de passe'} value={password} name={'password'} id={'password'} label={'Mot de passe'} onchange={(e) => setPassword(e.target.value)} type={'password'}  />
            <EasyButton isFetch={loadingLogin} onClick={()=>onLogin()} type="primary" name="text" text={"Connexion"}/>
            <div className={styles["error-auth"]}>{error}</div>
          </div>
          <EasyLink type='primary' className={styles["link-page"]} to='/mot-de-passe-oublie'><span>Mot de passe oublié</span></EasyLink>
          {/*['prem','mdp'].includes(typeAuth)  && <ModalConnexion type={typeAuth}/> */}
      </BlockAuth>
    )

    


}