import { ChangeEvent, CSSProperties, Fragment, useState } from 'react'
import Breadcrump from '../../../components/breadcrump/BreadCrump'
import CarousselSwiper from '../../../components/caroussel-swiper'
import Icon from '../../../icons'
import { useAppSelector } from '../../../redux/hooks'
import styles from './PostStandardDesktop.module.scss'
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom'
import { Standard } from '../postStandardSlice'
import VisioPicture from '../../../components/visio-picture'
import LikeList from '../like-list/LikeList';
import {useHistory,useLocation} from 'react-router-dom';
import { transformFactory } from '../../../utils/html_helpers'


type PostStandardDesktopProps = {
  onSendComment: (type: 'desktop' | 'mobile') => void
  handleLife: () => void
  handleComment: (e: ChangeEvent<HTMLTextAreaElement>) => void
  comment: string,
  handleKeyDownComments: any
}

export default function PostStandardDesktop({
  onSendComment,
  handleLife,
  handleComment,
  handleKeyDownComments,
  comment,
}: PostStandardDesktopProps) {
  const { breadcrumb, galerie, titre, texte, auteur, date, coms, likes, docs, url,mega_menu } = useAppSelector(
    (state) => state.standard.post
  ) as Standard

  const history = useHistory();
  const profil = useAppSelector(state=>state.auth.authentication.profil);
  const isModerateur = profil !== 'adherent'
  const [showLiker,setShowLiker] = useState(false);
  const [imgFullScreen, setImagFullScreen] = useState<false | number>(false);
  const transform = transformFactory(styles);

  function onShowMedia(val: number) {
    setImagFullScreen(val)
  }
  const number_of_comments = (coms && coms.length>0) ? coms.filter(i=>i.status == 'approved').length : '0';

  function onViewLiker(){
    setShowLiker(true)
  }

  return (
    <Fragment>
      <div className={styles['post-standard-desktop']}>
        {breadcrumb ? <Breadcrump items={breadcrumb} onepost={true} mega_menu={mega_menu} /> : null}
        {galerie && galerie.length > 0 && (
          <div className={styles['img-container']}>
            {galerie && galerie.length > 1 ? (
              <CarousselSwiper type={'contain'} medias={galerie.map((i) => i.url)} onClick={onShowMedia} />
            ) : galerie.length > 0 ? (
              <img onClick={()=>onShowMedia(0)} className={styles['image-galerie-standard']} src={galerie[0].url} />
            ) : null}
          </div>
        )}
        {titre && <div className={styles['post-titre']}>{titre}</div>}
        {texte && <div className={styles['post-content']}>{ReactHtmlParser(texte,{transform})}</div>}
        <div className={styles['post-author']}>
          {auteur && (
            <span>
              Publié par{' '}
              <Link to={`/user/${auteur?.id}`} className={styles['link-author']}>
                {auteur?.nom}
              </Link>
            </span>
          )}
          {date && <div>Le {date}</div>}
        </div>
        {url && <div className={styles['link-part']}>
          <div className={styles['title-link']}>Lien :</div>
          <a className={styles['link']} href={url}>
            <span className={styles['name-link']}>{url}</span>
            <span className={styles['icon-link']}>
              <Icon name="globe-2" />
            </span>
          </a>
        </div>
        }
        {docs && docs.length > 0 && (
          <div className={styles['list-documents']}>
            <div className={styles['title-Document']}>Liste des Documents : </div>
            <div className={styles["documents-wrapper"]}>
            {docs.map((document) => {
              return (
                <a className={styles['document']} href={document.file}>
                  <span className={styles['name-doc']}>{document.name}</span>
                  <span className={styles['icon-doc']}>
                    <Icon name="download-2" />
                  </span>
                </a>
              )
            })}
            </div>
          </div>
        )}
        {likes && (
          <div className={styles['actions-life']}>
            <div onClick={handleLife} className={styles['like-life']}>
              <Icon name={likes?.me ? 'like-plain' : 'like'} />
              <span>{likes?.count}</span>
            </div>
           {coms!=undefined && 
           <Fragment>
           <div className={styles['actions-separation']} />
            <div className={styles['comments-life']}>
              <Icon name="comments" />
              <span>{number_of_comments}</span>
            </div>
            </Fragment>
            }
          </div>
        )}
        {likes && Object.keys(likes?.list).length > 0 ? (
          <div className={styles['likes-persons-life']}>
            <span>Aimé par </span>
            {Object.keys(likes?.list)
              .slice(0, 2)
              .map((item) => {
                return (
                  <span onClick={()=>history.push(`/user/${item}`)} className={styles['user-like-life']}>
                    <span>{likes.list[item].firstname}</span>
                    <span>{likes.list[item].lastname}</span>
                  </span>
                )
              })}
            {likes.count >= 3 ? <span className={styles["more-viewer"]} onClick={onViewLiker}>{` et par ${likes.count - 2} autre(s) personne(s)`}</span> : null}
          </div>
        ) : null}
        {coms && coms.length > 0 ? (
          <div className={styles['comments-container']}>
            {coms.map((com) => {
              const styled = {
                '--border-bottom-comment' : com.status === 'hold' ? '0px' : '0.4rem'
              } as CSSProperties
              return (
                <div>
                <div style={styled} className={styles['comment-container-life']}>
                  <div className={styles['comment-user-life']}>
                    <span>{com.user}</span>
                  </div>
                  <div className={styles['comment-date-life']}>
                    <span>Le {com?.date}</span>
                  </div>
                  <div className={styles['comment-content-life']}>{com.content}</div>
                </div>
                {com.status == 'hold'
                  ? <div className={styles["comment-statut"]}>{isModerateur ? 'A modérer' : 'En cours de modération'}</div>
                  : null
                }
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
      {coms !== undefined && (
        <div className={styles['area-zone-conversation']}>
          <div className={styles['comment-wrapper']}>
            <textarea
              onKeyDown={(e)=>handleKeyDownComments(e,'desktop')}
              value={comment}
              onChange={handleComment}
              placeholder="Ajouter un commentaire"
            />
            <button onClick={() => onSendComment('desktop')} className={styles['button-send-comments-life']}>
              <Icon name="send" />
            </button>
          </div>
        </div>
      )}
    {typeof imgFullScreen === 'number' ? (
    <VisioPicture onClose={() => setImagFullScreen(false)} media={galerie[imgFullScreen as number].url} />
    ) : null}
    {showLiker && <LikeList list={likes.list} onHide={()=>setShowLiker(false)}/>}
    </Fragment>
  )
}
