import styles from './ButtonWrapper.module.scss';
import {useSpring,animated} from 'react-spring'
import {CSSProperties, useState} from 'react';
import { hexToCSSFilter } from 'hex-to-css-filter';
import { getColorRgbaByType } from '../helpers';



type ButtonWrapperProps = {
    children?:JSX.Element,
    type:string,
    onClick:()=>void,
    size:string
}   



export default function ButtonWrapper({children,type,onClick,size,...props}){
    const [hover,setHover] = useState(false);
    const springButton = useSpring({
        background:hover ? 
            `rgba(${getColorRgbaByType(type)},0.8)` 
            : `rgba(${getColorRgbaByType(type)},1)` 
    });
    const styledButton = {
        '--shadow-button' :  `0 4px 15px 0 rgba(${getColorRgbaByType(type)},0.8)`
    } as CSSProperties

    
    return(
        <animated.button 
            style={{...springButton,...styledButton}}
            onMouseEnter={()=>setHover(true)} 
            onMouseLeave={()=>setHover(false)} 
            onClick={onClick} 
            className={styles["button-wrapper"]}
            >
            {children}
        </animated.button>
    )
}