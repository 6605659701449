import { CSSProperties } from 'react';
import CellTable from './cell-table';
import styles from './EasyTable.module.scss';
import { useMediaQuery } from 'react-responsive';

type EasyTableProps = {
    entitled:any,
    values:any,
    handleLink?:(val:string)=>void
}

export default function EasyTable({entitled,values,handleLink}:EasyTableProps){
    const isMobile = useMediaQuery({ query: '(max-width:768px )'})
    return(
        <div className={styles["easy-table-container"]}>
            <div className={styles["header-table"]}>
               {entitled &&  Object.keys(entitled).map((item,key,array)=>{
                    const styled = {
                        '--border-left-radius-label':key === 0 ? '0.4rem' : '0rem',
                        '--border-right-radius-label':key === (array.length -1) ? '0.4rem' : '0rem',
                        '--background-color-label':key === 0 ? 'var(--ui-secondary-color)' : key%2 === 0 ? 'var(--ui-primary-darker)' : 'var(--ui-primary-color)',
                        '--color-label':key === 0 ? 'var(--ui-text-secondary-color)' : 'var(--ui-text-primary-color)'
                    }
                    return( <div 
                                key={key}
                                style={styled as CSSProperties} 
                                className={styles["label-column"]}>
                                {entitled[item][isMobile ? "label-mb":"label"]}
                             
                            </div>)})}
            </div>
            <div className={styles["grid-line-table"]}>
                {(values && entitled) && values.map((item,item_key,array)=>{
                    
                    return Object.keys(item).filter(i=>i!=='new').sort(function (a,b){return entitled[a].orderBy -  entitled[b].orderBy}).map((i,key,array)=>{
                      
                        const styled = {
                            '--background-color-cell': (item_key)%2 === 0 ? 'transparent' : 'var(--grey-very-lighter)',
                        }
                            return <div  
                                        style={styled as CSSProperties}
                                        key={key} 
                                        className={styles["cell-line"]}>
                                        <CellTable
                                            handleLink={()=>handleLink(item_key)} 
                                            name={entitled[i].type} 
                                            slug={item[i]?.slug} 
                                            url={item[i]?.url} 
                                            value={item[i]?.value}
                                            />
                                            { (entitled[i].type === 'document' && item.new) && <div className={styles["badge-new"]}>!</div>}
                                    </div>
                    })
                    
                })}
            </div>
            
        </div>
    )
}