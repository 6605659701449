import { Edit } from "./Edit";
import NotEditIcon from "./NotEditIcon";
import SendIcon from "./SendIcon";
import IdentifiantIcon from "./IdentifiantIcon";
import PasswordHideIcon from "./PasswordHideIcon";
import PasswordShowIcon from "./PasswordShowIcon";
import ThemeIcon from "./ThemeIcon";
import FullScreenIcon from "./FullScreenIcon";
import { NavbarAchat } from "./NavbarAchat";
import { NavbarCommunication } from "./NavbarCommunication";
import { NavbarSecurite } from "./NavbarSecurite";
import { NavbarCadeau } from "./NavbarCadeau";
import { NavbarPrint } from "./NavbarPrint";
import { NavbarProfile } from "./NavbarProfile";
import { NavbarArobase } from "./NavbarArobase";
import { NavbarDiplome } from "./NavbarDiplome";
import { NavbarFeuille } from "./NavbarFeuille";
import { NavbarBalance } from "./NavbarBalance";
import { NavbarAdmin } from "./NavbarAdmin";
import { NavbarActisParc } from "./NavbarActisParc";
import { NavbarLink1 } from "./NavbarLink1";
import { NavbarLink2 } from "./NavbarLink2";
import { MondialInstagram } from "./MondialInstagram";
import { MondialLinkedin } from "./MondialLinkedin";
import { MondialPhotos } from "./MondialPhotos";
import { MondialRealisations } from "./MondialRealisations";
import { MondialReseaux } from "./MondialReseaux";
import { MondialTrombinoscope } from "./MondialTrombinoscope";
import { SharePoint } from "./SharePoint";
import { Teams } from "./Teams";
import { Cse } from "./Cse";
import { N2F } from "./N2F";
import { Campus } from "./Campus";
import { Corporama } from "./Corporama";
import { Ines } from "./Ines";
import { Talents } from "./Talents";
import Ca from "./Ca";
import { Morussati } from "./Morussati";
import { Referencement } from "./Referencement";
import { VekaContact } from "./VekaContact";
import AfdfBouquets from "./AfdfBouquets";
import AfdfSiteVente from "./AfdfSiteVente";
import { MarkerIcon } from "./MarkerIcon";
import AfdfTendances from "./AfdfTendances";
import AfdfOffresFournisseurs from "./AfdfOffresFournisseurs";
import AfdfAnnuaireFournisseurs from "./AfdfAnnuaireFournisseurs";
import AfdfAnnuaireAfdf from "./AfdfAnnuaireAfdf";
import AfdfPublicationsOuvertes from "./AfdfPublicationsOuvertes";
import { Realisation } from "./Realisation";
import { Argumentaires } from "./Argumentaires";
import VekaCa from "./VekaCa";
import Boutique from "./Boutique";
import { MondialFacebook } from "./MondialFacebook";
import { MondialExtranet } from "./MondialExtranet";
import { MondialEvenements } from "./MondialEvenements";
import { MondialDocumentsTechniques } from "./MondialDocumentsTechniques";
import { MondialDocumentationMarketing } from "./MondialDocumentationMarketing";
import { MondialDeviseur } from "./MondialDeviseur";
import { MondialDeconnexion } from "./MondialDeconnexion";
import { MondialClub } from "./MondialClub";
import { MondialCirculaires } from "./MondialCirculaires";
import { MondialBoutiqueSpa } from "./MondialBoutiqueSpa";
import { MondialBoutiqueMarketing } from "./MondialBoutiqueMarketing";
import { MondialAnnuairePartenaires } from "./MondialAnnuairePartenaires";
import { MondialAnnuaire } from "./MondialAnnuaire";
import { MondialActualites } from "./MondialActualites";
import { NavbarPolls } from "./NavbarPolls";
import { NavbarTraining } from "./NavbarTraining";
import { ShoppingBag } from "./ShoppingBag";
import { NavbarDownload } from "./NavbarDownload";
import { NavbarReporting } from "./NavbarReporting";
import { NavbarPartners } from "./NavbarPartners";
import { NavbarContacts } from "./NavbarContacts";
import { NavbarCalendar } from "./NavbarCalendar";
import { NavbarPictures } from "./NavbarPictures";
import { NavbarPosts } from "./NavbarPosts";
import SearchIcon from "./SearchIcon";
import { Linkedin } from "./Linkedin";
import { Instagram } from "./Instagram";
import InstagramIcon from "./InstagramIcon";
import { LongArrowRight } from "./LongArrowRight";
import { Information } from "./Information";
import { Account } from "./Account";
import AddDocumentIcon from "./AddDocumentIcon";
import { Alert } from "./Alert";
import AlertIcon from "./AlertIcon";
import { ArrowLeft } from "./ArrowLeft";
import ArrowRight from "./ArrowRight";
import AvatarDefault from "./AvatarDefault";
import Bad from "./Bad";
import { Bar } from "./Bar";
import {Calendar} from "./Calendar";
import { Check } from "./Check";
import ChevronR from "./ChevronR";
import {ChevronRight} from "./ChevronRight";
import ChevronUp from "./ChevronUp";
import { Comment } from "./Comment";
import { Cross } from "./Cross";
import { Curve } from "./Curve";
import DateIcon from "./DateIcon";
import { Download } from "./Download";
import DownloadIcon from "./DownLoadIcon";
import DownloadImport from "./DownloadImport";
import { Email } from "./Email";
import EngrenageIcon from "./Engrenage";
import ErrorIcon from "./ErrorIcon";
import { Euro } from "./Euro";
import { Exclamation } from "./Exclamation";
import EyeIcon from "./EyeIcon";
import { Facebook } from "./Facebook";
import { Gallery } from "./Gallery";
import Globe from "./Globe";
import GlobeIcon from "./GlobeIcon";
import { Heart } from "./Heart";
import { Home } from "./Home";
import ImageAlone from "./ImageAlone";
import ImageIcon from "./ImageIcon";
import { MailAt } from "./MailAt";
import MultiImageIcon from "./MultiImageIcon";
import { Options } from "./Options";
import { Phone } from "./Phone";
import Reset from "./Reset";
import RocketIcon from "./RocketIcon";
import { Search } from "./Search";
import SmileyIcon from "./SmileyIcon";
import { Star } from "./Star";
import SuccesIcon from "./SuccesIcon";
import { Tab } from "./Tab";
import { Trash } from "./Trash";
import { TutoChrome1 } from "./TutoChrome1";
import { TutoChromeFirefox2 } from "./TutoChromeFirefox2";
import { TutoFirefox1 } from "./TutoFirefox1";
import { TutoInstalled } from "./TutoInstalled";
import { TutoInternet1 } from "./TutoInternet1";
import { TutoSafari1 } from "./TutoSafari1";
import { TutoSafari2 } from "./TutoSafari2";
import UserIcon from "./UserIcon";
import { UserPicture } from "./UserPicture";
import { WebLink } from "./WebLink";
import TvIcon from "./TvIcon";
import ProjectIcon from "./ProjectIcon";
import DocumentIcon from "./DocumentIcon";
import RssIcon from "./RssIcon";
import UserPhotoIcon from "./UserPhotoIcon";
import PieIcon from "./PieIcon";
import LocationIcon from "./LocationIcon";
import ChevronLeftIcon from "./ChevronLeftIcon";
import ChevronRightIcon from "./ChevronRightIcon";
import LikeIcon from "./LikeIcon";
import LikePLainIcon from "./LikePlainIcon";
import CommentIcon from "./CommentIcon";
import AlertNotificationIcon from "./AlertNotificationIcon";
import { ComponentPropsWithoutRef } from "react";
import ArrowIcon from "./ArrowIcon";
import MoreIcon from "./MoreIcon";
import TrashIcon from "./TrashIcon";
import EditIcon from "./EditIcon";
import ChatIcon from "./ChatIcon";
import ModerateIcon from "./ModerateIcon";
import HouseIcon from "./HouseIcon";
import NotificationIcon from "./NotificationIcon";
import AtomIcon from "./AtomIcon";
import ShopIcon from "./ShopIcon";




export const icons = {
    edit:Edit,
    notedit:NotEditIcon,
    send:SendIcon,
    identifiant:IdentifiantIcon,
    password_show:PasswordShowIcon,
    password_hide:PasswordHideIcon,
    theme:ThemeIcon,
    fullscreen:FullScreenIcon,
    achat:NavbarAchat,
    communication:NavbarCommunication,
    securite:NavbarSecurite,
    cadeau:NavbarCadeau,
    imprimante:NavbarPrint,
    profil:NavbarProfile,
    arobase:NavbarArobase,
    diplome:NavbarDiplome,
    feuille:NavbarFeuille,
    balance:NavbarBalance,
    administration:NavbarAdmin,
    "actis-parc":NavbarActisParc,
    "lien-1":NavbarLink1,
    "lien-2":NavbarLink2,
    actualites:NavbarPosts,
    life:NavbarPictures,
    agenda:NavbarCalendar,
    'annuaire-reseau':NavbarContacts,
    'annuaire-partenaires':NavbarPartners,
    'comptes-rendus':NavbarReporting,
    telechargements:NavbarDownload,
    boutique:ShoppingBag,
    "boutique-formations":NavbarTraining,
    sondages:NavbarPolls,
    "mondial-actualites":MondialActualites,
    "mondial-annuaire":MondialAnnuaire,
    "mondial-annuaire-partenaires":MondialAnnuairePartenaires,
    "mondial-boutique-marketing":MondialBoutiqueMarketing,
    "mondial-boutique-spa":MondialBoutiqueSpa,
    "mondial-circulaires":MondialCirculaires,
    "mondial-club":MondialClub,
    "mondial-deconnexion":MondialDeconnexion,
    "mondial-deviseur":MondialDeviseur,
    "mondial-documentation-marketing":MondialDocumentationMarketing,
    'mondial-documents-techniques':MondialDocumentsTechniques,
    "mondial-evenements":MondialEvenements,
    'mondial-extranet':MondialExtranet,
    "mondial-facebook":MondialFacebook,
    "mondial-instagram":MondialInstagram,
    "mondial-linkedin":MondialLinkedin,
    'mondial-photos':MondialPhotos,
    'mondial-realisations':MondialRealisations,
    'mondial-reseaux':MondialReseaux,
    'mondial-trombinoscope':MondialTrombinoscope,
    'SharePoint':SharePoint,
    'Teams':Teams,
     'Cse':Cse,
     'N2F':N2F,
     'Campus':Campus,
     'Corporama':Corporama,
     'Ines':Ines,
     'Talents':Talents,
     'boutique-cahra':Boutique,
     'VekaChiffre':VekaCa,
     ca:Ca,
     'VekaArgumentaires':Argumentaires,
     'VekaRéalisations':Realisation,
     'VekaMorassuti':Morussati,
     'VekaRéférencement':Referencement,
     'Vekacontact':VekaContact,
     'AfdfPublicationsOuvertes':AfdfPublicationsOuvertes,
     'AfdfAnnuaireAfdf':AfdfAnnuaireAfdf,
     'AfdfAnnuaireFournisseurs':AfdfAnnuaireFournisseurs,
     'AfdfBouquets':AfdfBouquets,
     'AfdfOffresFournisseurs':AfdfOffresFournisseurs,
     'AfdfSiteVente':AfdfSiteVente,
     'AfdfTendances':AfdfTendances,
     'map':MarkerIcon,
     'location':LocationIcon,
      //
     search:SearchIcon,
     linkedin:Linkedin,
     "instagram-solid":Instagram,
     "instagram-line":InstagramIcon,
     "long-arrow-right":LongArrowRight,
     "arrow-left":ArrowLeft,
     "arrow-right":ArrowRight,
     information:Information,
     account:Account,
     "add-document":AddDocumentIcon,
     notification:Alert,
     "alert-2":AlertIcon,
     "avatar-default":AvatarDefault,
     bad:Bad,
     bar:Bar,
     calendar:Calendar,
     campus:Campus,
     check:Check,
     'chevronleft':ChevronLeftIcon,
     'chevronright':ChevronRightIcon,
     'chevron-right':ChevronR,
     "chevron-right-2":ChevronRight,
     "chevron-up":ChevronUp,
     comment:Comment,
     cross:Cross,
     curve:Curve,
     date:DateIcon,
     download:Download,
     'download-2':DownloadIcon,
     "download-import":DownloadImport,
     email:Email,
     engrenage:EngrenageIcon,
     error:ErrorIcon,
     euro:Euro,
     exclamation:Exclamation,
     eye:EyeIcon,
     facebook:Facebook,
     gallery:Gallery,
     globe:Globe,
     "globe-2":GlobeIcon,
     heart:Heart,
     home:Home,
     "image-alone":ImageAlone,
     image:ImageIcon,
     "image-multi":MultiImageIcon,
     'mail-at':MailAt,
     option:Options,
     phone:Phone,
     reset:Reset,
     rocket:RocketIcon,
     "search-2":Search,
     smiley:SmileyIcon,
     start:Star,
     success:SuccesIcon,
     tab:Tab,
     trash:TrashIcon,
     "tuto-chrome":TutoChrome1,
     "tuto-chrome-firefox":TutoChromeFirefox2,
     "tuto-firefox":TutoFirefox1,
     "tuto-installed":TutoInstalled,
     "tuto-internet":TutoInternet1,
     "tuto-safari-1":TutoSafari1,
     "tuto-safari-2":TutoSafari2,
     user:UserIcon,
     "user-picture":UserPicture,
     "web-link":WebLink,
     "tv":TvIcon,
     'project':ProjectIcon,
     'document':DocumentIcon,
     rss:RssIcon,
     'user-photo':UserPhotoIcon,
     pie:PieIcon,
     arrow:ArrowIcon,
     more:MoreIcon,
    'new-edit':EditIcon,
    instagram:InstagramIcon,
     like:LikeIcon,
     'like-plain':LikePLainIcon,
     comments:CommentIcon,
     'alert-notification':AlertNotificationIcon,
     chat:ChatIcon,
     notif:NotificationIcon,
     house:HouseIcon,
     atom:AtomIcon,
     shop:ShopIcon,
     moderate:ModerateIcon,
 
    }

type IconProps = {
    name:keyof typeof icons
} & ComponentPropsWithoutRef<any>

export default function Icon({name,...props}:IconProps):JSX.Element | null{
    let Component = icons[name];

    if(Component!=null) return <Component {...props}/>
    return null
}
