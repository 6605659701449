// export const ADMIN_SET_POST = "ADMIN_SET_POST";
export const PGL_PROFILE = 'PGL_PROFILE'
export const PGL_LEADS = 'PGL_LEADS'
export const PGL_LEADS_LOADING = 'PGL_LEADS_LOADING'
export const PGL_COMPANIES = 'PGL_COMPANIES'
export const PGL_SELLERS = 'PGL_SELLERS'
export const PGL_POPIN = 'PGL_POPIN'
export const PGL_CALLBACK = 'PGL_CALLBACK'
export const PGL_CALL = 'PGL_CALL'
export const PGL_EMAIL = 'PGL_EMAIL'
export const PGL_RESULT_MAIL ="PGL_RESULT_MAIL"
export const PGL_SORT_LEADS = 'PGL_SORT_LEADS'
export const PGL_LEADS_COLUMNS = 'PGL_LEADS_COLUMNS'
export const PGL_CHANGE_LEADS_CONTEXT = 'PGL_CHANGE_LEADS_CONTEXT'
export const PGL_LEADS_COUNT = 'PGL_LEADS_COUNT'
export const PGL_LEADS_FILTERS = 'PGL_LEADS_FILTERS'
export const PGL_LEADS_FILTERS_FIELD = 'PGL_LEADS_FILTERS_FIELD'
export const PGL_LEADS_FILTERS_FIELD_RESET = 'PGL_LEADS_FILTERS_FIELD_RESET'
export const PGL_LEADS_EXPORT = 'PGL_LEADS_EXPORT'
export const PGL_LEADS_EXPORT_RESET = 'PGL_LEADS_EXPORT_RESET'
export const PGL_LEADS_LEAD_GET = 'PGL_LEADS_LEAD_GET'
export const PGL_LEADS_LEAD_SET = 'PGL_LEADS_LEAD_SET'
export const PGL_LEADS_LEAD_NEW = 'PGL_LEADS_LEAD_NEW'
export const PGL_LEADS_LEAD_PRINT = 'PGL_LEADS_LEAD_PRINT'
export const PGL_LEADS_LEAD_QUALIF = 'PGL_LEADS_LEAD_QUALIF'
export const PGL_LEADS_LEAD_HISTORY = 'PGL_LEADS_LEAD_HISTORY'
export const PGL_LEADS_LEAD_ALERT = 'PGL_LEADS_LEAD_ALERT'
export const PGL_LEADS_LEAD_RESET = 'PGL_LEADS_LEAD_RESET'
export const PGL_POPIN_RESET = 'PGL_POPIN_RESET'
export const PGL_LEADS_LEAD_QUALIF_RESET = 'PGL_LEADS_LEAD_QUALIF_RESET'
export const PGL_CALLBACK_RESET = 'PGL_CALLBACK_RESET'
export const PGL_SET_CITY = "PGL_SET_CITY"
export const PGL_SET_CP = "PGL_SET_CP"
export const PGL_POPIN_CLEAN ="PGL_POPIN_CLEAN"
export const PGL_SET_STATUS = 'PGL_SET_STATUS'
