import styles from './Layout.module.scss'
import { useSelector,shallowEqual } from 'react-redux'
import { memo, FC } from 'react'

// HOOKS
import { useNavbarPgl, NavbarPglProvider } from '../../../hooks/useNavbarPgl'

// TYPES
import { IRootState } from '../../../types/state'

// COMPONENTS
import DraggableFilters from './DraggableFilters'
import Navbar from './Navbar'
import ButtonText from '../../ui/buttons/ButtonText'
import {useHistory} from 'react-router-dom';
interface ILayout {
  children: JSX.Element[] | JSX.Element
}

const Layout: FC<ILayout> = ({ children }) => {
  const deviceInfos = useSelector((state: IRootState) => state.deviceInfos)
  // const withoutNavbar = deviceInfos === 'smartphone' ? true : false
  const { withoutNavbar } = useNavbarPgl()
  const pgl_profile = useSelector((state: IRootState) => state.pgl_profile, shallowEqual)
  const history = useHistory();


  function onBack(){
    if(history.action === "POP"){
      history.replace({pathname:'/'})
    }
    else{
      history.goBack()
    }
  
   // history.goBack();
  } 


  return (
    <NavbarPglProvider>
      <div className={styles['layout']}>
        <Navbar />
        {pgl_profile.role===null ?
        <div className={styles['not-access']}>
          
          <div>Vous n'avais pas les droits pour accéder à cette rubrique</div>
          <ButtonText onAction={onBack} text="Retour" typeColor="primary"/>
        
        </div>
        :
        <section className={!withoutNavbar ? styles['content'] : styles['nonavbar']}>{children}</section>
        }
        <div className={!withoutNavbar ? styles[''] : styles['nonavbar']}>
 
        </div>

        {/* {!withoutNavbar ? deviceInfos === 'smartphone' && <DraggableFilters /> : ''} */}
        {/* {deviceInfos === 'smartphone' && withoutNavbar === false ? <DraggableFilters /> : ''} */}
      </div>
    </NavbarPglProvider>
  )
}

export default memo(Layout)
