import * as React from "react"

const AfdfTendances = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15.4 15.4"
    style={{
      enableBackground: "new 0 0 15.4 15.4",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M13.7 6v7.7h-12v-12h7.7V0H1.7C.8 0 0 .8 0 1.7v12c0 .9.8 1.7 1.7 1.7h12c.9 0 1.7-.8 1.7-1.7V6h-1.7zM12 6l.8-1.8 1.8-.8-1.8-.8L12 .9l-.8 1.8-1.8.7 1.8.8L12 6zm-3.2.6L7.7 4.3 6.6 6.6 4.3 7.7l2.4 1.1 1.1 2.4 1.1-2.4 2.4-1.1-2.5-1.1z" />
  </svg>
)

export default AfdfTendances
