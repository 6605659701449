import * as React from "react"

const GlobeIcon = ({fill,width,height}) => (
  <svg
    height={height}
    width={width}
    xmlSpace="preserve"
    viewBox="0 0 32 32"
  
  >
    <path
      d="M16.499 9.162c-2.735 0-5.469-.315-8.123-.937a.5.5 0 1 1 .229-.974 34.824 34.824 0 0 0 17.678-.498.499.499 0 1 1 .283.959 35.663 35.663 0 0 1-10.067 1.45zM26.424 26.362a.504.504 0 0 1-.142-.021 34.804 34.804 0 0 0-19.566 0 .5.5 0 0 1-.283-.959 35.798 35.798 0 0 1 20.133 0 .5.5 0 0 1-.142.98z"
      fill={fill}
    />
    <path
      d="M15.888 30.639a.498.498 0 0 1-.282-.087c-4.667-3.185-7.453-8.422-7.453-14.009 0-4.527 1.781-8.788 5.017-11.998a.5.5 0 0 1 .705.709c-3.045 3.021-4.722 7.03-4.722 11.289 0 5.256 2.623 10.185 7.018 13.183a.5.5 0 0 1-.283.913zM17.153 30.639a.5.5 0 0 1-.282-.913c4.395-2.998 7.018-7.927 7.018-13.183S21.266 6.358 16.871 3.36a.5.5 0 0 1 .564-.826c4.667 3.185 7.453 8.422 7.453 14.009s-2.786 10.824-7.453 14.009a.503.503 0 0 1-.282.087z"
      fill={fill}
    />
    <path
      d="M16.499 30.49a.5.5 0 0 1-.5-.5V3.096a.5.5 0 0 1 1 0V29.99a.5.5 0 0 1-.5.5z"
      fill={fill}
    />
    <path
      d="M30.446 17.043H4.552a.5.5 0 0 1 0-1h25.895a.5.5 0 1 1-.001 1z"
      fill={fill}
    />
    <path
      d="M16.499 31.043c-7.995 0-14.5-6.505-14.5-14.5 0-3.521 1.278-6.919 3.6-9.565a.5.5 0 1 1 .752.66 13.498 13.498 0 0 0-3.352 8.905c0 7.444 6.056 13.5 13.5 13.5s13.5-6.056 13.5-13.5-6.056-13.5-13.5-13.5A13.348 13.348 0 0 0 8.12 5.958a.499.499 0 1 1-.621-.783 14.333 14.333 0 0 1 9-3.132c7.995 0 14.5 6.505 14.5 14.5s-6.505 14.5-14.5 14.5z"
      fill={fill}
    />
  </svg>
)

export default GlobeIcon
