import thunk from 'redux-thunk'

import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import { createStore, applyMiddleware } from 'redux'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import reducers from './reducers'

const cacheAnnuaire = process.env.REACT_APP_CACHE_ANNUAIRE == 'true' ? ["contacts",'partners'] : []; 

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'linkedin',...cacheAnnuaire],
}

const pReducer = persistReducer(persistConfig, reducers)
const middleware = composeWithDevTools(applyMiddleware(thunk))
export const store = createStore(pReducer, middleware)
export const persistor = persistStore(store)


export  function errorRequest(error,rejectWithValue){
  if(!error.response){
    throw error
}else if(error.statut == 404){
    return rejectWithValue(error.response.statutText)
}
return rejectWithValue(error.response.data)
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;