// import {
//   CHECK_AUTH,
//   SIGN_IN,
//   GET_TEMPORARY_CODE,
//   SEND_MAIL,
//   SEND_EMAIL_TO_ADMIN,
//   GET_NAVBAR,
//   GET_POSTS,
//   GET_CONTACTS,
//   GET_PARTNERS,
//   GET_ADMINS,
//   GET_PROFILE,
//   SET_PROFILE,
//   SET_PROFILE_PICTURE,
//   SET_NEW_PASSWORD,
//   GET_POST,
//   SET_LIKE,
//   SET_COMMENT,
//   GET_PDF,
//   ADMIN_SET_POST,
//   ADMIN_DELETE_POST,
//   ADMIN_GET_GROUPS,
//   ADMIN_GET_CATEGORIES,
//   ADMIN_GET_POSTS,
//   ADMIN_GET_POST_FOR_UPDATE
// } from "reduxactions/pci/types";
import {
  GET_EVENTS,
  ACCEPT_EVENT_PARTICIPATION,
  REFUSE_EVENT_PARTICIPATION,
  ADMIN_SET_POST,
  ADMIN_DELETE_POST,
  DELETE_EVENTS_NOTIFICATION,
} from '../../actions/pci/types'
import update from 'immutability-helper'

const INITIAL_STATE = {
  data: [],
  r: '',
  error: false,
  breadcrumb: false,
}

const calendarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADMIN_DELETE_POST:
      if (action.rubric_id === 'agenda') {
        return update(state, {
          data: {
            [action.post_index]: {
              $set: false,
            },
          },
        })
      } else {
        return state
      }
    case GET_EVENTS:
      return update(state, {
        r: { $set: action.payload.r },
        data: {
          $set: action.payload.data.posts,
        },
        breadcrumb: {
          $set: action.payload.data.breadcrumb,
        },
        error: {
          $set: action.payload.data.error || false,
        },
      })
    case ACCEPT_EVENT_PARTICIPATION:
    case REFUSE_EVENT_PARTICIPATION:
      const event_to_update = state.data.findIndex((event) => event.id === action.event_id)
      return update(state, {
        data: {
          [event_to_update]: { participation: { $set: action.payload.value } },
        },
      })
    case ADMIN_SET_POST:
      if (action.rubric_id === 'agenda') {
        return {
          data: [],
          r: '',
        }
      } else {
        return state
      }
    case DELETE_EVENTS_NOTIFICATION:
      const old_events = state.data
      let events = old_events
      if (!events) {
        return state
      } else {
        events.forEach((event) => {
          if (event.new) {
            event.new = false
          }
        })
        return update(state, {
          data: {
            $set: events,
          },
        })
      }
    default:
      return state
  }
}

export default calendarReducer
