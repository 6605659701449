// @ts-nocheck

import { useFetchProtected } from '../useFetchProtected';
import styles from './ProtectedPage.module.scss';
import {useHistory} from 'react-router-dom';
import parse from 'html-react-parser';
import { Home } from '../../../icons/Home';

export default function ProtectedPage({type}){
    const {data,loading,error} = useFetchProtected(type)
    const history = useHistory();

    if(loading){
        return null
    }
    else if(error){
        return <div>{error}</div>
    }
    else if(typeof(data)==='object'){
    return(
        <div className={styles["protected-page"]}>
         
            <button onClick={()=>history.replace('/')} className={styles["close-block-protected"]}><Home/></button>
            <div className={styles["page-protected-wrapper"]}>
                <h1 className={styles["title"]}>{data.title}</h1>
                <div className={styles["contentContainer"]}>{parse(data?.content)}</div>
                </div>
          
        </div>
    )}
    else return null
}


