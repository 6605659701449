import Icon from '../../../../icons'
import styles from './PostCard.module.scss'
import { Link, useHistory } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/hooks'

type PostCardProps = {
  title: string
  auteur: string
  entreprise: string
  date: string
  img: string
  notif: boolean
  likes: { count: number; me: boolean }
  comments: { hold: number; approved: number }
  status: string
  slug: string
  id: string | number
  rub: { id: number; slug: string; src: string }
  onLike: (val: string | number) => void
  currentLocation: { type: 'post'; parentCat: string; childCat: string }
}

export default function PostCard({
  title,
  auteur,
  entreprise,
  date,
  img,
  notif,
  status,
  likes,
  comments,
  slug,
  id,
  rub,
  onLike,
  currentLocation,
}: PostCardProps) {
  const profil = useAppSelector((state) => state.auth.authentication.profil)
  const isModerateur = profil !== 'adherent'

  const history = useHistory()
  function goTo() {
    history.push({
      pathname: `/actualite/actualites/${id}`,
      state: {
        lastContent: currentLocation,
      },
    })
  }

  return (
    <div onClick={goTo} className={styles['post-card-wrapper']}>
      {notif ? (
        <div className={styles['post-notif']}>
          <Icon name="alert-notification" />
        </div>
      ) : null}
      <div className={styles['post-card-container']}>
        <div className={styles['post-header']}>
          <div className={styles['post-title']}>{title}</div>
          <div className={styles['more-option']}></div>
        </div>
        <div className={styles['post-auhtor']}>
          <span>{auteur ? `Publié par ${auteur}` : ''}</span>
          <span>{entreprise ? ` (${entreprise})` : ''}</span>
        </div>
        <div className={styles['post-date']}>{date ? `Le ${date}` : ''}</div>
        <div className={styles['post-actions']}>
          <div className={styles['post-action']}>
            {likes && (
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  onLike(id)
                }}
                className={styles['post-like']}
              >
                <Icon name={likes?.me ? 'like-plain' : 'like'} />
                <span>{likes?.count ?? '0'}</span>
              </div>
            )}
            {likes && comments && <span>|</span>}
            {comments && (
              <div className={styles['post-comment']}>
                <Icon name="comments" />
                <span>{comments?.approved ?? '0'}</span>
              </div>
            )}
          </div>
          <div className={styles['post-arrow-container']}>
            <Icon name="chevronright" />
          </div>
        </div>
        {img && (
          <div className={styles['post-image-container']}>
            <img loading="lazy" src={img} />
          </div>
        )}
        {status !== 'publish' ? <div className={styles['post-moderate']}>Article à modérer</div> : null}
      </div>
    </div>
  )
}
