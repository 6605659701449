import {
  GET_SHOPPING_POSTS,
  GET_SHOPPING_POST,
  RESET_SHOPPING_POST,
  GET_SHOPPING_CART,
  RESET_SHOPPING_CART,
  GET_SHOPPING_TYPOLOGIES,
  UPDATE_CART,
  SET_SHOPPING_PROMOCODE,
  VALIDATE_SHOPPING_ORDER,
  RESET_SHOPPING_ORDER,
  SET_SHOPPING_TYPOLOGIE,
} from '../../actions/pci/types'
import update from 'immutability-helper'

const INITIAL_STATE = {
  posts: {
    page: 1,
    data: [],
    last: false,
    r: '',
    typologie: '',
  },
  post: false,
  cart: [],
  typologies: [],
  update: false,
  promocode: false,
  order: false,
  typologie: false,
  breadcrumb: false,
}

const shoppingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SHOPPING_TYPOLOGIE:
      return {
        ...state,
        typologie: action.typologie,
      }
    case GET_SHOPPING_POSTS:
    
      // return {
      //   ...state,
      //   posts: action.payload,
      // };
      let items
      if (
        state.posts.r === action.payload.r &&
        state.posts.page !== action.payload.page &&
        state.posts.typologie === action.payload.typologie
      ) {
        // console.log("add");
        items = state.posts.data
        if (action.payload.data.articles) {
          action.payload.data.articles.forEach((element) => {
            items.push(element)
          })
        }
      } else {
        // console.log("init");
        items = action.payload.data.articles
      }
      return update(state, {
        posts: {
          page: { $set: action.payload.page },
          r: { $set: action.payload.r },
          typologie: { $set: action.payload.typologie },
          breadcrumb: { $set: action.payload.data.breadcrumb },
          last: {
            $set: action.payload.data.last ? action.payload.data.last : false,
          },
          data: {
            $set: items,
          },
        },
      })
    case SET_SHOPPING_PROMOCODE:
      return {
        ...state,
        promocode: action.payload,
      }
    case UPDATE_CART:
      return {
        ...state,
        update: action.payload.success,
      }
    case GET_SHOPPING_POST:
      return {
        ...state,
        post: action.payload.article,
        post_breadcrumb: action.payload.breadcrumb,
      }
    case GET_SHOPPING_TYPOLOGIES:
      return {
        ...state,
        typologies: action.payload.typologies,
      }
    case VALIDATE_SHOPPING_ORDER:
      return {
        ...state,
        order: action.payload,
      }
    case RESET_SHOPPING_ORDER:
      return {
        ...state,
        order: false,
      }
    case GET_SHOPPING_CART:
      return {
        ...state,
        cart: action.payload,
        update: false,
      }
    case RESET_SHOPPING_POST:
      return {
        ...state,
        post: false,
      }
    case RESET_SHOPPING_CART:
      return {
        ...state,
        cart: [],
      }
    default:
      return state
  }
}

export default shoppingReducer
