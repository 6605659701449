import LayoutApp from "./layout-app";
import LayoutAuth from "./layout-auth";
import ClassicLayout from "./classic-layout";
import NotFetchLayout from "./not-fetch-layout";

const Components = {
    app:LayoutApp,
    auth:LayoutAuth,
    classic:ClassicLayout,
    nofetch:NotFetchLayout
}

type LayoutProps ={
    type:string
}

export default function Layout({type,...props}){
    let Component = Components[type];

    if(Component!==undefined){
        return <Component {...props} />
    }
    return null
}