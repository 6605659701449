//@ts-ignore

import { AxiosResponse } from "axios";
import { useEffect,useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import ErrorComponent from "../../components/error-component";
import api from "../../config/pci/api";
import { useAuth } from "../../context/AuthContext";
import LoadingPage from "../../pages/loading-page";
import { getNavbarSocials,getNavbarNotifications } from "../../redux/actions/pci";
import { useAppSelector } from "../../redux/hooks";

export default function LayoutApp({children,...props}){

  
    const dispatch = useDispatch();
      //@ts-ignore next-line
    const {id,nonce} = useSelector(state=>state.auth.authentication);
    const [loadingApp,setLoadingApp] = useState<boolean>(true);
    const {lastconnect} = useAppSelector(state=>state.session);
    const [error,setError] = useState<boolean>(false);
    const {authorized,loading} = useAuth();
    //@ts-ignore next-line
    const menu = useSelector(state=>state.navbar);


   async function fetchMenu(){
    try{ const responseMenu = await api.get <AxiosResponse<unknown, any>>('/callmenu', {
            params: {
            user: id,
            nonce: nonce,
            },
            })
            if(responseMenu.data.hasOwnProperty('error')){
                //@ts-ignore
                setError({text:'',status:403})
               return setLoadingApp(false)
               
            }
            //@ts-ignore next-line
            else if(responseMenu.data.length >0 && responseMenu.data?.filter(i=> i.home == 'home_1').length === 0){
                //@ts-ignore
                setError({text:'',status:403})
                return setLoadingApp(false)
                 
            }
            else{
                dispatch({ type: 'GET_NAVBAR', payload: responseMenu.data });
                dispatch(getNavbarNotifications(id,nonce,lastconnect))
                dispatch(getNavbarSocials(id,nonce))
                setLoadingApp(false)
        
        }
    }catch(error){
        setLoadingApp(false)
        //@ts-ignore
        setError({text:'',status:error.response.status})
}
    }

    useEffect(()=>{
        if(authorized)fetchMenu()
        else setLoadingApp(false)
        return () => setError(false)  
    },[authorized])
  

    if(loadingApp) return <LoadingPage type={"app"}/>
    else if(error) return <ErrorComponent error={error}  name='app'/>
    else if(menu?.items.length > 0) return children
    return null
}