import ContainerVideo from "./container-video"
import ContainerPicture from "./container-picture"
import ContainerCaroussel from "./container-caroussel"
import ContainerWithoutMedia from "./container-without-media";
import {forwardRef} from 'react';


const Components ={
    video:ContainerVideo,
    picture:ContainerPicture,
    caroussel:ContainerCaroussel,
    text:ContainerWithoutMedia
} 


type ContainerMediaProps = {
    type:string,
    medias:Array<string>
} 

function ContainerMedia({type,...props}:ContainerMediaProps,ref):JSX.Element | null{
    const Component = Components[type];

    if(Component!==undefined){
        return <Component ref={ref} type={type}  {...props}/>
    }
    return null


}

export default forwardRef(ContainerMedia)







