import Icon from '../../../icons';
import styles from './ButtonSocial.module.scss';
import { hexToCSSFilter } from 'hex-to-css-filter';
import {CSSProperties, useState} from 'react';
import { colourNameToHex,colours } from '../../../utils/colorNameToHex';
import { useSpring,animated } from 'react-spring';

type ButtonSocial = {
    onClick:()=>void,
    color:string,
    name?:string,
    text?:string,
    type:string,
}

export default function ButtonSocial({onClick,color="green",name,text,type}:ButtonSocial){


    const [hover,setHover] = useState(false)
    const colorReWrite = 
        (color.startsWith('var(--')|| color.startsWith('--')) 
        ? getComputedStyle(document.documentElement).getPropertyValue(color.startsWith('var(--') ? color.substring(4).slice(0, -1) : color) 
        : color.startsWith('#') ? color 
        : colours.hasOwnProperty(color) ? colours[color] : '#FFFFFF'  ;


    const colorisation = hexToCSSFilter(colorReWrite).rgb;

    const {x} = useSpring({
        x: hover ? 0 : 1
    })


    const styled = {
    '--background-button' :  x.to([0,1],[type==='plain'?0.8:0.2,type==='plain'?1.0:0.0])
                            .to(value=>`rgba(${colorisation.join(',')},${value})`),
    '--border-button':`1px solid ${type === 'line' ?color : 'transparent'}`,
    '--text-button':type === 'plain' ? 'var(--ui-text-primary-color)': color

    } as CSSProperties


    return(
        <animated.button style={styled} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} onClick={onClick} className={styles["button-social"]}>
            <Icon name={name} />
            <span>{text}</span>
        </animated.button>
    )
}