import { createGlobalStyle } from 'styled-components'

import config from '../config'

const GlobalStyle = createGlobalStyle`
  // NEW SYSTEM
  :root {
    //Primary
    --theme-color-primary: #002e5a;
    --theme-color-secondary: #00b1eb;
    --theme-color-important:#AE2527;
    //Lighter
    --theme-color-primary-lighter: #00b1eb;
    --theme-color-secondary-lighter: #04a8de;
    --theme-color-important-lighter: #F15050;



    
    --theme-color-primary-muted: #002f5a40;
    --theme-color-secondary-muted: #00b1eb40;

    //gradient color
 
    --linear-gradient-primary: linear-gradient(to top, #002e5a 10%, #004f9f 100%);
    --linear-gradient-secondary: linear-gradient(to top,#004f9f,#4488D4);
    --linear-gradient-important: linear-gradient(to top,#AE2527,#F15050);
    --linear-gradient-info: linear-gradient(to bottom,#f7b733,#fc4a1a);
 
    
    //lighter gradient color
    --linear-gradient-primary-lighter: linear-gradient(to top, #004f9f 0%, #00b1eb 100%);
    --linear-gradient-secondary-lighter: linear-gradient(to top,#4488D4,#04a8de);
    --linear-gradient-important-lighter: linear-gradient(to top,#ea8c8c,#F15050);
    

    // OLD ONES, TO REMOVE

    // Navbar
    --navbar-top: ${config.colors.navbar.top};
    --navbar-top: ${config.colors.navbar.top};
    --navbar-font-color: ${config.colors.navbar.font_color};
    --navbar-bottom: ${config.colors.navbar.bottom};
    --navbar-top-admin: ${config.colors.navbar.top_admin};
    --navbar-bottom-admin: ${config.colors.navbar.bottom_admin};
    --navbar-top-burger-active: ${config.colors.navbar.top_burger_active};
    --navbar-bottom-burger-active: ${config.colors.navbar.bottom_burger_active};
    --navbar-social-top: ${config.colors.navbar.social_top};
    --navbar-social-bottom: ${config.colors.navbar.social_bottom};
    /* --navbar-bottom-admin-tab: */
    
    // Modale 
    --alert-fond: ${config.colors.alert.bg_color};

    // Searchbar  
    --searchbar-color: ${config.colors.searchbar.color};
    --content-bg-color: ${config.colors.content.bg_color};

    // tuto
    --tuto-color:    ${config.colors.tuto.color};
    --tuto-icons:    ${config.colors.tuto.icons};

    // graph_remplissage
    --graph-remplissage-actif:    ${config.colors.graph_remplissage.actif};
    --graph-remplissage-inactif:    ${config.colors.graph_remplissage.inactif};




    /* ANNUAIRE  */
    //MENU
    --button-linear-gradient-primary :  ${config.colors.button.gradient.primary};
    // PRIMARY
    --annuaire-table-header:${config.colors.annuaire.tabsheader};
    // SECONDARY 
    --annuaire-table-link:${config.colors.annuaire.linkheader};
    --annuaire-favorite:${config.colors.annuaire.favorite};
    --entreprise-circle:${config.colors.entreprise.circle};

    //ENTREPRISE
    --entreprise-nom:${config.colors.entreprise.nom};
    --entreprise-logo-circle-1:${config.colors.entreprise.logo.circleLogoOne};
    --entreprise-logo-circle-2:${config.colors.entreprise.logo.circleLogoTwo};
    --entreprise-jeton-url:${config.colors.entreprise.jetonUrl};
    --entreprise-jeton-email:${config.colors.entreprise.jetonEmail};
    --entreprise-link-file:${config.colors.entreprise.linkFile};

    //PARTNER
    --partner-circle-partner:${config.colors.partner.circle};
    --partenaire-logo-circle-1:${config.colors.partner.logo.circleLogoOne};

    //USER
    --user-entreprise:${config.colors.user.entreprise};
  }

  iframe{
    pointer-events:${process.env.NODE_ENV === 'production' ? 'auto' : 'none'}
  }
  

`

export default GlobalStyle


/*
  REFLEXION_COLOR FRED DONT KILL THAT

    //Primary
    --theme-color-primary: ${config.colors.primary.main??'#002e5a'};
    --theme-color-secondary: ${config.colors.secondary.main ?? '#00b1eb'};
    --theme-color-important:${config.colors.important.main ?? '#AE2527'};
    //Lighter
    --theme-color-primary-lighter:${config.colors.primary.lighter?? '#00b1eb'};
    --theme-color-secondary-lighter:${config.colors.secondary.lighter??'#04a8de'};
    --theme-color-important-lighter: ${config.important.lighter ?? '#F15050'};

    //muted
    
    --theme-color-primary-muted: ${config.colors.primary.muted??'#002f5a40'};
    --theme-color-secondary-muted: ${config.colors.secondary.muted??'#00b1eb40'};

    //gradient color
 
    --linear-gradient-primary: ${config.colors.primary.gradient?? 'linear-gradient(to top, #002e5a 10%, #004f9f 100%)'};
    --linear-gradient-secondary: ${config.colors.secondary.gradient ?? 'linear-gradient(to top,#004f9f,#4488D4)'};
    --linear-gradient-important: ${config.colors.important.gradient ??  'linear-gradient(to top,#AE2527,#F15050)'};
 
    
    //lighter gradient color
    --linear-gradient-primary-lighter:${config.colors.primary.gradientLighter ?? 'linear-gradient(to top, #004f9f 0%, #00b1eb 100%)'};
    --linear-gradient-secondary-lighter: ${config.colors.secondary.gradientLighter ??'linear-gradient(to top,#4488D4,#04a8de)'};
    --linear-gradient-important-lighter: ${config.colors.important.gradientLighter ?? 'linear-gradient(to top,#ea8c8c,#F15050)'};
    */