import {
  ADMIN_GET_CATEGORIES,
  ADMIN_GET_GROUPS,
  ADMIN_SET_POST,
  ADMIN_GET_POST_FOR_UPDATE,
  ADMIN_GET_SOCIAL_TAGS,
  ADMIN_GET_SOCIAL_THEMES,
  ADMIN_RESET_SOCIAL_TAGS,
  ADMIN_RESET_SOCIAL_THEMES,
  ADMIN_RESET_POST_FOR_UPDATE,
} from '../../actions/pci/types'

const INITIAL_STATE = {
  categories: [],
  groups: [],
  social_tags: [],
  social_themes: [],
  message: false,
  editpost: false,
}

const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADMIN_GET_SOCIAL_TAGS:
      return {
        ...state,
        social_tags: action.payload,
      }
    case ADMIN_RESET_SOCIAL_TAGS:
      return {
        ...state,
        social_tags: [],
      }
    case ADMIN_RESET_SOCIAL_THEMES:
      return {
        ...state,
        social_themes: [],
      }
    case ADMIN_GET_SOCIAL_THEMES:
      return {
        ...state,
        social_themes: action.payload,
      }
    case ADMIN_GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      }
    case ADMIN_GET_GROUPS:
      return {
        ...state,
        groups: action.payload,
      }
    case ADMIN_SET_POST:
      return {
        ...state,
        message: action.payload,
      }
    case ADMIN_GET_POST_FOR_UPDATE:
      return {
        ...state,
        editpost: action.payload,
      }
    case ADMIN_RESET_POST_FOR_UPDATE:
      return {
        ...state,
        editpost: false,
      }
    default:
      return state
  }
}
export default adminReducer
