const NotEditIcon = (props) => (
  <svg
    fill="none"
    height={props?.height}
    width={props?.width}
    viewBox={'0 0 24 24'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.94 10 2.22 3.28a.75.75 0 1 1 1.06-1.06l18.5 18.5a.75.75 0 0 1-1.06 1.06L14 15.062l-4.938 4.938a2.25 2.25 0 0 1-.999.58l-5.116 1.395a.75.75 0 0 1-.92-.921l1.395-5.116a2.25 2.25 0 0 1 .58-.999L8.938 10Zm4 4L10 11.061l-4.938 4.938a.75.75 0 0 0-.193.333l-1.05 3.85 3.85-1.05A.75.75 0 0 0 8 18.938L12.94 14ZM17.94 9l-2.88 2.88 1.06 1.06 4.91-4.91a3.578 3.578 0 1 0-5.06-5.06l-4.91 4.91 1.06 1.06L15 6.06 17.94 9Zm-.91-4.97a2.079 2.079 0 0 1 2.94 2.94l-.97.97L16.06 5l.97-.97Z"
      fill={props?.fill}
    />
  </svg>
)

export default NotEditIcon