import React from 'react';

export const Teams = (props) => {
  return (
<svg version="1.1" id="Calque_1" x="0px" y="0px"
	 viewBox="0 0 18.2 11.6" >
<path id="Icon_material-people-outline" d="M12.8,6.6c-1.3,0-2.5,0.3-3.7,0.8C7.9,6.9,6.7,6.6,5.4,6.6C3.6,6.6,0,7.5,0,9.3v2.3h18.2
	V9.3C18.2,7.5,14.6,6.6,12.8,6.6z M9.5,10.3H1.2v-1c0-0.4,2.1-1.4,4.1-1.4s4.1,1,4.1,1.4L9.5,10.3z M17,10.3h-6.2v-1
	c0-0.4-0.2-0.7-0.4-1C11.1,8,12,7.9,12.8,7.9c2,0,4.1,1,4.1,1.4L17,10.3z M5.4,5.8c1.6,0,2.9-1.3,2.9-2.9S7,0,5.4,0S2.5,1.3,2.5,2.9
	S3.8,5.8,5.4,5.8L5.4,5.8z M5.4,1.2C6.3,1.2,7,2,7,2.9c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.6C3.7,2,4.5,1.2,5.4,1.2z
	 M12.8,5.8c1.6,0,2.9-1.3,2.9-2.9c0-1.6-1.3-2.9-2.9-2.9S9.9,1.3,9.9,2.9l0,0C9.9,4.5,11.2,5.8,12.8,5.8C12.8,5.8,12.8,5.8,12.8,5.8
	L12.8,5.8z M12.8,1.2c0.9,0,1.7,0.7,1.7,1.7s-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.6C11.2,2,11.9,1.2,12.8,1.2L12.8,1.2z"/>
</svg>

  );
};
