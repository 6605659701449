import styles from './Input.module.scss';
import React,{ forwardRef } from 'react';


const InputWhite= React.forwardRef((props,ref)=>{

    return (
            <div style={props.stylesContainer} className={styles.container}>
                <label style={props.stylesLabel}  className={styles.label}>{props.label}</label>
                <input onFocus={props.onFocus} ref={ref}  required={props.required} style={props.stylesInput} onChange={props.onChange} className={styles["input-white"]} placeholder={props.placeholder} type={props.type}  value={props.value}/>
                {props.icon && <div style={props.stylesIcon} className={styles.icons}>{props.icon}</div> }
            </div>
    )       
});

export default InputWhite



InputWhite.defaultProps = {
    placeholder: "Remplisser le champs",
    value: "",
    type:"text",
    step:false,
    label:"",
    icon:false
  };


