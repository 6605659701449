import { ChangeEventHandler,CSSProperties,useState } from 'react';
import { useSpring,animated } from 'react-spring';
import styles from './InputStandard.module.scss';

type InputStandardProps = {
    value:string,
    type:string,
    name:string,
    id:string,
    label:string,
    placeholder:string,
    icon?:JSX.Element,
    disabled:boolean,
    onKeyHandler?:()=>void
    onchange:ChangeEventHandler<HTMLInputElement>
}

export default function InputStandard({
    value,
    type,
    label,
    name,
    id,
    icon,
    placeholder,
    onchange,
    onKeyHandler,
    disabled=false
    }:InputStandardProps):JSX.Element{

    const [focus,setFocus] = useState(false);

    const inputSpring = useSpring({
            value: focus ? 'var(--primary-color)' : 'var(--grey-color)',
            label:focus ? 'var(--primary-color)' : 'var(--grey-color)',
        
    })

    const styled = {
       wrapper:{
        '--border-easy-input':inputSpring.value,
        '--icon-color-easy-input':inputSpring.value,
        '--background-easy-input':disabled ? 'var(--grey-very-lighter)' : 'transparent',
        '--font-color-easy-input': disabled ? 'var(--grey-darker)' : 'var(--grey-light-darker)' 
    } as CSSProperties
    ,
       label:{ 
        '--label-color-easy-input':inputSpring.label,
        '--label-weight-easy-input':focus ? '600' : '300'
    } as CSSProperties
}
    return(
        <div className={styles["input-standard-container"]}>
            <animated.label style={styled.label} htmlFor={id}>{label}</animated.label>
            <animated.div style={styled.wrapper} className={styles["input-wrapper"]}>
                <input onKeyUp={onKeyHandler} disabled={disabled} placeholder={placeholder} onFocus={()=>setFocus(true)} onBlur={()=>setFocus(false)} onChange={onchange} value={value} type={type} name={name} id={id}/>
                {icon ?? icon}
            </animated.div>
        </div>  
    )
}