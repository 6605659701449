import { CSSProperties, Fragment, useEffect, useReducer,useState } from "react";
import { useInitFbSDK } from "../../../../hooks/fb-hooks";
import { createCarroussel, createChildrenElementForCaroussel, createMediaObjectContainer, createReelObjectContainer, isUploadSuccessful, onLoginFB, publishMediaObjectContainer } from '../../../../components/social-network/api/instagram-api'
import ModalPublish from "../../../../components/social-network/modal-publish";
import useDidMountEffect from "../../../../hooks/useDidmountEffect";
import Modal from "../../../../components/modal";
import { useSelector,useDispatch } from "react-redux";
import LayoutSocial from "../layout-social";
import { errorMessage } from "../../../../components/social-network/error_message";
import { trackingSocialPublication } from "../../../../redux/actions/pci";
import { useAppSelector } from "../../../../redux/hooks";




export default function InstagramsPage():JSX.Element{
    useInitFbSDK();

    
    //const [post,dispatch] = useReducer(reducer,initialState);
    //@ts-ignore next-line
    const dispatch = useDispatch();
    const post = useAppSelector(state=>state.social);
    const {nonce,id} = useAppSelector(state=>state.auth.authentication);
    const text = post.texte ?  post.tags ? `${post.texte}\n ${post.tags.map(i=>{if(!i.startsWith('#'))return `#${i}`;return i}).join(' ')}` : post.texte : post.texte
    const [facebookAccessToken,setFacebookAccessToken] = useState<string | null>(null);
    //error success fb publish
    const [fbPublishMessage,setFbPublishMessage] = useState<{open:boolean,type:string,message:string}>({open:false,type:'',message:''});
    //state toggle show modal publication
    const [showModalPublish,setShowModalPublish] = useState<boolean>(false);
    //state loading publication
    const [loadingPublication,setLoadingPublication] = useState<boolean>(false)

     /**
     * *PUBLISH CONTAINER CALL BACK
     * @param facebookAccessToken 
     * @param id_account_business 
     * @param id_container 
     */

    async function publishContainer(facebookAccessToken,id_account_business,id_container){
        try{
            const isReadyForPublish = await isUploadSuccessful(0,facebookAccessToken,id_container)
            if(isReadyForPublish){
                await publishMediaObjectContainer(
                    facebookAccessToken,
                    id_account_business,
                    id_container
                  );
                setLoadingPublication(false)
                setShowModalPublish(false)
                dispatch(trackingSocialPublication(id,nonce,post.id))
                return setFbPublishMessage({type:"success",message:errorMessage.instagram.succes_publication,open:true})   
            }else{
                setLoadingPublication(false);
                setShowModalPublish(false)
                return setFbPublishMessage({type:"error",message:errorMessage.instagram.error_publication,open:true})   
            }
         
        }catch(error){
            setLoadingPublication(false);
            setShowModalPublish(false)
            return setFbPublishMessage({type:'error',message:errorMessage.instagram.error_publication,open:true})  
        }
        
    } 
    
    /*
    ** LOGIN FACBEOOK
    */
    async function onLogin(){
      try{
        const {authResponse} =  await onLoginFB();
            if(authResponse.accessToken){
                setFacebookAccessToken(authResponse.accessToken);
                setShowModalPublish(true)
            }
            
        }catch(error){
            setFbPublishMessage({type:'error',message:errorMessage.instagram.error_publication,open:true})  
      
        }
    }
    

    /**
     * PUBLISH CAROUSSEL
     * @param id_account_business 
     * @param publishMediaContainer 
     */
    async function onPublishCaroussel(id_account_business,publishMediaContainer){
        setLoadingPublication(true)
        //Create all children element's caroussel
        const media_list = post?.galerie.map(i=>i.url).map( async (element)=>{
            return  await createChildrenElementForCaroussel(facebookAccessToken,id_account_business,{img:element});
        })

        const mediaList0bject = await Promise.all(media_list);

         //Create container caroussel returning id
        if(mediaList0bject.includes(undefined)){
            setLoadingPublication(false);
            setShowModalPublish(false)
            return setFbPublishMessage((prev)=>{return {...prev,type:"error",message:errorMessage.instagram.error_img_caroussel,open:true}})
        }
        const {id:mediaObjectContainerId,error:errorContainerCaroussel} =  await createCarroussel(facebookAccessToken,id_account_business,mediaList0bject,text);

        if(errorContainerCaroussel){
            setLoadingPublication(false);
            setShowModalPublish(false)
            return setFbPublishMessage({type:"error",message:errorContainerCaroussel.message,open:true})
        }
        await publishMediaContainer(facebookAccessToken,id_account_business,mediaObjectContainerId)
        
    }


    /**
     * PUBLISH PICTURE
     * @param id_account_business 
     * @param publishMediaContainer 
     */
    async function onPublishPicture(id_account_business,publishMediaContainer){
        setLoadingPublication(true)
        const {id:pictureContainerID,error:errorPictureContainer} =   await createMediaObjectContainer(facebookAccessToken,id_account_business,{img:post?.galerie[0].url,caption:text});
        if(errorPictureContainer){
            setLoadingPublication(false);
            setShowModalPublish(false)
            return setFbPublishMessage({type:"error",message:errorPictureContainer.message,open:true})
        }
        
        await publishMediaContainer(facebookAccessToken,id_account_business,pictureContainerID)
    }


    /**
     * PUBLISH VIDEO
     * @param id_account_business 
     * @param publishMediaContainer 
     */
    async function onPublishVideo(id_account_business,publishMediaContainer){
        setLoadingPublication(true)
        const {id:reelContainerId,error:errorReelContainer} =   await createReelObjectContainer(facebookAccessToken,id_account_business,post?.galerie[0].url,text)
        if(errorReelContainer){
            setLoadingPublication(false);
            setShowModalPublish(false)
            return setFbPublishMessage({type:"error",message:errorReelContainer.message,open:true})
        }
        await publishMediaContainer(facebookAccessToken,id_account_business,reelContainerId)
    }



    /**
     * Publish handler conditionnal by type
     * @param id_account_business 
     * @param type 
     */
    function onPublishContent(account,type){
      
        if(type==='caroussel'){
            onPublishCaroussel(account.id,publishContainer)
        }
        else if (type === 'video'){
            onPublishVideo(account.id,publishContainer)
        }
        else{
            onPublishPicture(account.id,publishContainer)
    }
}

useDidMountEffect(()=>{
    if(!fbPublishMessage && facebookAccessToken){
        //@ts-ignore next-line
        window.FB.logout(() => {
            setFacebookAccessToken(null);
          });
    }
},[fbPublishMessage])



    return(
            <LayoutSocial  
                onLogin={onLogin} 
                showModalPublish={showModalPublish} 
                plateform={"instagram"} 
                onCloseModalPublish={()=>setShowModalPublish(false)} 
                auth_token={facebookAccessToken} 
                onPublishContent={onPublishContent} 
                loadingPublication={loadingPublication}
                responseMessage={fbPublishMessage}
                onCloseModalResponseMessage={() => setFbPublishMessage((prev)=>{return {...prev,open:false}})}
                />
    )
}

