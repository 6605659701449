export const referenceIcon ={
    edit:["edit","edition","écrire","ecrire","stylo","crayon","modifier","update","ecriture","édition","formulaire","form"],
    notedit:["notedit","edition","écrire","ecrire","stylo","crayon","modifier","update","supprimer","remove","reset","effacer","écriture","édition","formulaire","form"],
    send:["envoyer","send","message","avion","mail","valider","go","validation","message","conversation","commentaire"],
    identifiant:["identifiant","user","utilisateur","mail","email","personne","person","formulaire","form"],
    password_show:["password_show","password","input","mot de passe","eye","voir","oeil","mot-de-passe","formulaire","form","identifant"],
    password_hide:["password_hide","password","input","mot de passe","eye","voir","oeil","mot-de-passe","formulaire","form","identifant"],
    theme:["theme","thème","dark","white","couleur","colorimetrie","color","lune"],
    achat:["achat","acheter","boutique","panier","cadit"],
    communication:["communication","communicate","parler","exprimer","dire"],
    securite:["securite","sécurité","cadenas","open","close","form","formulaire"],
    cadeau:["cadeau","gift","offrir","boutique","gagner","offre","jeu","jouer"],
    imprimante:["imprimante","imprimer","bureau","bureautique","papier","print"],
    profil:["profil","person","personne","user","utilisateur","profile","humain"],
    arobase:["arobase","mail","email","application"],
    diplome:["diplome","diplôme","person","personne","réussir","user","utilisateur","école","université",'fac','humain'],
    feuille:["feuille","nature","plante"],
    balance:["balance","équilibre","equilibre","justice","juridique"],
    administration:["administration","maison","house","home","homepage","admin","accueil"],
    "actis-parc":["actis-parc","actis","engin","moteur","materiel","outil","batiment"],
    "lien-1":["lien-1","engin","moteur","materiel","outil","pelleteuse","agricole","batiment"],
    "lien-2":["lien-2","ordinateur","bureau","bureautique","écran","ecran","screen","apple"],
    actualites:["actualites","actualité","news","paper","bureau","bureautique","journal","feuille","document"],
    life:["life","image","photo","instagram","vie","photographie"],
    agenda:["agenda","calendrier","calendar","date","datetime"],
    "annuaire-reseau":["annuaire-reseau","réseaux","adherent","annuaire","book","concession"],
    "annuaire-partenaires":["annuaire-partenaires","annuaire","book","partenaire","partner","fournisseur"],
    "comptes-rendus":["comptes-rendus","compte-rendu","bureautique","bureau","papier","paper","juridique","bloc-note","bloc","note"],
    "telechargements":["telechargements","téléchargement","download","upload","charger","télécharger"],
    "boutique":["boutique","panier","ajouter","add","achat","acheter"],
    "boutique-formations":["boutique-formations","boutique","acheter","former","user","utilisateurs","group","groupe","team","person","personne","duo","deux"],
    "sondages":["sondages","statistique","stats","camembert","sondage"],
    "mondial-actualites":["mondial-actualites","mondial","actualité","actu","news","paper","papier","bureau","bureautique","feuille","document","partner","fournisseur"],
    "mondial-annuaire":["mondial-annuaire","mondial","annuaire","adherent","book","user","utilisateur","groupe","group","team","fournisseur","partenaire","partner"],
    "mondial-annuaire-partenaires":["mondial-annuaire-partenaires","mondial","annuaire","adherent","concessionnaire","concession","partenaire","partner","book","user","utilisateur","groupe","group","team","phone","téléphone","fournisseur"],
    "mondial-boutique-marketing":["mondial-boutique-marketing","boutique","marketing","mondial","panier","cadit","acheter","buy"],
    "mondial-boutique-spa":["mondial-boutique-spa","boutique","spa","eau","nature","naturel","piscine","water","stats","statistique"],
    "mondial-circulaires":["mondial-circulaires","mondial","circulaire","livre","papier","paper","book","feuille","page","open","ouvert"],
    "mondial-club":["mondial-club","club","mondial","stars","favoris","étoile"],
    "mondial-deconnexion":["mondial-deconnexion","mondial","deconnexion",'déconnexion',"leave","connexion","partir","disconnect"],
    "mondial-deviseur":["mondial-deviseur","deviseur","achat","document","paper","papier","mondial","devise","argent"],
    "mondial-documentation-marketing":["mondial-documentation-marketing","mondial","megaphone","mégaphone","crier","marketing","parler"],
    "mondial-documents-techniques":["mondial-documents-techniques","mondial","engrenage","option","technique","mécanique"],
    "mondial-evenements":['mondial-evenements',"mondial","évenement","calendar","calendrier","time","agenda"],
    "mondial-extranet":["mondial-extranet","extranet","mondial","cube"],
    "mondial-facebook":["mondial-facebook","mondial","facebook","réseaux sociaux","social","réseaux","network","social network","feed","publication"],
    "mondial-instagram":["mondial-instagram","mondial","instagram","réseaux sociaux","social","réseaux","network","social network","feed","photo","image","publication"],
    "mondial-linkedin":["mondial-linkedin","mondial","linkedin","réseaux sociaux","social","réseaux","network","social network","feed","publication"],
    "mondial-photos":["mondial-photos","photo","appareil photo","appareil","mondial"],
    "mondial-realisations":["mondial-realisations","image","photo","picture"],
    "mondial-reseaux":["mondial-reseaux","rss","réseaux sociaux","social","réseau","network","sociaux","social network","feed"],
    "mondial-trombinoscope":["mondial-trombinoscope","annuaire","book","phone","téléphone","user","utilisateur","trombinoscope","partenaire","réseaux","partner","concession","concessionaire"],
    "SharePoint":["SharePoint","share","partager","dollar",'argent',"rond","s","letter","lettre","point"],
    'Teams':["Teams","duo","deux","user","utilisateur","person","personne","team","groupe","group","équipe"],
    "Cse":["Cse","argent","euro","devise","payer","achat"],
    "N2F":["N2F","paper","papier","document","feuille","bureautique",'news'],
    "Campus":["Campus","diplome","diplôme","école","ecole","université","collège","fac","étude","étudier"],
    "Corporama":["Corporama","megaphone","mégaphone","crier","parler"],
    "Ines":["Ines","soleil","été","brille","journée","matin","ensoleillé","étoile"],
    "Talents":["Talents","annuaire","favoris","partenaire","réseaux","partenaire","book","user","utilisateurs"],
    "boutique-cahra":["boutique-cahra","boutique","cahra","cahra","acheter","buy","magasin","marché","deventure"],
    "VekaChiffre":["VekaChiffre","euro","argent","veka","devise","dépense","chiffre","chiffe d'affaire","ca"],
    "ca":["euro","argent","veka","devise","dépense","chiffre","chiffe d'affaire","ca"],
    "VekaArgumentaires":["VekaArgumentaires","menu","liste","texte","indentation","left","gauche","veka"],
    "VekaRéalisations":["VekaRéalisations","idée","veka","lampe","allumer","ampoule"],
    "VekaMorassuti":["VekaMorassuti","morassuti","image","photo","veka"],
    "VekaRéférencement":["VekaRéférencement","référencement","référence","stars","étoile","veka"],
    "Vekacontact":["Vekacontact","contact","téléphone","phone","raccrocher","appeler","call","veka"],
    "AfdfPublicationsOuvertes":["AfdfPublicationsOuvertes","commentaire","comment","discuter","bd","publication","afdf"],
    "AfdfAnnuaireAfdf":["AfdfAnnuaireAfdf","annuaire","question","bulle","commentaire","comments"],
    "AfdfAnnuaireFournisseurs":["AfdfAnnuaireFournisseurs","fournisseur","partner","panier","achat","boutique"],
    "AfdfBouquets":["AfdfBouquets","achat","boutique","panier"],
    "AfdfOffresFournisseurs":["AfdfOffresFournisseurs","euro","devise","argent","riche"],
    "AfdfSiteVente":["AfdfSiteVente","vente","panier","cadit","boutique","acheter","course"],
    "AfdfTendances":["AfdfTendances","stars","étoile","tendance"],
    "map":["map","marker","carte","localisation","cartographie","géolocalisation"],
    "search":["search","input","research","cherche","rechercher","loupe"],
    "linkedin":["linkedin","réseaux sociaux","social","network","social network","réseaux"],
    "instagram-solid":["instagram-solid","instagram","réseaux sociaux","social","network","réseaux"],
    "instagram-line":["instagram-line","instagram","réseaux sociaux","social","network","réseaux"],
    "long-arrow-right":["long-arrow-right","right","arrow","fleche","flêche","droite","next","suivant"],
    "arrow-left":["arrow-left","left","gauche","arrow","fleche","flêche","back","retour","précédent"],
    "arrow-right":["arrow-right","right","arrow","fleche","flêche","droite","next","suivant"],
    "information":["information","info","i","important"],
    "account":["account","user","profil","profile","utilisateur"],
    "add-document":["add-document","document","téléchargement","download","add","ajouter","fichier","files"],
    "notification":["notification","cloche","notif","alerte"],
    "alert-2":["alert-2","alert","alerte","attention","erreur","error"],
    "avatar-default":["avatar-default","account","user","profil","profile","utilisateur","avatar"],
    "bad":["bad","erreur","error","colère"],
    "bar":["bar","curve","title"],
    "calendar":["calendar","calendrier","agenda","calendar","planning","time"],
    "campus":["campus","diplome","diplôme","école","ecole","université","collège","fac","étude","étudier"],
    "check":["check","valid","valider","succès","succes"],
    "chevron-right":["chevron-right","chevron",'right',"droite","next","suivant"],
    "chevron-right-2":["chevron-right-2","chevron",'right',"droite","next","suivant"],
    "chevron-up":["chevron-up","chevron","up","haut","remonter"],
    "comment":["comment","commentaire","comment","parler","répondre","communication"],
    "cross":["cross","fermer","close","croix"],
    "curve":["curve","bar","title"],
    "date":["date","time","heure","hour","montre","chrono"],
    "download":["download","téléchargement","upload","télécharger","import"],
    "download-2":["download-2","download","téléchargement","upload","télécharger","import"],
    "download-import":["download-import","download","téléchargement","upload","télécharger","import"],
    "email":["email","mail","message",'enveloppe'],
    "engrenage":["engrenage","options","technique"],
    "error":["error","engrenage","technique","technique"],
    "euro":["euro","devise","argent","monnaie"],
    "exclamation":["exclamation","attention","error","erreur"],
    "eye":["eye","vue","look","dashboard","voir"],
    "facebook":["facebook","social","network","social network","réseaux sociaux","réseaux","sociaux"],
    "gallery":["gallery","gallerie","image","photos","multiple","paysage"],
    "globe":["globe","internet","réseaux","web",'url',"connexion","globe","terre","planète","planet"],
    "globe-2":["globe-2","globe","internet","réseaux","web",'url',"network","globe","terre","planète","planet"],
    "heart":["heart","coeur","vie","amour","like","publication","réseaux",'sociaux',"social","network"],
    "home":["home","maison","house","accueil","homepage"],
    "image-alone":["image-alone","image","picture","photo","paysage","life"],
    "image":["image","picture","photo","paysage","life"],
    "image-multi":["image-multi","multi","multiple","image","picture","photo","paysage","life"],
    "mail-at":["arobase","mail","at","email","message"],
    "option":["option","more","plus"],
    "phone":["phone","téléphone","décrochet","call","appeler"],
    "reset":["reset","refresh","relancer","recharger","recommencer","reload"],
    "rocket":["rocket","fusée","mise à jour"],
    "search-2":["search-2","search","loupe","chercher",'rechercher',"research","input"],
    "smiley":["smiley","heureux","yes","oui","content"],
    "start":["start","stars","étoile","favoris"],
    "success":["success","succès","check",'good',"bon"],
    "tab":["tab"],
    "trash":["trash","poubelle","jeter","jette"],
    "tuto-chrome":["tuto-chrome","bulle","comment","commentaire","tutoriel","parler"],
    "tuto-chrome-firefox":["tuto-chrome-firefox","tutoriel","tuto"],
    "tuto-firefox":["tuto-firefox","tutoriel","bulle","comment","commentaire","parler","tuto"],
    'tuto-installed':["tuto-installed","tutoriel","bulle","comment","commentaire","parler","tuto"],
    "tuto-internet":["tuto-internet","tutoriel","bulle","comment","commentaire","parler","tuto"],
    'tuto-safari-1':["tuto-safari-1","tutoriel","bulle","comment","commentaire","parler","tuto"],
    'tuto-safari-2':['tuto-safari-2','tuto',"tutoriel"],
    'user':["user",'utilisateur','person',"personne",'profil',"profile"],
    'user-picture':['user-picture',"user",'utilisateur','person',"personne",'profil',"profile"],
    'web-link':["web-link","link","web",'url','globe','internet',"network",'réseaux','terre','planète','planet','connexion'],
    'fullscreen':["fullscreen","screen","full","tv"],
    'tv':["tv","screen","desktop","télé","télévision","tv-show"],
    'project':['project','task'],
    'document':['document','fichier'],
    'rss':["rss",'social','network','social-network','réseau','sociaux','réseau social'],
    'user-photo':["user-photo",'user','photo','trombinoscope'],
    'pie':['pie','sondage','statistic','statistique','data'],
    'location':['location','marker','map'],
    'arrow':['arrow','flêche','fleche'],
    'more':['more','plus','option','options'],
    'new-edit':["new-edit",'edit','crayon','éditer'],
    'instagram':['instagram'],
    'chevronright':['chevron','right'],
    'chevronleft':['chevron','left'],
    like:['like'],
    'like-plain':['like-plain','like','plain'],
    comments:['comments','comment'],
    'alert-notification':['alert-notification','alert','alerte','notification'],
    chat:['chat','dialogue','dialog'],
    'notif':['notif','notification','alert','alerte'],
    house:['house','home','maison'],
    atom:['atom','atome','atomique'],
    shop:["shop",'shopping'],
    moderate:['moderate']

}

function removeDuplicates(arr) {
    let unique : any = [];
    arr.forEach((element:any) => {
        if (!unique.includes(element)) {
            unique.push(element);
        }
    });
    return unique;
}

export const allRef =  removeDuplicates(
    Object.keys(referenceIcon).map(i=>{
        return referenceIcon[i]
    }).flat());


export function searchIconHaveRef(text){
    const arrayRef :any[] = [];
    Object.keys(referenceIcon).forEach(item=>{
        for(var i=0;i<referenceIcon[item].length;i++){
            if(referenceIcon[item][i] === text){
                arrayRef.push(item)
            }
        }
    })
    return arrayRef
}
