import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/pci/api'
import { stats } from '../../config/stats'
import { postLike as postLikeFromLike } from '../post-picture/postPictureSlice';
import { postLike as postLikeFromStandard } from '../post-standard/postStandardSlice';
import { postLike as postLikeFromHome } from '../home/homeSlice';
import {postLikeFeat as postLikeFeatFromHome} from '../home/homeSlice';
import {postComment as postCommentFromPublicationStandard} from '../post-standard/postStandardSlice';
import {postComment as postCommentFromPublicationLike} from '../post-picture/postPictureSlice';
type checkOutStateType = 'LOADING' | 'ERROR' | 'READY'

type Publication = {
  page: number
  data: Array<any>
  feat: any
  carousel_partners: Array<any>
  documents: any
  mega_menu: boolean
  last: boolean
  breadcrumb: any
  checkoutStateMore: checkOutStateType
  list_category: Array<any>
}

type PublicationsState = {
  posts: {
    [key: string]: {
      posts: { [key: string]: any }
      list_category: Array<any>
      breadcrumb: Array<any>
      checkOutState: checkOutStateType
    }
  }
  error: string
  checkOutState: checkOutStateType
}

let initPublication: PublicationsState = {
  posts: {},
  error: '',
  checkOutState: 'LOADING',
}

export const getFirstRowPublication = createAsyncThunk(
  'publications/firstrow',
  async ({ parentCat, childCat }: { parentCat: number; childCat }, { rejectWithValue, getState }) => {
    const {
      session: { lastconnect },
      auth: {
        authentication: { id, nonce },
      },
    } = getState() as any

    try {
      const [responseData, responseCategorie] = await Promise.all([
        api.get('/filposts', {
          params: {
            user: id,
            nonce: nonce,
            page: 1,
            cat: childCat,
            lastconnect: lastconnect,
            stat: stats(),
          },
        }),
        api.get('/listpoststags', {
          params: {
            user: id,
            nonce: nonce,
            cat: parentCat,
          },
        }),
      ])

      const firtRowResponse = [responseData, responseCategorie].map((response) => {
        //@ts-ignore
        if (response.data.hasOwnProperty('error') && (response.data.posts.length === 0 || !response.data.posts)){
          
          //@ts-ignore
          return rejectWithValue(response.data.error)
        } 
        //@ts-ignore
        return response.data
      })

      if(! (firtRowResponse as any)[0].hasOwnProperty('posts')){
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }

      return firtRowResponse
    } catch (err: any) {
      if (!err.response) {
        throw err
      } else if (err.statut == 404) {
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
      return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
    }
  }
)

export const getPublications = createAsyncThunk(
  '/publications/getPublications',
  async ({ parentCat, childCat }: { parentCat; childCat }, { rejectWithValue, getState }) => {
    const {
      session: { lastconnect },
      auth: {
        authentication: { id, nonce },
      },
    } = getState() as any

    try {
      const response = await api.get('/filposts', {
        params: {
          user: id,
          nonce: nonce,
          page: 1,
          cat: childCat,
          lastconnect: lastconnect,
          stat: stats(),
        },
      })

      //@ts-ignore
      if (response.data.hasOwnProperty('error') && (response.data.posts.length === 0 || !response.data.posts))
        //@ts-ignore
        return rejectWithValue(response.data.error)
      //@ts-ignore
      return response.data
    } catch (err: any) {
      if (!err.response) {
        throw err
      } else if (err.statut == 404) {
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
      return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
    }
  }
)

export const postLike = createAsyncThunk(
  '/publications/postLike',
  async ({ id_post, parentCat, childCat }: { id_post; parentCat; childCat }, { rejectWithValue, getState }) => {
    const {
      auth: {
        authentication: { id, nonce },
      },
    } = getState() as any
    try {
      const response = await api.get('/newlike', {
        params: {
          user: id,
          nonce: nonce,
          post: id_post,
          stat: stats(),
        },
      })
      //@ts-ignore
      if (response.data.hasOwnProperty('error'))
        //@ts-ignore
        return rejectWithValue(response.data.error)
      //@ts-ignore
      return response.data
    } catch (err: any) {
      if (!err.response) {
        throw err
      } else if (err.statut == 404) {
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
      return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
    }
  }
)


export const postLikeFeat = createAsyncThunk(
  '/publications/postLikeFeat',
  async ({ id_post, parentCat, childCat }: { id_post; parentCat; childCat }, { rejectWithValue, getState }) => {
    const {
      auth: {
        authentication: { id, nonce },
      },
    } = getState() as any
    try {
      const response = await api.get('/newlike', {
        params: {
          user: id,
          nonce: nonce,
          post: id_post,
          stat: stats(),
        },
      })
      //@ts-ignore
      if (response.data.hasOwnProperty('error'))
        //@ts-ignore
        return rejectWithValue(response.data.error)
      //@ts-ignore
      return response.data
    } catch (err: any) {
      if (!err.response) {
        throw err
      } else if (err.statut == 404) {
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
      return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
    }
  }
)

export const getMorePublication = createAsyncThunk(
  '/publications/getMorePublications',
  async ({ parentCat, childCat, page }: { parentCat; childCat; page }, { rejectWithValue, getState }) => {
    const {
      session: { lastconnect },
      auth: {
        authentication: { id, nonce },
      },
    } = getState() as any

    try {
      const response = await api.get('/filposts', {
        params: {
          user: id,
          nonce: nonce,
          page: page,
          cat: childCat,
          lastconnect: lastconnect,
          stat: stats(),
        },
      })
      //@ts-ignore
      if (response.data.hasOwnProperty('error'))
        //@ts-ignore
        return rejectWithValue(response.data.error)
      //@ts-ignore
      return response.data
    } catch (err: any) {
      if (!err.response) {
        throw err
      } else if (err.statut == 404) {
        return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
      }
      return rejectWithValue("Une erreur s'est produite lors de la récupération des données.")
    }
  }
)

export const publicationSlice = createSlice({
  name: 'publications',
  initialState: initPublication,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFirstRowPublication.pending, (state, { payload, meta }) => {
      state.checkOutState = 'LOADING'
    })

    builder.addCase(getFirstRowPublication.rejected, (state, { payload, meta }) => {
      state.checkOutState = 'ERROR'
    })

    builder.addCase(getFirstRowPublication.fulfilled, (state, { payload, meta }) => {
      state.checkOutState = 'READY'
      state.posts[meta.arg.parentCat] = {
        checkOutState: 'READY',
        posts: {
          [meta.arg.childCat]: {
            data: (payload[0] as any).posts.length ?  (payload[0] as any).posts.filter((_: any) => _.feat !== true) :null,
            feat: (payload[0] as any).posts.find((_: any) => _.feat === true),
            mega_menu: (payload[0] as any).mega_menu,
            page: 2,
            last:
              (payload[0] as any).posts.filter((_: any) => _.feat !== true).length === 0
                ? true
                : (payload[0] as any).last ?? false,
            checkoutStateMore: 'READY',
          },
        },
        breadcrumb: (payload[0] as any).breadcrumb,
        list_category: payload[1] as any,
        }
      
    })

    builder.addCase(getPublications.pending, (state, { payload, meta }) => {
      state.posts[meta.arg.parentCat].checkOutState = 'LOADING'
    })

    builder.addCase(getPublications.rejected, (state, { payload, meta }) => {
      state.posts[meta.arg.parentCat].checkOutState = 'ERROR'
    })

    builder.addCase(getPublications.fulfilled, (state, { payload, meta }) => {
      state.posts[meta.arg.parentCat] = {
        ...state.posts[meta.arg.parentCat],
        checkOutState: 'READY',
        posts: {
          ...state.posts[meta.arg.parentCat].posts,
          [meta.arg.childCat]: {
            data: (payload as any).posts.filter((_: any) => _.feat !== true),
            feat: (payload as any).posts.find((_: any) => _.feat === true),
            last: (payload as any).last ?? false,
            page: 2,
            checkoutStateMore: 'READY',
          },
        },
      }
    })

    builder.addCase(getMorePublication.pending, (state, { payload, meta }) => {
      state.posts[meta.arg.parentCat].posts[meta.arg.childCat].checkoutStateMore = 'LOADING'
    })

    builder.addCase(getMorePublication.rejected, (state, { payload, meta }) => {
      state.posts[meta.arg.parentCat].posts[meta.arg.childCat].checkoutStateMore = 'ERROR'
    })

    builder.addCase(getMorePublication.fulfilled, (state, { payload, meta }) => {
      state.posts[meta.arg.parentCat].posts[meta.arg.childCat].checkoutStateMore = 'READY'
      state.posts[meta.arg.parentCat].posts[meta.arg.childCat].data.push(...(payload as any).posts)
      state.posts[meta.arg.parentCat].posts[meta.arg.childCat].last = (payload as any).last ?? false
      state.posts[meta.arg.parentCat].posts[meta.arg.childCat].page = meta.arg.page + 1
    })

    builder.addCase(postLike.fulfilled, (state, { payload, meta }) => {
      const publication = state.posts[meta.arg.parentCat].posts[meta.arg.childCat]
      publication.data = publication.data.map((item) => {
        if (item.id == meta.arg.id_post) return { ...item, likes: payload }
        return item
      })
    })

    builder.addCase(postLikeFeat.fulfilled, (state, { payload, meta }) => {
      const publication = state.posts[meta.arg.parentCat].posts[meta.arg.childCat]
      publication.feat =  {...publication.feat,likes:payload}
    })
    builder.addCase(postLikeFromLike.fulfilled,(state,{payload,meta})=>{
      const parentCat = meta.arg.lastContent.parentCat;
      const childCat = meta.arg.lastContent.childCat;

      if (meta.arg.lastContent.type == 'feat') {
        if (state.posts[parentCat]  &&  state.posts[parentCat].posts[parentCat] && state.posts[parentCat].posts[parentCat].feat) {
          state.posts[parentCat].posts[parentCat].feat.likes = payload
        }
        if (state.posts[parentCat] &&  state.posts[parentCat].posts[childCat] && state.posts[parentCat].posts[childCat].feat) {
            state.posts[parentCat].posts[childCat].feat.likes = payload
        }
      }
      else{
      if(state.posts[parentCat] && state.posts[parentCat].posts[childCat]){
        const index = state.posts[parentCat].posts[childCat].data.findIndex(i=>i.id == meta.arg.id_post);
        if(index >=0){
          state.posts[parentCat].posts[childCat].data[index].likes = payload
        } 
      }
      if(state.posts[parentCat] && state.posts[parentCat].posts[parentCat]){
        const indexParent = state.posts[parentCat].posts[parentCat].data.findIndex(i=>i.id == meta.arg.id_post);
        if(indexParent >=0){
          state.posts[parentCat].posts[parentCat].data[indexParent].likes = payload
        } 
      }
    }
    })
    builder.addCase(postLikeFromStandard.fulfilled,(state,{payload,meta})=>{
   
      if(meta.arg.lastContent!=undefined){
      const parentCat = meta.arg.lastContent.parentCat;
      const childCat = meta.arg.lastContent.childCat;
      if (meta.arg.lastContent.type == 'feat') {
        if (state.posts[parentCat]  &&  state.posts[parentCat].posts[parentCat] && state.posts[parentCat].posts[parentCat].feat) {
          state.posts[parentCat].posts[parentCat].feat.likes = payload
        }
        if (state.posts[parentCat] &&  state.posts[parentCat].posts[childCat] && state.posts[parentCat].posts[childCat].feat) {
            state.posts[parentCat].posts[childCat].feat.likes = payload
        }
      }else{
      if(state.posts[parentCat] && state.posts[parentCat].posts[childCat]){
        const index = state.posts[parentCat].posts[childCat].data.findIndex(i=>i.id == meta.arg.id_post);
        if(index >=0){
          state.posts[parentCat].posts[childCat].data[index].likes = payload
        }
      }
      if(state.posts[parentCat] && state.posts[parentCat].posts[parentCat]){
        const indexParent = state.posts[parentCat].posts[parentCat].data.findIndex(i=>i.id == meta.arg.id_post);
        if(indexParent >=0){
          state.posts[parentCat].posts[parentCat].data[indexParent].likes = payload
        } 
        }
      }
      }
    })
    builder.addCase(postLikeFromHome.fulfilled,(state,{payload,meta})=>{
      const parentCat = meta.arg.parentCat;
      if(state.posts[parentCat] && state.posts[parentCat].posts[parentCat]){
        const index = state.posts[parentCat].posts[parentCat].data.findIndex(i=>i.id == meta.arg.id_post);
        if(index >=0){
          state.posts[parentCat].posts[parentCat].data[index].likes = payload
        }
      }
    })
    
    builder.addCase(postLikeFeatFromHome.fulfilled,(state,{payload,meta})=>{
      const parentCat = meta.arg.parentCat;
      if(state.posts[parentCat] && state.posts[parentCat].posts[parentCat] && state.posts[parentCat].posts[parentCat].feat ){
        state.posts[parentCat].posts[parentCat].feat.likes = payload
      }
    })

    builder.addCase(postCommentFromPublicationStandard.fulfilled,(state,{payload,meta})=>{
      if(meta.arg.lastContent !== undefined &&  (payload as any).comments[(payload as any).comments.length -1].status === "approved"){
        const parentCat = meta.arg.lastContent.parentCat
        const childCat = meta.arg.lastContent.childCat
        if(meta.arg.lastContent.type == 'feat'){
          if(  parentCat &&
            state.posts[parentCat] &&
            state.posts[parentCat].posts[childCat] &&
            state.posts[parentCat].posts[childCat].feat
            && childCat !=parentCat
            ){
              state.posts[parentCat].posts[childCat].feat.comments.approved = Number(state.posts[parentCat].posts[childCat].feat.comments.approved) + 1
            }
            if(  parentCat &&
              state.posts[parentCat] &&
              state.posts[parentCat].posts[parentCat] &&
              state.posts[parentCat].posts[parentCat].feat
              ){
                state.posts[parentCat].posts[childCat].feat.comments.approved = Number(state.posts[parentCat].posts[parentCat].feat.comments.approved) + 1
              }
          }else{
              
              if(state.posts[parentCat] && state.posts[parentCat].posts[childCat] && parentCat !==childCat){
                const index = state.posts[parentCat].posts[childCat].data.findIndex(
                  (i) => i.id == meta.arg.id_post
                )
                if (index >= 0) 
                  state.posts[parentCat].posts[childCat].data[index].comments.approved = Number(state.posts[parentCat].posts[childCat].data[index].comments.approved) + 1
              } 
              if(state.posts[parentCat] && state.posts[parentCat].posts[parentCat]){
                const indexParent = state.posts[parentCat].posts[parentCat].data.findIndex(
                  (i) => i.id == meta.arg.id_post
                )
                if (indexParent >= 0) 
                  state.posts[parentCat].posts[parentCat].data[indexParent].comments.approved = Number(state.posts[parentCat].posts[parentCat].data[indexParent].comments.approved) + 1
              } 
            }
        }
  
  })
  builder.addCase(postCommentFromPublicationLike.fulfilled,(state,{payload,meta})=>{
    if(meta.arg.lastContent !== undefined &&  (payload as any).comments[(payload as any).comments.length -1].status === "approved"){
      const parentCat = meta.arg.lastContent.parentCat
      const childCat = meta.arg.lastContent.childCat
      if(meta.arg.lastContent.type == 'feat'){
        if(  parentCat &&
          state.posts[parentCat] &&
          state.posts[parentCat].posts[childCat] &&
          state.posts[parentCat].posts[childCat].feat
          && childCat !=parentCat
          ){
            state.posts[parentCat].posts[childCat].feat.comments.approved = Number(state.posts[parentCat].posts[childCat].feat.comments.approved) + 1
          }
          if(  parentCat &&
            state.posts[parentCat] &&
            state.posts[parentCat].posts[parentCat] &&
            state.posts[parentCat].posts[parentCat].feat
            ){
              state.posts[parentCat].posts[childCat].feat.comments.approved = Number(state.posts[parentCat].posts[parentCat].feat.comments.approved) + 1
            }
        }else{
            
            if(state.posts[parentCat] && state.posts[parentCat].posts[childCat] && parentCat !==childCat){
              const index = state.posts[parentCat].posts[childCat].data.findIndex(
                (i) => i.id == meta.arg.id_post
              )
              if (index >= 0) 
                state.posts[parentCat].posts[childCat].data[index].comments.approved = Number(state.posts[parentCat].posts[childCat].data[index].comments.approved) + 1
            } 
            if(state.posts[parentCat] && state.posts[parentCat].posts[parentCat]){
              const indexParent = state.posts[parentCat].posts[parentCat].data.findIndex(
                (i) => i.id == meta.arg.id_post
              )
              if (indexParent >= 0) 
                state.posts[parentCat].posts[parentCat].data[indexParent].comments.approved = Number(state.posts[parentCat].posts[parentCat].data[indexParent].comments.approved) + 1
            } 
          }
      }

})
  }
})
