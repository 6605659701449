import BackgroundVisio from "../background-visio";
import styles from './VisioPicture.module.scss';
export default function VisioPicture({onClose,medias}){
    return(
        <BackgroundVisio id={"visio-picture"} onClose={onClose} >
            <div className={styles["visio-picture-container"]}>
                <img src={medias[0]}/>
            </div>
        </BackgroundVisio>
    )
}