import ErrorApp from "./error-app";

const Components = {
    app:ErrorApp
}

export default function ErrorComponent({name,...props}){
    
    let Component = Components[name];

    if(Component!=undefined)
        return <Component {...props} />
    return null
}