import PostCard from "./post-card/PostCard";
import FeatCard from "./feat-card";
import LifeCard from "./life-card";

const cards = {
    post:PostCard,
    feat:FeatCard,
    life:LifeCard
}


type PublicationCardProps <T extends keyof typeof cards> ={
    name:T,
} & ComponentPropsMapping[T]

type ComponentPropsMapping = {
    post: React.ComponentProps<typeof PostCard>;
    feat: React.ComponentProps<typeof FeatCard>;
    life:React.ComponentProps<typeof LifeCard>
  };

export default function PublicationCard<T extends keyof typeof cards>({
        name,
        ...props
    }
    
    :PublicationCardProps<T>){
    let Component = cards[name];

    if(Component!==undefined){
        return <Component {...(props as any)}/>
    }
    return null
}