import Icon from '../../../../icons';
import styles from './FeatCard.module.scss';
import {Link,useHistory,useLocation} from 'react-router-dom';

type FeatCardProps = {
    title:string,
    img:string,
    text:string,
    auteur:string,
    date:string,
    entreprise:string,
    likes:{me:boolean,count:number}
    comments:{hold:number,approve:number},
    id:string|number,
    type:'standard' | 'photo',
    onLikeFeat?:any,
    currentLocation:{type:'feat',parentCat:string,childCat:string}
}

export default function FeatCard({
    title,
    img,
    text,
    auteur,
    entreprise,
    date,
    likes,
    comments,
    id,
    type,
    onLikeFeat,
    currentLocation
}:FeatCardProps){

    const location = useLocation();
    const history = useHistory();

    function goTo(){
     const path = type =='standard' ?  `/actualite/actualites/${id}` :  `/photo/${id}`
     const obj = type === 'standard' ?  { pathname:path,state:{lastContent:currentLocation}} : {pathname:path,state:{background:location,lastContent:currentLocation}}
     history.push(obj)

    }

    return(
        <div onClick={goTo} className={styles["feat-card-container"]}>
            <div className={styles["feat-img-container"]}>
               {img ? <img loading="lazy" alt='feat' src={img}/> : null}
            </div>
            <div className={styles["feat-wrapper-right"]}>
                <div className={styles["feat-content-container"]}>
                    <h3 className={styles["feat-type"]}>A la une !</h3>
                    <div className={styles["feat-title"]}>{title??''}</div>
                    <div className={styles["feat-text"]}>{text??''}</div>
                    <div className={styles["feat-author"]}>{`Publié par : ${auteur??''} ${entreprise? `( ${entreprise} )` :''}`}</div>
                    <div className={styles["feat-date"]}>{date ? `Le ${date}` : ''}</div>
                </div>
                <div className={styles["feat-footer"]}>
                    <div className={styles["feat-action"]}>
                        {likes && <div className={styles["feat-like"]}>
                            <Icon onClick={(e)=>{e.stopPropagation();onLikeFeat(id)}} name={likes?.me ? 'like-plain' : 'like' as any}/>
                            <span>{likes?.count?? '0'}</span>
                        </div>
                        }
                        {(likes && comments) && <div>|</div>}
                        {comments && <div className={styles["feat-comment"]}>
                            <Icon name='comments'/>
                            <span>{(comments as any)?.approved?? '0'}</span>
                        </div>
                        }    
                    </div>
                    <div className={styles["arrow"]}><Icon name='chevronright'/></div>
                </div>
            </div>
        </div>
    )
}

