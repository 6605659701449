import { Fragment,forwardRef } from 'react';
import { useLockBodyScroll } from 'react-use';
import CardSearchLite from './card-search-lite/CardSearchLite';
import styles from './ModalSearch.module.scss';

export type modalSearchBarProps ={
    searchText:string,
    listSearch:Array<any>,
    onViewAllSearch?:()=>void,
    onGoToPageSearch:(id_rub:string,id_detail:string)=>void
}
 const ModalSearch = forwardRef<HTMLDivElement, modalSearchBarProps>(({searchText,listSearch,onViewAllSearch,onGoToPageSearch},ref):JSX.Element | null=>{

    useLockBodyScroll(true)
    if(listSearch.length !==0){
    return(
        <div ref={ref} className={styles["modal-search-container"]}>
    
            <Fragment>
                    <div className={styles['list-container']}>
                    {listSearch.map(({id,title,type,url},key)=>{
                        return <CardSearchLite id={id} url={url}  onGoToPageSearch={onGoToPageSearch} className={styles["card-search"]} key={key} title={title} type={type} />
                    })}
                    </div>
                    <button onClick={onViewAllSearch} className={styles["more-result-button"]}>Voir plus de résultats</button>
            </Fragment>
        
        </div>
    )
    }
    else return null
})


export default ModalSearch