import Layout from "../../components/pgl/Layout/Layout";
import styles from "../../components/pgl/Chalances/Chalances.module.scss";
import AutoCompleteConcession from "../../components/pgl/Chalances/AutoCompleteConcession";
import { useDispatch,useSelector } from "react-redux";
import {useState,useEffect,useRef} from 'react';
import { pgl_getCompanies } from "../../redux/actions/pgl";
import ButtonText from "../../components/ui/buttons/ButtonText";
import {useHistory} from 'react-router-dom';
import { useNavbarPgl } from "../../hooks/useNavbarPgl";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ButtonBack from "../../components/ui/buttons/ButtonBack";


export default function Chalances(props){
  const { setNavbarTitle } = useNavbarPgl()
  const dispatch = useDispatch();
  const history = useHistory();
  
  const mobileDimensions = useWindowDimensions()



  const [concession,setConcession] = useState('');
  const [commercial,setCommercial] = useState('');
  const authentication = useSelector(state=> state.auth.authentication);
  const companies = useSelector(state=>state.pgl_companies);
  const [list,setList] = useState([]);
  const [select,setSelect] = useState(false);
  const [concessionSelected,setConcessionSelected] = useState(false)

  function goBack(){
    history.goBack();
  }
  useEffect(()=>{
    setNavbarTitle('Gestion des zones')
    dispatch(pgl_getCompanies(authentication.id, authentication.nonce))
  },[])


  const onChangeConcession = (e) =>{
    setConcession(e.target.value)
  }

  const data = Object.keys(companies).map((key,i)=>{ return companies[key] });
   
  useEffect(()=>{
    if(concession =="" || select){
      setList([]);
    }else{
    let reg = new RegExp(concession.toLowerCase());
     var newArray = data.filter(function(term){if(term.post_title.toLowerCase().match(reg)){return term}})
     if (newArray.length > 0){
      setList([...newArray]);
     }else{
      setList([{ID:'bad',post_title:'Aucun resultats pour cette saisie'}]) 
     }
    
    
    }
  },[concession,select])

  function onSelectConcession(id,text,count){
    setConcession(text)
    setConcessionSelected({id:id,concession:text,count:count});
    setSelect(true)
}

function onFocusConcession(e){
 setSelect(false)
}

function goToImport(){
  history.push({pathname:"/leads/chalances/import",state:{concessionnaire:concessionSelected}})
}

  return(
    <div style={{paddingTop:'60px',position:'relative'}}>
      {mobileDimensions > 1200 && <ButtonBack onClick={goBack} styleButton={{position:'absolute',left:'5%',top:'0px'}}/>}
        <div className={styles.title}>
          <h2>Selectionner une concession</h2>
          <div className={styles.line}></div>
        </div>
        <AutoCompleteConcession onFocusConcession={onFocusConcession} onSelectConcession={onSelectConcession} companies={list} onChangeConcession={onChangeConcession} value={concession}/>
        { select &&
          <div style={{display:"flex",justifyContent:'center',alignItems:'center',marginTop:60}}>
              <ButtonText onAction={goToImport} typeColor="lighter" text="valider"/>
        </div>}
    </div>
  )
}