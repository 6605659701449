import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../redux/hooks'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { Fragment, useEffect } from 'react'
import {
  getFirstRowPublication,
  getMorePublication,
  getPublications,
  postLike,
  postLikeFeat,
} from './publicationSlices'
import Layout from '../../layout'
import Loading from '../../components/pgl/Loading'
import styles from './Publications.module.scss'
import PublicationCard from './card-publication'
import Masonry from 'react-masonry-css'
import { createSelector } from '@reduxjs/toolkit'
import ListCategory from './list-category'
import { useQuery } from '../../hooks/useQuery'
import Breadcrump from '../../components/breadcrump/BreadCrump';
//breakpoint column
const breakpointColumn = {
  default: 4,
  1000: 3,
  768: 2,
  600: 1,
}

//Selector permettant de récupérer le type de posts
export const typeOfCard = createSelector(
  [(state) => state.items, (state, categorie: number | string) => categorie],
  (menu, categorie) => {
    return menu.find((item: any) => item.id == categorie)
  }
)

export default function Publications() {
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const childCat = query.get('childCat')
  const dispatch = useDispatch()
  const { checkOutState, posts, error } = useAppSelector((state) => state.publications)
  const { id } = useParams()
  const currentChildCat = childCat ?? id
  const cardType = useAppSelector((state) => typeOfCard(state.navbar, id))

  //Publication Reducer
  const parentPublication = posts[id]
  const publication = parentPublication?.posts[currentChildCat]

  useEffect(() => {
    if (!posts[id]) dispatch(getFirstRowPublication({ parentCat: id, childCat: currentChildCat }))
  }, [currentChildCat, id])

  function onFetchMore() {
    if (publication?.checkoutStateMore === 'READY')
      dispatch(getMorePublication({ parentCat: id, childCat: currentChildCat, page: publication?.page }))
    else return false
  }

  function onLikePublication(val: number | string) {
    dispatch(postLike({ id_post: val, parentCat: id, childCat: currentChildCat }))
  }

  function onChangeCategory(child) {
    if (parentPublication?.checkOutState !== 'LOADING') {
      history.push({
        pathname: location.pathname,
        search: `?childCat=${child}`,
      })
      dispatch(getPublications({ parentCat: id, childCat: child }))
    }
  }

  function onLikeFeat(val: number | string) {
    dispatch(postLikeFeat({ id_post: val, parentCat: id, childCat: currentChildCat }))
  }

  function createTitle(checkOutState, post) {
    if (checkOutState === 'READY' && post && (post as any).breadcrumb && (post as any).breadcrumb.length > 0) {
      const bread = (post as any).breadcrumb
      if (bread.length > 1) {
        return bread[bread.length - 2].nom
      } else {
        return bread[bread.length - 1].nom
      }
    }
    return ''
  }

  return (
    <Layout type={'nofetch'} title={createTitle(checkOutState, parentPublication)}>
      {checkOutState === 'LOADING' ? (
        <Loading />
      ) : checkOutState === 'ERROR' ? (
        <div style={{ padding: '1rem' }}>Aucune publication dans cette rubrique.</div>
      ) : (
        <div className={styles['publications-page']}>
          {parentPublication?.breadcrumb ? (
            <Breadcrump mega_menu={publication?.mega_menu} items={posts[id]?.breadcrumb} />
          ) : null}
          {checkOutState === 'READY' &&
          parentPublication &&
          parentPublication.list_category &&
          parentPublication.list_category.length > 0 ? (
            <ListCategory
              currentCategorie={currentChildCat}
              categories={[{ name: 'Tous les articles', id: id }, ...parentPublication?.list_category]}
              onSelect={onChangeCategory}
            />
          ) : null}
          {parentPublication ? (
            parentPublication?.checkOutState === 'LOADING' ? (
              <Loading />
            ) : parentPublication.checkOutState === 'ERROR' ? (
              <div>Aucune publication dans cette rubrique.</div>
            ) : (
              <Fragment>
                {publication.feat && (
                  <PublicationCard
                    currentLocation={{type:'feat',parentCat:id,childCat:currentChildCat}}
                    id={publication.feat?.id}
                    type={cardType?.screen !== 'pic' ? 'standard' : 'photo'}
                    name="feat"
                    onLikeFeat={onLikeFeat}
                    title={publication.feat?.titre}
                    img={publication.feat?.photo?.url}
                    text={publication.feat?.texte}
                    auteur={publication.feat?.auteur}
                    date={publication.feat?.date}
                    entreprise={publication.feat?.gps?.entreprise?.gp}
                    likes={publication.feat.likes}
                    comments={publication.feat.comments}
                  />
                )}
                {publication && publication?.data.length > 0 ? (
                  <Masonry
                    breakpointCols={breakpointColumn}
                    className={styles['grid-posts']}
                    columnClassName={styles['grid-posts-column']}
                  >
                    {publication?.data.map((pub) => {
                      if (!cardType || !cardType.hasOwnProperty('screen') || cardType.screen !== 'pic') {
                        return (
                          <PublicationCard
                            currentLocation={{type:'post',parentCat:id,childCat:currentChildCat}}
                            onLike={onLikePublication}
                            key={'post' + pub.id}
                            name={'post'}
                            title={pub?.titre}
                            img={pub?.photo?.url}
                            auteur={pub?.auteur}
                            date={pub?.date}
                            entreprise={pub?.gps?.entreprise?.gp}
                            notif={pub?.new}
                            likes={pub?.likes}
                            comments={pub?.comments}
                            status={pub?.status}
                            id={pub?.id}
                            slug={pub?.slug}
                            rub={pub.rub}
                          />
                        )
                      } else {
                        return (
                          <PublicationCard
                            currentLocation={{type:'post',parentCat:id,childCat:currentChildCat}}
                            key={'life' + pub.id}
                            onLike={onLikePublication}
                            id={pub?.id}
                            name={'life'}
                            images={pub?.galerie}
                            new={pub?.new}
                            likes={pub?.likes}
                            comments={pub?.comments}
                            status={pub?.status}
                            slug={pub?.slug}
                          />
                        )
                      }
                    })}
                  </Masonry>
                ) : (
                  <div className={styles['']}>Aucune publication dans cette rubrique.</div>
                )}
                {!publication.last ? (
                  <button onClick={onFetchMore} className={styles['button-more']}>
                    {publication?.checkoutStateMore === 'LOADING'
                      ? 'Chargement ...'
                      : 'Charger plus de publications'}
                  </button>
                ) : null}
              </Fragment>
            )
          ) : null}
        </div>
      )}
    </Layout>
  )
}
