import { CSSProperties, useEffect } from "react"

export default function useChangeSizeStyle(size,isLoad,mobile){
    
function changeSize(isLoad,ratio){
        if(isLoad)
            if(ratio > 1.2)return {h:400,w:600}
            else if(ratio<=1.2 && ratio>=0.8)return {h:400,w:400}
            else return {h:600,w:400}
    
        return {h:400,width:400}   
    }
    
    const styled = {
        '--width-img':mobile ? '100%' : `${changeSize(isLoad,size?.ratio).w}px`,
        '--height-img':`${changeSize(isLoad,size?.ratio).h}px`,
       
    } as CSSProperties


    return styled
}