import { FC, useState, createContext, useContext } from 'react'

interface INavbarPglContext {
  navbarTitle?: string
  setNavbarTitle: (title: string) => void
  withoutNavbar?: boolean
  setWithoutNavbar: (withoutNavbar: boolean) => void
}

const NavbarPglContext = createContext<INavbarPglContext>({
  navbarTitle: undefined,
  setNavbarTitle: () => {},
  withoutNavbar: false,
  setWithoutNavbar: () => {},
})

export const NavbarPglProvider: FC<{ children: JSX.Element }> = ({ children }) => {
  const [navbarTitle, setNavbarTitle] = useState<string>()
  const [withoutNavbar, setWithoutNavbar] = useState<boolean>()

  return (
    <NavbarPglContext.Provider value={{ navbarTitle, setNavbarTitle, withoutNavbar, setWithoutNavbar }}>
      {children}
    </NavbarPglContext.Provider>
  )
}

export const useNavbarPgl = () => useContext(NavbarPglContext)
