import * as React from "react"

const Bad = ({width,height}) => (
  <svg
    height={width}
    width={height}
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <radialGradient
      cx={299.351}
      cy={-389.65}
      gradientTransform="matrix(1 0 0 -1 -281.6 -371.9)"
      gradientUnits="userSpaceOnUse"
      id="a"
      r={32.587}
    >
      <stop
        offset={0}
        style={{
          stopColor: "#bc3f64",
          stopOpacity: 0.95,
        }}
      />
      <stop
        offset={0.299}
        style={{
          stopColor: "#bc3f64",
          stopOpacity: 0.6512,
        }}
      />
      <stop
        offset={0.463}
        style={{
          stopColor: "#bc3f64",
          stopOpacity: 0,
        }}
      />
    </radialGradient>
    <circle cx={17.75} cy={17.75} fill="url(#a)" r={14.208} />
    <linearGradient
      gradientTransform="scale(1 -1) rotate(-45 -354.175 462.692)"
      gradientUnits="userSpaceOnUse"
      id="b"
      x1={235.9}
      x2={256.23}
      y1={396.124}
      y2={375.795}
    >
      <stop
        offset={0}
        style={{
          stopColor: "#f3d59b",
        }}
      />
      <stop
        offset={0.527}
        style={{
          stopColor: "#f85565",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#bc3f64",
        }}
      />
    </linearGradient>
    <path
      d="M26.163 26.165c-5.613 5.612-14.715 5.612-20.327-.001-5.614-5.611-5.615-14.716 0-20.329 5.612-5.614 14.715-5.614 20.327 0 5.616 5.615 5.614 14.715 0 20.33z"
      fill="url(#b)"
    />
    <path
      d="M7.434 11.554a9.684 9.684 0 0 1 5.17-4.589"
      fill="none"
      opacity={0.15}
      stroke="#FFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={5}
    />
    <circle
      cx={10.768}
      cy={14.184}
      fill="#443A22"
      r={1.367}
      stroke="#443A22"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
    />
    <circle
      cx={21.23}
      cy={14.184}
      fill="#443A22"
      r={1.367}
      stroke="#443A22"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
    />
    <path
      fill="none"
      stroke="#443A22"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="m8.631 11.053 4.273 2.763M23.368 11.053l-4.272 2.763M11.109 20.182c1.064-1.213 2.894-1.938 4.89-1.938 1.999 0 3.828.725 4.892 1.941"
    />
  </svg>
)

export default Bad
