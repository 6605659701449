import styles from './Link.module.scss';
import {Link} from 'react-router-dom';
import { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { getColorRgbaByType } from '../helpers';


type EasyLinkProps = {
    to:string | {[key:string]:any},
    className?:string,
    children?:JSX.Element,
    type:string
}

const AnimatedLink = animated(Link);

export default function EasyLink({to,className,children,type}:EasyLinkProps){

    const [hover,setHover] = useState(false)


    const springLink = useSpring({
        color:!hover ? `rgba(${getColorRgbaByType(type)},0.8)`:`rgba(${getColorRgbaByType(type)},1)`
    })

    return(
        <AnimatedLink style={springLink} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} className={`${styles["link-container"]} ${className??''}`} to={to}>
            {children}
        </AnimatedLink>
    )
}