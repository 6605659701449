
  /* --------------------------------------------------------
   *             INSTAGRAM AND FACEBOOK GRAPH APIs
   * --------------------------------------------------------
   */

import { rejects } from "assert";
import { errorMessage } from "../error_message";




  /*
   *LOGIN FB
  */
   export const onLoginFB = () =>{
    return new Promise((resolve)=>{
      window.FB.login(
        (response)=>{
          resolve(response)
        },{
          // Scopes that allow us to publish content to Instagram
          scope: "instagram_basic,pages_show_list,instagram_content_publish",
        }
      )
    })
  }



 export const getFacebookPages = (facebookUserAccessToken) => {
    return new Promise((resolve) => {
      window.FB.api(
        "me/accounts",
        { access_token: facebookUserAccessToken },
        (response) => {
          resolve(response.data);
        }
      );
    });
  };

  export const getInstagramBusinessAccount = (facebookUserAccessToken)=>{
    return new Promise((resolve) => {
      window.FB.api(
        "me/accounts",
        { 
          access_token: facebookUserAccessToken,
          fields:'instagram_business_account{id,name,profile_picture_url}'
        },
        (response) => {
          if(!response.data.hasOwnProperty('error')){
            if(response.data.length === 0){
                resolve({error:errorMessage.facebook.noaccount})
            }
           const list =  response.data.filter(i=>i.hasOwnProperty('instagram_business_account'));
           if(list.length>0){
                resolve(list.map(({instagram_business_account})=>{
                    return {
                            name:instagram_business_account.name,
                            id:instagram_business_account.id,
                            picture_url:instagram_business_account.profile_picture_url,
                            access_token:null,
                        }
                    }))
           }
           else resolve({error:errorMessage.instagram.noaccount})
          }
          resolve({error:errorMessage.global.error_request});
        }
      );
    });
  }

 export const getInstagramAccountId = (facebookUserAccessToken,facebookPageId) => {
    return new Promise((resolve) => {
      window.FB.api(
        facebookPageId,
        {
          access_token: facebookUserAccessToken,
          fields: "instagram_business_account",
        },
        (response) => {
          if(response.hasOwnProperty('instagram_business_account'))
            resolve(response.instagram_business_account.id);
          else resolve(null)
        }
      );
    });
  };




 export const createMediaObjectContainer = (facebookUserAccessToken,instagramAccountId,image) => {
    return new Promise((resolve) => {
      window.FB.api(
        `${instagramAccountId}/media`,
        "POST",
        {
          access_token: facebookUserAccessToken,
          image_url: image.img,
          caption: image.caption,
        },
        (response) => {
          resolve(response);
        }
      );
    });
  };


  export const createReelObjectContainer = (facebookUserAccessToken,instagramAccountId,video,caption) => {
    return new Promise((resolve) => {
      window.FB.api(
        `${instagramAccountId}/media`,
        "POST",
        {
          access_token: facebookUserAccessToken,
          video_url: video,
          media_type:'REELS',
          caption:caption,
        },
        (response) => {
          resolve(response);
        }
      );
    });
  };



  export const createChildrenElementForCaroussel = (facebookUserAccessToken,instagramAccountId,image) => {
    return new Promise((resolve) => {
      window.FB.api(
        `${instagramAccountId}/media`,
        "POST",
        {
          access_token: facebookUserAccessToken,
          image_url: image.img,
          is_carousel_item:true
        },
        (response) => {
          resolve(response.id);
        }
      );
    });
  };



  export const createCarroussel = (facebookUserAccessToken,instagramAccountId,childrenCaroussel,caption) => {
    return new Promise((resolve) => {
      window.FB.api(
        `${instagramAccountId}/media`,
        "POST",
        {
          access_token: facebookUserAccessToken,
          children:childrenCaroussel,
          media_type:'CAROUSEL',
          caption:caption
        },
        (response) => {
          resolve(response);
        }
      );
    });
  };





  export const publishMediaObjectContainer = (
    facebookUserAccessToken,
    instagramAccountId,
    mediaObjectContainerId
  ) => {
    return new Promise((resolve) => {
      window.FB.api(
        `${instagramAccountId}/media_publish`,
        "POST",
        {
          access_token: facebookUserAccessToken,
          creation_id: mediaObjectContainerId,
        },
        (response) => {
          resolve(response.id);
        }
      );
    });
  };


  export const checkStatusUri = (
    facebookUserAccessToken,
    mediaObjectContainerId,
) =>{
  return new Promise((resolve)=>{
      window.FB.api(`${mediaObjectContainerId}`,
    { 
      access_token: facebookUserAccessToken,
      fields:'status_code',
    },
    (response)=>{
      resolve(response)
    }
    )
  })
}

function _wait(n) { return new Promise(resolve => setTimeout(resolve, n)); }

export const isUploadSuccessful = async(retryCount,facebookUserAccessToken,mediaObjectContainerId)=>{
  try{
    if(retryCount > 30) return false;
    const {status_code,error} = await checkStatusUri(facebookUserAccessToken,mediaObjectContainerId);
    if(error) return false
    if(status_code!="FINISHED"){
      await _wait(3000);
      await isUploadSuccessful(retryCount+1,facebookUserAccessToken,mediaObjectContainerId)
    }
    return true

  }catch(error){
    throw error
  }
}