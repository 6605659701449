import styles from './LayoutSocialNetwork.module.scss'
import { CSSProperties, forwardRef, useEffect, useState } from 'react'
import ContainerMedia from '../../../../components/social-network/container-media'
import SocialIcons from '../../../../icons/social-icons'
import { social_helpers } from './helpers'
import { useDispatch, useSelector } from 'react-redux'
import DateIcon from '../../../../icons/DateIcon'
import UserIcon from '../../../../icons/UserIcon'
import ContentEditable from '../../../../components/social-network/content-editable'
import { useMeasure } from '@react-hookz/web'
import ButtonSocial from '../../../../components/social-network/button-social'
import { EDIT_CAPTION_SOCIAL_NETWORK } from '../../../../redux/actions/pci/types'
import HastagList from '../../../../components/social-network/hastag-list'
import ModalPublish from '../../../../components/social-network/modal-publish'
import Modal from '../../../../components/modal'
import { useTransition } from 'react-spring'
import Breadcrumb from '../../../../components/Breadcrumb'
import Breadcrump from '../../../../components/breadcrump/BreadCrump'

type LayoutSocialNetworkProps = {
  children?: JSX.Element | JSX.Element[] | any[]
  onLogin: () => void
  showModalPublish: boolean
  plateform: 'facebook' | 'linkedin' | 'instagram'
  onCloseModalPublish: () => void
  auth_token: string | null
  onPublishContent: (account: any, type: any) => void
  loadingPublication: boolean
  responseMessage: { type: string; message: string; open: boolean }
  onCloseModalResponseMessage: () => void
}

export default function LayoutSocialNetwork({
  children,
  onLogin,
  showModalPublish,
  plateform,
  onCloseModalPublish,
  auth_token,
  onPublishContent,
  loadingPublication,
  responseMessage,
  onCloseModalResponseMessage,
}: LayoutSocialNetworkProps) {
  const [mediaMeasure, ref] = useMeasure<HTMLElement>()

  const dispatch = useDispatch()

  //@ts-ignore next-line
  const { galerie, reseau, titre, date, auteur, texte, breadcrumb } = useSelector((state) => state.social)
  const [isEdit, setIsEdit] = useState(false)

  const type_media = galerie.length == 0 ? 'text' : galerie.length > 1 ? 'caroussel' : 'picture'

  /**
   * VARIABLE CSS FOR CHANGE
   */
  const styled = {
    '--background-header': social_helpers[reseau].color,
  } as CSSProperties

  /**
   * VARIABLE CSS FOR CHANGE WIDTH TEXT AREA BY IMAGE
   */

  const styledTextContent = {
    '--textarea-width': `${mediaMeasure?.width}px`,
  } as CSSProperties

  /**
   * FUNCTION CHANGE CAPTION
   * @param val
   */
  function onChangeCaption(val: string) {
    dispatch({ type: EDIT_CAPTION_SOCIAL_NETWORK, payload: val })
  }

  /**
   * TRANSITION ANIMATION COMPONENT MODAL PUBLISH
   */
  const [transitions, api] = useTransition(showModalPublish, () => ({
    from: { opacity: 0, transform: 'translateY(-40px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-40px)' },
    delay: 200,
  }))

  /**
   * TRANSITION ANIMATION COMPONENT MODAL
   */
  const [transitionsModal, apiModal] = useTransition(responseMessage.open, () => ({
    from: { opacity: 0, transform: 'translateY(-100px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-100px)' },
    delay: 200,
  }))

  const rework_galerie = galerie
    .map((media) => media.url)
    .filter((element, index) => {
      return galerie.map((media) => media.url).indexOf(element) === index
    })

  return (
    <div className={styles['social-container']}>
      <Breadcrump items={breadcrumb} />
      <br />
      <ContainerMedia medias={rework_galerie} ref={ref} type={type_media} />
      <div style={styled} className={styles['header-social']}>
        <div>{social_helpers[reseau].name}</div>
        <SocialIcons name={social_helpers[reseau].id} />
      </div>
      <div className={styles['info-social']}>
        {auteur && auteur.nom ? (
          <div className={styles['text-icon']}>
            <UserIcon />
            {`Publié par ${auteur.nom}`}
          </div>
        ) : null}
        {date ? (
          <div className={styles['text-icon']}>
            <DateIcon />
            {`Le ${date}`}
          </div>
        ) : null}
      </div>
      <div className={styles['title-social']}>{titre}</div>
      <HastagList isEdit={isEdit} />
      <div style={styledTextContent} className={styles['sentence-instagram']}>
        {mediaMeasure?.width && (
          <ContentEditable
            className={styles['content-editable']}
            width={mediaMeasure?.width ?? 600}
            onChange={onChangeCaption}
            sentence={texte ?? ''}
            type={'text'}
            isEdit={isEdit}
          />
        )}
      </div>
      <div className={styles['actions-social']}>
        <ButtonSocial
          type={'line'}
          text="Editer"
          name={!isEdit ? 'edit' : 'notedit'}
          onClick={() => setIsEdit(!isEdit)}
          color={'var(--ui-primary-color)'}
        />

        <ButtonSocial type="plain" text="Publier" name="send" onClick={onLogin} color={'--primary-color'} />
      </div>
      {transitions(
        (style, item) =>
          item && (
            <ModalPublish
              style={style}
              type_content={plateform}
              onClose={onCloseModalPublish}
              id={'publication-social-network'}
              token={auth_token}
              onPublishContent={onPublishContent}
              loadingPublication={loadingPublication}
              type_media={type_media}
            />
          )
      )}
      {transitionsModal(
        (style, item) =>
          item && (
            <Modal
              style={style}
              id={responseMessage.type}
              onClose={onCloseModalResponseMessage}
              message={responseMessage?.message}
              type={responseMessage.type}
            />
          )
      )}
    </div>
  )
}
