import styles from './ErrorApp.module.scss';


export default function ErrorApp({error}){
    return(
        <div className={styles['error-app-container']}>
            <div className={styles["error-wrapper"]}>
                <div className={styles["error-message"]}>Cette page n'est pas accessible</div>
                <div className={styles["error-sub-message"]}>Si le problème persiste, contactez un administrateur.</div>
                <div  className={styles["error-code"]}>{`HTTP ERROR ${error.status}`}</div>
            </div>
        </div>
    )
}