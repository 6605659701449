import Portal from '../../../components/common/portal/Portal'
import styles from './PostStandardMobile.module.scss'
import { useHistory } from 'react-router-dom'
import Icon from '../../../icons'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { Standard } from '../postStandardSlice'
import CarousselSwiper from '../../../components/caroussel-swiper'
import ReactHtmlParser from 'react-html-parser'
import { CSSProperties, forwardRef, Fragment, useEffect, useState } from 'react'
import { useClickAway } from 'react-use'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import VisioPicture from '../../../components/visio-picture'
import LikeList from '../like-list/LikeList'
import { transformFactory } from '../../../utils/html_helpers'

type PostStandardMobileProps = {
  handleLife: () => void
  comment: string
  onSendComment: (type: string) => void
  handleComment: (e) => void
  handleKeyDownComments: any
}

export default forwardRef(function PostStandardMobile(
  { handleLife, comment, onSendComment, handleComment, handleKeyDownComments }: PostStandardMobileProps,
  ref: any
) {
  const history = useHistory()
  const { breadcrumb, galerie, titre, texte, auteur, date, coms, likes, docs, url } = useAppSelector(
    (state) => state.standard.post
  ) as Standard

  useEffect(() => {
    // Vérifier si la barre d'adresse est en bas
    if (window.visualViewport && window.visualViewport.pageTop !== 0) {
      console.log("La barre d'adresse est en bas.")
    } else {
      console.log("La barre d'adresse n'est pas en bas.")
    }
  }, [])

  const profil = useAppSelector((state) => state.auth.authentication.profil)
  const isModerateur = profil !== 'adherent'
  const [showLiker, setShowLiker] = useState(false)
  const [imgFullScreen, setImagFullScreen] = useState<false | number>(false)
  const transform = transformFactory(styles)

  useClickAway(ref, () => {
    if (ref.current) setImagFullScreen(false)
  })

  function onShowMedia(val: number) {
    setImagFullScreen(val)
  }
  useEffect(() => {
    disableBodyScroll(ref.current)
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  function onViewLiker() {
    setShowLiker(true)
  }

  const isIphone = /iPhone/i.test(navigator.userAgent)
  const viewPortIsDown = window.visualViewport && window.visualViewport.pageTop !== 0

  const styledComment = {
    '--comment-wrapper-border-bottom': viewPortIsDown ? '1rem' : '1rem',
    '--comment-wrapper-height': viewPortIsDown ? '4rem' : '4rem',
    '--comment-wrapper-position': 'fixed',
  } as CSSProperties
  //isIphone ? 'fixed' : 'sticky'
  //isIphone ? '3rem' : '0rem'
  const styledModal = {
    '--modal-padding-bottom':
      /iPhone|iPod/.test(navigator.userAgent) && /Safari/.test(navigator.userAgent) ? '150px' : '4rem',
  } as CSSProperties

  const number_of_comments = coms && coms.length > 0 ? coms.filter((i) => i.status == 'approved').length : '0'

  return (
    <Fragment>
      <Portal id="modal-post-standard">
        <div style={styledModal} ref={ref} className={styles['background-modal']}>
          <div className={styles['header-standard']}>
            <button onClick={() => history.goBack()} className={styles['back-life']}>
              <Icon name="chevronleft" />
            </button>
            <div className={styles['title-post']}>{breadcrumb[0].nom}</div>
            <button onClick={() => history.push('/')} className={styles['back-life']}>
              <Icon name="house" />
            </button>
          </div>
          <div className={styles['content-standard']}>
            {galerie && galerie.length > 0 && (
              <div className={styles['galerie-standard']}>
                {galerie.length > 1 ? (
                  <CarousselSwiper type="cover" medias={galerie.map((i) => i.url)} onClick={onShowMedia} />
                ) : (
                  <img
                    onClick={() => onShowMedia(0)}
                    className={styles['image-galerie-standard']}
                    src={galerie[0].url}
                  />
                )}
              </div>
            )}
            <div className={styles['content']}>
              {/*<div className={styles["life-categorie"]}>{breadcrumb[0].nom}</div>*/}
              <div className={styles['title-life']}>{titre}</div>
              <div className={styles['description-life']}>{ReactHtmlParser(texte, { transform })}</div>
              {auteur?.nom ? (
                <div className={styles['author-life']}>
                  <span className={styles['author-life-prefix']}>Publié par </span>
                  <span className={styles['author-life-name']}>{auteur.nom}</span>
                </div>
              ) : null}
              {date ? (
                <div className={styles['date-life']}>
                  <span className={styles['date-life-prefix']}>Le </span>
                  <span className={styles['date-life-datetime']}>{date}</span>
                </div>
              ) : null}
              {likes && coms && (
                <div className={styles['actions-life']}>
                  <div onClick={handleLife} className={styles['like-life']}>
                    <Icon name={likes?.me ? 'like-plain' : 'like'} />
                    <span>{likes?.count}</span>
                  </div>
                  <div className={styles['actions-separation']} />
                  <div className={styles['comments-life']}>
                    <Icon name="comments" />
                    <span>{number_of_comments}</span>
                  </div>
                </div>
              )}
              {likes && Object.keys(likes?.list).length > 0 ? (
                <div className={styles['likes-persons-life']}>
                  <span>Aimé par </span>
                  {Object.keys(likes?.list)
                    .slice(0, 2)
                    .map((item) => {
                      return (
                        <span onClick={() => history.push(`/user/${item}`)} className={styles['user-like-life']}>
                          <span>{likes.list[item].firstname}</span>
                          <span>{likes.list[item].lastname}</span>
                        </span>
                      )
                    })}
                  {likes.count >= 3 ? (
                    <span className={styles['more-viewer']} onClick={onViewLiker}>{` et par ${
                      likes.count - 2
                    } autre(s) personne(s)`}</span>
                  ) : null}
                </div>
              ) : null}
              {url && (
                <div className={styles['link-part']}>
                  <div className={styles['title-link']}>Lien :</div>
                  <a className={styles['link']} href={url}>
                    <span className={styles['name-link']}>{url}</span>
                    <span className={styles['icon-link']}>
                      <Icon name="globe-2" />
                    </span>
                  </a>
                </div>
              )}
              {docs && docs.length > 0 && (
                <div className={styles['list-documents']}>
                  <div className={styles['title-Document']}>Liste des Documents : </div>
                  <div className={styles['documents-wrapper']}>
                    {docs.map((document) => {
                      return (
                        <a className={styles['document']} href={document.file}>
                          <span className={styles['name-doc']}>{document.name}</span>
                          <span className={styles['icon-doc']}>
                            <Icon name="download-2" />
                          </span>
                        </a>
                      )
                    })}
                  </div>
                </div>
              )}
              {coms && coms.length > 0 ? (
                <div className={styles['comments-container']}>
                  {coms.map((com) => {
                    const styled = {
                      '--border-bottom-comment': com.status === 'hold' ? '0px' : '0.4rem',
                    } as CSSProperties
                    return (
                      <div>
                        <div style={styled} className={styles['comment-container-life']}>
                          <div className={styles['comment-user-life']}>
                            <span>{com.user}</span>
                          </div>
                          <div className={styles['comment-date-life']}>
                            <span>Le {com?.date}</span>
                          </div>
                          <div className={styles['comment-content-life']}>{com.content}</div>
                        </div>
                        {com.status == 'hold' ? (
                          <div className={styles['comment-statut']}>
                            {isModerateur ? 'A modérer' : 'En cours de modération'}
                          </div>
                        ) : null}
                      </div>
                    )
                  })}
                </div>
              ) : null}
            </div>
          </div>
          {coms !== undefined && (
            <div style={styledComment} className={styles['comment-editor-wrapper-life']}>
              <textarea
                onKeyDown={(e) => handleKeyDownComments(e, 'mobile')}
                value={comment}
                onChange={handleComment}
                placeholder="Ajouter un commentaire"
              />
              <button onClick={() => onSendComment('mobile')} className={styles['button-send-comments-life']}>
                <Icon name="send" />
              </button>
            </div>
          )}
        </div>
      </Portal>
      {typeof imgFullScreen === 'number' ? (
        <VisioPicture onClose={() => setImagFullScreen(false)} media={galerie[imgFullScreen as number].url} />
      ) : null}
      {showLiker && <LikeList list={likes.list} onHide={() => setShowLiker(false)} />}
    </Fragment>
  )
})
