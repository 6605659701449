import { ChangeEventHandler,CSSProperties,useRef,useState } from 'react';
import { useSpring,animated } from 'react-spring';
import Icon from '../../../icons';
import EasyButton from '../../button';
import styles from './InputPassword.module.scss';

type InputStandardProps = {
    value:string,
    type:string,
    name:string,
    id:string,
    label:string,
    placeholder:string,
    icon?:JSX.Element,
    onKeyHandler?:()=>void,
    onchange:ChangeEventHandler<HTMLInputElement>
}

export default function InputPassword({value,type="password",label,name,id,icon,placeholder,onchange,onKeyHandler}:InputStandardProps):JSX.Element{

    const [focus,setFocus] = useState<boolean>(false);
    const [show,setShow] = useState<boolean>(type === 'password' ?false : true);
    const refButton = useRef(null);


    const inputSpring = useSpring({
        value: focus ? 'var(--primary-color)' : 'var(--grey-color)',
        label:focus ? 'var(--primary-color)' : 'var(--grey-color)',
    
})

        const styled = {
        wrapper:{ 
            '--border-easy-input':inputSpring.value,
            '--icon-color-easy-input':inputSpring.value
        } as CSSProperties
        ,
        label:{ 
            '--label-color-easy-input':inputSpring.label,
            '--label-weight-easy-input':focus ? '600' : '300'
        } as CSSProperties
        }

    const dynamicType = show ? 'text' : 'password'


    function onBlur(e){
      if(e.relatedTarget == refButton.current){
        setShow(prev => !prev)
      }
    }
    
    return(
        <div className={styles["input-standard-container"]}>
            <animated.label style={styled.label} htmlFor={id}>{label}</animated.label>
            <animated.div style={styled.wrapper} className={styles["input-wrapper"]}>
                <input onBlurCapture={onBlur} onKeyUp={onKeyHandler} placeholder={placeholder} onFocus={()=>setFocus(true)} onBlur={()=>setFocus(false)} onChange={onchange} value={value} type={dynamicType} name={name} id={id}/>
                <EasyButton 
                    ref={refButton}
                    type='primary'
                    icon={show ? ({ style }) => <Icon style={style} name="password_hide" /> : ({ style }) => <Icon style={style} name="password_show" />}
                    onClick={() => setShow(prev => !prev)} name={"icon"}/>
            </animated.div>
        </div>  
    )
}