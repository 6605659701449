export  function isBrowser(){

    let browserName;
    if(navigator.userAgent.match(/chrome|chromium|crios/i)){
        browserName = "IS_CHROME";
      }else if(navigator.userAgent.match(/firefox|fxios/i)){
        browserName = "IS_FIREFOX";
      }  else if(navigator.userAgent.match(/safari/i)){
        browserName = "IS_SAFARI";
      }else{
        browserName="IS_INTERNET";
      }
      return browserName
    
}



export const isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};
