import ProtectedPage from "./protected-page";
import ProtectedModal from "./protected-modal";


const Components = {
    page:ProtectedPage,
    modal:ProtectedModal
}

export default function Protected({name,...props}):JSX.Element | null{
    let Component = Components[name]

    if(Component !== undefined){
        return <Component {...props} />
    }
    return null

}