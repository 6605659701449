const ErrorIcon = ({fill,height,width}) => (
    <svg
      height={height}
      width={width}
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
      d="M12.012 2.25c.734.008 1.465.093 2.181.253a.75.75 0 0 1 .582.649l.17 1.527a1.384 1.384 0 0 0 1.928 1.116l1.4-.615a.75.75 0 0 1 .85.174 9.792 9.792 0 0 1 2.204 3.792.75.75 0 0 1-.271.825l-1.242.916a1.381 1.381 0 0 0 .001 2.226l1.243.915a.75.75 0 0 1 .271.826 9.797 9.797 0 0 1-2.203 3.792.75.75 0 0 1-.849.175l-1.406-.617a1.38 1.38 0 0 0-1.926 1.114l-.17 1.526a.75.75 0 0 1-.571.647 9.518 9.518 0 0 1-4.406 0 .75.75 0 0 1-.572-.647l-.17-1.524a1.382 1.382 0 0 0-1.924-1.11l-1.407.616a.75.75 0 0 1-.849-.175 9.798 9.798 0 0 1-2.203-3.796.75.75 0 0 1 .271-.826l1.244-.916a1.38 1.38 0 0 0 0-2.226l-1.243-.914a.75.75 0 0 1-.272-.826 9.793 9.793 0 0 1 2.205-3.792.75.75 0 0 1 .849-.174l1.4.615a1.387 1.387 0 0 0 1.93-1.118l.17-1.526a.75.75 0 0 1 .583-.65c.718-.159 1.45-.243 2.202-.252Zm0 1.5a9.135 9.135 0 0 0-1.355.117l-.109.977A2.886 2.886 0 0 1 6.524 7.17l-.898-.394a8.293 8.293 0 0 0-1.348 2.317l.798.587a2.881 2.881 0 0 1 0 4.643l-.798.588c.32.842.775 1.626 1.348 2.322l.905-.397a2.882 2.882 0 0 1 4.016 2.318l.11.984c.89.15 1.798.15 2.688 0l.109-.984a2.881 2.881 0 0 1 4.018-2.322l.905.396a8.296 8.296 0 0 0 1.347-2.318l-.798-.588a2.881 2.881 0 0 1 0-4.643l.796-.587a8.293 8.293 0 0 0-1.348-2.317l-.896.393a2.884 2.884 0 0 1-4.023-2.324l-.11-.976a8.988 8.988 0 0 0-1.333-.117ZM13.575 8a.3.3 0 0 1 .19.067l.047.049 2.778 3.577a.5.5 0 0 1 .052.532l-.052.082-2.778 3.577a.3.3 0 0 1-.169.108l-.068.008h-2.704a.3.3 0 0 1-.272-.427l.036-.059L13.408 12l-3.105-4h3.272Zm-3.9.804 1.69 2.201-.78.995.638.813a.3.3 0 0 1 0 .372l-1.337 1.68a.3.3 0 0 1-.422.048l-.049-.05-2.002-2.555a.5.5 0 0 1 0-.616l2.263-2.888Z"
      fill={fill}
    />
    </svg>
  )
  
  export default ErrorIcon
  