import { INIT_PAGE_FLUX,GET_FLUX } from "../../actions/pci/types";

const initialState = {
  listFlux:[],
};



export default function fluxReducers(state = initialState, action) {
  switch (action.type) {
    case INIT_PAGE_FLUX:
    /*On initialise le flux via les infos du menu à recuperer
    * On prepare le terrain pour chaque flux ac un object comportant l'id du flux
    */

      const initFlux = action.payload
      //Ici on filtre l'ensemble des donnée qui possede le type=="rub"
      .filter((item)=>{
        return item.type == "rub"
      //Enfin on creer un tableau d'object ac l'id deja preparé
      })
      .map(item=>{
          return{
              id:item.id,
              page:0,
              data:[],
              last:false,
              breadcrumb:false,
              mega_menu:false,
              r:''
            }
      })
    
      return{
        ...state,
        listFlux:initFlux
      }

    case GET_FLUX:
    console.log(state)
    const index = [...state.listFlux].findIndex((item)=>{ return action.payload.id == item.id});
    const addflux = {
      id:action.payload.id,
      page:action.payload.page,
      data:action.payload.data,
      last:action.payload.last,
      parent:action.payload.parent,
      breadcrumb:action.payload.breadcrumb,
      mega_menu:action.payload.mega_menu,
      r:''
    }
    //Update my flux
    const newFlux = [...state.listFlux.slice(0,index),addflux,...state.listFlux.slice(index+1)];

  
    return{
      ...state,
      listFlux:newFlux,
  
   }
  default:
      return state;
  }



}
