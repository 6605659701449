import Layout from '../../layout'
import styles from './PostStandard.module.scss'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import Portal from '../../components/common/portal/Portal'
import { useMedia } from 'react-use'
import PostStandardMobile from './mobile'
import PostStandardDesktop from './desktop'
import { getPostStandard, postLike, postComment } from './postStandardSlice'
import { useParams,useLocation } from 'react-router-dom'
import Loading from '../../components/pgl/Loading'
export default function PostStandard() {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const isMobile = useMedia('(max-width:768px)')
  const refMobile = useRef<HTMLDivElement>(null)

  //state
  const postStandard = useAppSelector((state) => state.standard.post)
  const checkOutState = useAppSelector((state) => state.standard.checkOutState);
  const checkOutComsState = useAppSelector((state) => state.standard.checkOutComsState);
  const location = useLocation();
  const [comment, setComment] = useState('');

  useEffect(() => {
    dispatch(getPostStandard({ id_post: id }))
  }, [])

  function handleComment(e: ChangeEvent<HTMLTextAreaElement>) {
    setComment(e.target.value)
  }

  function handleKeyDownComments(e,type){
    if(e.keyCode == 13 && checkOutComsState !='LOADING' ){
        e.preventDefault();
        onSendComment(type)
    }
}

  function scrollToBottom(element) {
    const myDiv = element
    if (myDiv) {
      const scrollHeight = myDiv.scrollHeight
      const scrollTop = myDiv.scrollTop
      const difference = scrollHeight - scrollTop
      const perTick = difference / 10

      setTimeout(() => {
        myDiv.scrollTop = myDiv.scrollTop + perTick

        if (Math.abs(myDiv.scrollTop + myDiv.clientHeight - scrollHeight) <= 1) {
          return
        }

        scrollToBottom(element)
      }, 10)
    }
  }

  async function onSendComment(type) {
    if (comment.length > 0 && checkOutComsState !='LOADING' && comment.trim() !== '')
      dispatch(postComment({ com: comment, id_post: id,lastContent:location?.state?.lastContent }))
        //@ts-ignore
        .unwrap()
        .then(() => {
          setComment('')
          //Desktop
          if (type == 'desktop')
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: 'smooth',
            })
          else {
            //scrollToBottom(refMobile.current)
            if(refMobile.current){
            const scrollableDiv = refMobile.current;
            scrollableDiv.scrollTo({
              top: scrollableDiv.scrollHeight,
              behavior: "smooth"
            });
            }
          }
        })
  }

  console.log(location)
  function handleLife() {
    dispatch(postLike({ id_post: id,lastContent:location?.state?.lastContent }))
  }

  function createTitle(checkOutState,post){
    if(checkOutState === 'READY' && post && (post as any).breadcrumb && (post as any).breadcrumb.length > 0){
      const bread = (post as any).breadcrumb
      if(bread.length > 1){
          return bread[bread.length-2].nom
      }
      else{
        return bread[bread.length-1].nom
      }
    }
    return ''
  }
  return (
    <Layout has_back={true} title={createTitle(checkOutState,postStandard)} type={'nofetch'}>
      {checkOutState === 'READY' ? (
        isMobile ? (
          <PostStandardMobile
          handleKeyDownComments={handleKeyDownComments}
            ref={refMobile}
            handleComment={handleComment}
            handleLife={handleLife}
            onSendComment={onSendComment}
            comment={comment}
          />
        ) : (
          <PostStandardDesktop
           handleKeyDownComments={handleKeyDownComments}
            comment={comment}
            handleComment={handleComment}
            handleLife={handleLife}
            onSendComment={onSendComment}
          />
        )
      ) : checkOutState == 'LOADING' ? (
        <Loading />
      ) : null}
    </Layout>
  )
}
