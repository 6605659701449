import BackButtonPage from '../../components/back-button/BackButtonPage'
import Navbar from '../../components/Navbar'
import ShoppingBag from '../../components/shop/ShoppingBag'
import styles from './NoFetchLayout.module.scss';
import {useHistory} from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';



type ClassicLayoutProps = {
    title:string,
    fetchData?:any,
    has_back:boolean,
    breadcrumb:any,
    children:JSX.Element | JSX.Element[],
    errorText:string | boolean
}

export default function NotFetchLayout({
    title,
    has_back,
    breadcrumb,
    children
    }:ClassicLayoutProps):JSX.Element{

    const history = useHistory()
    
    return(
        <div className={styles["page-container"]}>
              <Navbar title={title} />
              {has_back ? <BackButtonPage classNames={styles["arrowbutton"]}/> : null}
              <ShoppingBag from_global_app={true} />
              {breadcrumb ? <Breadcrumb items={breadcrumb} /> : null}
              {children}
        </div>
    )
}