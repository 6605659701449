

export default function VekaCa(props){
    return(
<svg version="1.1" id="ca" x="0px" y="0px"
	 viewBox="0 0 18 26">
<path d="M3.1,11.5C3.3,10.7,3.6,9.9,4,9.2c0.4-0.7,0.9-1.4,1.6-2c0.6-0.6,1.4-1,2.2-1.4c0.8-0.3,1.8-0.5,2.8-0.5
	c1.4,0,2.6,0.3,3.7,0.9c1,0.6,1.8,1.5,2.3,2.5L14.5,10c-0.2-0.5-0.5-0.9-0.8-1.2c-0.3-0.3-0.6-0.6-1-0.8c-0.4-0.2-0.7-0.3-1.1-0.4
	c-0.4-0.1-0.8-0.1-1.1-0.1c-0.6,0-1.2,0.1-1.8,0.3c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.7,0.8-1,1.3c-0.3,0.5-0.5,1-0.6,1.5H12
	l-0.5,1.4h-6v0.3c0,0.3,0,0.6,0.1,0.9h5.7l-0.6,1.4H5.9c0.2,0.5,0.4,1,0.7,1.4c0.3,0.4,0.6,0.8,1,1.1c0.4,0.3,0.8,0.5,1.3,0.7
	c0.5,0.2,1,0.3,1.5,0.3c0.4,0,0.8,0,1.2-0.1c0.4-0.1,0.8-0.2,1.2-0.5c0.4-0.2,0.7-0.5,1-0.8c0.3-0.3,0.6-0.7,0.8-1.2l2,1.2
	c-0.2,0.6-0.6,1.1-1.1,1.6c-0.5,0.4-1,0.8-1.5,1.1c-0.6,0.3-1.2,0.5-1.8,0.7c-0.6,0.2-1.3,0.2-1.9,0.2c-0.9,0-1.7-0.2-2.5-0.5
	c-0.8-0.3-1.4-0.7-2.1-1.2c-0.6-0.5-1.1-1.1-1.6-1.8c-0.4-0.7-0.8-1.4-1-2.2H1l0.6-1.4H3c0-0.3-0.1-0.7-0.1-1v-0.2H1.4l0.6-1.4H3.1z
	"/>
</svg>
    )
}