import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage,isSupported } from 'firebase/messaging';
import config from '../config';


var firebaseConfig = {
    apiKey: "AIzaSyCYRIapQ3DlhBzztFt_Gh0DmWEjyUHDG24",
    authDomain: "pci-test-40faa.firebaseapp.com",
    projectId: "pci-test-40faa",
    storageBucket: "pci-test-40faa.appspot.com",
    messagingSenderId: "47461053931",
    appId: "1:47461053931:web:d5c4ec0416ebc2560b55ae"
};



const firebaseApp = initializeApp(config.firebase_configuration);
let messaging = null;


if ('serviceWorker' in navigator && isSupported()) {
    messaging = getMessaging(firebaseApp);
}else{
   console.log("les service-worker ne sont pas supporté")
}
  

export async function fetchToken(){
    try{
        const status = await Notification.requestPermission();
        if (status && status === 'granted') {
            const fcm_token = await getToken(messaging, {vapidKey: config.firebase_public_key })
            if(fcm_token){
                return {token_fcm:fcm_token,isFound:true}
            }else{
                return {token_fcm:'no-notif',isFound:true}
            }
        }else{
            return {token_fcm:'no-notif',isFound:true}  
        }


    }catch(error){
        return {token_fcm:'no-notif',isFound:true}
    }
}


export const onMessageListener = () =>{
 
  return new Promise((resolve) => {    
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
}