import LayoutSocial from '../layout-social';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { createFeedWithoutMedia, onLoginFB } from '../../../../components/social-network/api/facebook-api';
import { createFeedWithMedia, createMediaObjectContainerFacebook } from '../../../../components/social-network/api/facebook-api';
import useDidMountEffect from '../../../../hooks/useDidmountEffect';
import { useInitFbSDK } from '../../../../hooks/fb-hooks';
import { errorMessage } from '../../../../components/social-network/error_message';
import { trackingSocialPublication } from '../../../../redux/actions/pci';
import { useAppSelector } from '../../../../redux/hooks';



export default function FacebookPage(){
    useInitFbSDK();
    const [facebookAccessToken,setFacebookAccessToken] = useState(null)
    const [showModalPublish,setShowModalPublish] = useState(false);
    const [fbPublishMessage,setFbPublishMessage] = useState<any>({type:'',message:'',open:false});
    const [loadingPublication,setLoadingPublication] = useState<boolean>(false)
    const dispatch = useDispatch();
    const post = useAppSelector(state=>state.social);
    const {nonce,id} = useAppSelector(state=>state.auth.authentication);
    const text = post.texte ?  post.tags ? `${post.texte}\n ${post.tags.map(i=>{if(!i.startsWith('#'))return `#${i}`;return i}).join(' ')}` : post.texte : post.texte
    
    
    
    /*
    ** LOGIN FACBEOOK
    */
    async function onLogin(){
        try{
          const {authResponse} =  await onLoginFB();
              if(authResponse.accessToken){
                  setFacebookAccessToken(authResponse.accessToken);
                  setShowModalPublish(true)
              }
              
          }catch(error){
              setFbPublishMessage({type:'error',message:errorMessage.facebook.authentication,open:true})  
        
          }
      }




      /**
       * PUBLISH WITH PICTURE
       */

      async function onPublishWithPicture(account){
        setLoadingPublication(true);
             //Create all children element's caroussel
             const media_list = post?.galerie.map(i=>i.url).map( async (element)=>{
                return  await createMediaObjectContainerFacebook(facebookAccessToken,account,{img:element});
            });

            const mediaList0bject = await Promise.all(media_list);

        
            if(mediaList0bject.includes(undefined)){
                setLoadingPublication(false);
                setShowModalPublish(false)
                return setFbPublishMessage({type:"error",message:errorMessage.facebook.error_image,open:true})
            }
            const {id:mediaObjectContainerId,error:errorContainerCaroussel} =  await createFeedWithMedia(account.access_token,account.id,mediaList0bject,text);
            if(errorContainerCaroussel){
                setLoadingPublication(false);
                setShowModalPublish(false)
               return setFbPublishMessage({type:"error",message:errorMessage.facebook.error_publication,open:true}) 
            }

           else if(mediaObjectContainerId){
                setLoadingPublication(false);
                setShowModalPublish(false);
                dispatch(trackingSocialPublication(id,nonce,post.id))
                return setFbPublishMessage({type:"success",message:errorMessage.facebook.succes_publication,open:true})
           }
           else{
                setLoadingPublication(false);
                setShowModalPublish(false)
            return setFbPublishMessage({type:"error",message:errorMessage.facebook.error_publication,open:true}) 
           } 
      }


      /**
       * PUBLISH CONTENT WITH TEXT
       * 
       */
       async function onPublishContentWithoutMedia(account){
            setLoadingPublication(true);
        
            const {id:mediaObjectContainerId,error:errorContainerCaroussel} =  await createFeedWithoutMedia(account.access_token,account.id,text);
            if(errorContainerCaroussel){
                setLoadingPublication(false);
                setShowModalPublish(false)
               return setFbPublishMessage({type:"error",message:errorMessage.facebook.error_publication,open:true}) 
            }

           else if(mediaObjectContainerId){
                setLoadingPublication(false);
                setShowModalPublish(false)
                dispatch(trackingSocialPublication(id,nonce,post.id))
                return setFbPublishMessage({type:"success",message:errorMessage.facebook.succes_publication,open:true})
           }
           else{
                setLoadingPublication(false);
                setShowModalPublish(false)
            return setFbPublishMessage({type:"error",message:errorMessage.facebook.error_publication,open:true}) 
           } 
      }

      /**
       * PUBLISH CONTENT
       * 
       */
      
      function onPublishContent(account,type){
        if(type === 'caroussel' || type === 'picture' ){
            onPublishWithPicture(account)
        }
        else{
            onPublishContentWithoutMedia(account)
        }

      }

    
      useDidMountEffect(()=>{
        if(!fbPublishMessage){
            //@ts-ignore next-line
            window.FB.logout(() => {
                setFacebookAccessToken(null);
              });
        }
    },[fbPublishMessage])
    

    return(
        <LayoutSocial 
            onLogin={onLogin} 
            showModalPublish={showModalPublish} 
            plateform={"facebook"} 
            onCloseModalPublish={()=>setShowModalPublish(false)} 
            auth_token={facebookAccessToken} 
            onPublishContent={onPublishContent} 
            loadingPublication={loadingPublication}
            responseMessage={fbPublishMessage}
            onCloseModalResponseMessage={() => setFbPublishMessage((prev)=>{return {...prev,open:false}})}  />
    )
}
