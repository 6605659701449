import { forwardRef } from "react"
import { animated } from "react-spring";
import AlertIcon from "../../../../icons/AlertIcon";
import SuccesIcon from "../../../../icons/SuccesIcon";
import styles from './ModalSuccess.module.scss';


type ModalSuccessProps = {
    message:string,
    onClose
}

 function ModalSuccess({message,onClose,style},ref){
    return(
        <animated.div style={style} ref={ref} className={styles["modal-error"]}>
            <div className={styles["bg-modal"]}></div>
            <div className={styles["logo-modal"]}>
                <SuccesIcon/>
            </div>
            <div className={styles["modal-content"]}>
                <div className={styles["modal-title"]}>Succès !</div>
                <div className={styles["modal-message"]}><span>{message}</span></div>
                <button onClick={onClose} className={styles["button-close"]}>Fermer</button>
            </div>
        </animated.div>
    )
}

export default forwardRef(ModalSuccess)