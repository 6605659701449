import styles from './LifeCard.module.scss'
import { Link, useLocation, useHistory } from 'react-router-dom'
import Icon from '../../../../icons'
import { animated, useSpring } from 'react-spring'
import { BindHover } from '../../../../bind-event/bindHover'
import { MouseEventHandler, SyntheticEvent } from 'react'

type LikeCardProps = {
  images: Array<{ h: number; w: number; url: string }>
  likes: { me: boolean; count: number }
  comments: { approved: number; hold: number }
  new: boolean
  status: string
  id: string | number
  slug: string
  onLike: (val: string | number) => void,
  currentLocation:{type:'post',parentCat:string,childCat:string}
}

export default function LifeCard({
  id,
  images,
  likes,
  comments,
  new: notification,
  status,
  slug,
  onLike,
  currentLocation
}: LikeCardProps) {
  const history = useHistory()
  /**
   * spring scaling
   */
  const [spring, api] = useSpring(() => ({
    transform: 'scale(1.0)',
  }))
  /**
   * Hover animation scaling
   */
  function handleHover(e: SyntheticEvent<Element, Event>) {
    api.start({
      transform: `scale(${e.type === 'mouseenter' ? '1.2' : '1.0'})`,
    })
  }
  /**
   * Event Scaling
   */
  const bind = BindHover(handleHover)

  const location = useLocation()

  function goTo() {
    history.push({ pathname: `/photo/${id}`, state: { background: location,lastContent:currentLocation } })
  }

  return (
    <div onClick={goTo} className={styles['life-card-wrapper']}>
      {images.length > 1 && (
        <div className={styles['icons-multi-image']}>
          <Icon name="image-multi" />
        </div>
      )}
      <div {...bind()} className={styles['life-card-container']}>
        {(likes || comments) && (
          <div className={styles['overlay']}>
            <div className={styles['life-actions']}>
              {likes && (
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                    onLike(id)
                  }}
                  className={styles['life-like']}
                >
                  <Icon name={likes.me ? 'like-plain' : 'like'} />
                  <span>{likes.count ?? '0'}</span>
                </div>
              )}
              {comments && (
                <div className={styles['life-comments']}>
                  <Icon name="comments" />
                  <span>
                    {
                      //@ts-ignore
                      comments?.approved ?? '0'
                    }
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
        {images.length > 0 ? (
          <animated.img loading="lazy" style={spring} className={styles['life-image-alone']} src={images[0].url} />
        ) : null}
      </div>
    </div>
  )
}
