import React from 'react';

export const Corporama = (props) => {
  return (
<svg version="1.1" id="Icon_ionic-ios-megaphone" 
	 x="0px" y="0px" viewBox="0 0 15.9 15.9" >
<path id="Tracé_228" d="M1.6,5.4c0-1.2,0.6-2.4,1.7-3.1c0.1,0,0.1-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1l0,0C1.8,2.2,0.5,3.3,0.4,4.8
	C0.1,4.9,0,5.1,0,5.4C0,5.7,0.2,5.9,0.4,6c0.3,1.2,1.2,2.2,2.4,2.5l0,0c0.1,0,0.2,0,0.2-0.1C3,8.3,3,8.2,3,8.2
	C2.2,7.5,1.7,6.5,1.6,5.4z"/>
<path id="Tracé_229" d="M15.4,2.1c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.5-0.5-0.9-0.8-1.2C14.1,0.2,13.6,0,13.1,0c-0.4,0-0.7,0.1-1,0.3
	c-1.8,1-3.8,1.6-5.9,1.8c-1.6,0-1.5,0-1.5,0C3,2.9,2.2,5,3,6.8C3.3,7.5,3.8,8,4.4,8.4c0.5,0.4,0.9,0.3,0.8,0.8s-0.9,3.7-1,4.4
	S4.1,14.7,4.4,15C5.1,15.5,6,15.8,7,15.9c0.7,0,0.5-0.3,0.5-1.7s-0.4-5,0.6-5c1.8,0,2.8,0.7,4.2,1.2c0.2,0.1,0.5,0.2,0.8,0.2
	c0.5,0,1-0.3,1.3-0.7c0.4-0.4,0.6-0.8,0.8-1.2c0-0.1,0.1-0.2,0.1-0.3c0.4-1,0.5-2.1,0.5-3.1C15.9,4.2,15.8,3.1,15.4,2.1z M14.4,8.1
	c-0.2,0.6-0.7,1.1-1,1.1s-0.7-0.5-0.9-1.1c-0.3-0.9-0.5-1.9-0.5-2.9c0-1,0.1-1.9,0.5-2.9c0.2-0.6,0.6-1,0.9-1s0.8,0.4,1,1
	C15.1,4.2,15.1,6.3,14.4,8.1L14.4,8.1z"/>
</svg>
  );
};
