export const errorMessage = {
   /* global:{
      error_request:'Une erreur est survenu veuillez réessayer',
      no_select_account:"Veuillez sélectionner la page ou le compte sur lequels vous voulez publier",
    
    },
    instagram:{
        noaccount:"Vous n'avez pas de page instagram business relié à votre page facebook",
        authentication:"Une Erreur est survenue lors de la tentative de connection à instagram",
        error_img_caroussel:"Une image de votre caroussel n'est pas accepté par instagram",
        no_select_account:"Veuillez sélectionner la page instagram sur laquelle vous voulez publier",
        succes_publication:"Le post a bien été publié sur votre compte instagram",
        error_publication:"Echec lors du process de la publication"
    },
    facebook:{
        noaccount:"Vous n'avez pas de page facebook relié à l'application",
        authentication:"Une Erreur est survenue lors de la tentative de connection à facebook",
        no_select_account:"Veuillez sélectionner la page facebook sur laquelle vous voulez publier",
        error_image:"Une image n'est pas accepté par facebook",
        error_publication:"Une erreur c'est produite lors de la publication du contenu",
        succes_publication:"Le post a bien été publié sur votre compte facebook"

    },
    linkedin:{
        noaccount:"Vous n'avez pas de compte linkedin relié à l'application",
        no_select_account:"Veuillez sélectionner le compte linkedin sur lequel vous voulez publier",
        authentication:"une erreur c'est produite lors de l'authentifcation à linkedin",
        succes_publication:"Le post a bien été publié sur votre compte linkedin",
        error_publication:"Echec lors du process de la publication"
    }*/

    global:{
        error_request:'Une erreur est survenue, veuillez réessayer',
        no_select_account:"Veuillez sélectionner la page ou le compte sur lequel vous voulez publier",
        
        },
        instagram:{
        noaccount:"Vous n'avez pas de page Instagram business reliée à votre page Facebook",
        authentication:"Une erreur est survenue lors de la tentative de connexion à Instagram",
        error_img_caroussel:"Une image de votre carrousel n'est pas acceptée par Instagram",
        no_select_account:"Veuillez sélectionner la page Instagram sur laquelle vous voulez publier",
        succes_publication:"Le post a bien été publié sur votre compte Instagram",
        error_publication:"Échec lors du process de publication"
        },
        facebook:{
        noaccount:"Vous n'avez pas de page Facebook reliée à l'application",
        authentication:"Une erreur est survenue lors de la tentative de connexion à Facebook",
        no_select_account:"Veuillez sélectionner la page Facebook sur laquelle vous voulez publier",
        error_image:"Une image n'est pas acceptée par Facebook",
        error_publication:"Une erreur s'est produite lors de la publication du contenu",
        succes_publication:"Le post a bien été publié sur votre compte Facebook"
        
        },
        linkedin:{
        noaccount:"Vous n'avez pas de compte LinkedIn relié à l'application",
        no_select_account:"Veuillez sélectionner le compte LinkedIn sur lequel vous voulez publier",
        authentication:"Une erreur s'est produite lors de l'authentification à LinkedIn",
        succes_publication:"Le post a bien été publié sur votre compte LinkedIn",
        error_publication:"Échec lors du process de la publication"
        }
}



