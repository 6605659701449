export const social_helpers = {
    instagram:{
        id:'instagram',
        name:'instagram',
        color:'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)'
    },
    facebook:{
        id:'facebook',
        name:'facebook',
        color:'linear-gradient(to bottom, #3A5795 0%, #2B4886 100%)'
    },
    linkedin:{
        id:'linkedin',
        name:'linkedin',
        color:'#0077b5'
    }
}

