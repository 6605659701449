import { CSSProperties } from 'react'
import styles from './Avatar.module.scss'

type AvatarProps = {
  avatar: string | null | boolean
  prenom?: string
  nom?: string
  width?: string
  height?: string
  type: 'round' | 'normal'
}

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

export default function Avatar({
  avatar,
  width = '1rem',
  height = '1rem',
  type = 'round',
  prenom = 'AA',
  nom = 'AA',
}: AvatarProps): JSX.Element {
  
  
    const styled = {
    '--radius-avatar': type == 'round' ? '50%' : '0%',
    '--width-avatar': width,
    '--height-avatar': height,
    '--background-avatar-image': avatar ? `url(${avatar})` : 'none',
    '--background-avatar-position': avatar ? 'center' : 'center',
    '--background-avatar-color': avatar ? 'none' : 'var(--ui-primary-color)',
  } as CSSProperties
  

  return (
    <div style={styled} className={styles['avatar-container']}>
      {avatar ? '' : <span>{`${prenom.charAt(0)} ${nom.charAt(0)}`}</span>}
    </div>
  )
}
