import ReactDOM from 'react-dom'
//import * as serviceWorker from './serviceWorker'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import { toast } from 'react-toastify'
// REDUX
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './redux'
import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from './components/error-fallback/ErrorFallBack';
import config from './config';
import App from './App'


if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
}


// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}


ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      window.location.href = '/';
    }}
  >
    <App />
    </ErrorBoundary>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
/*
serviceWorker.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
    window.location.reload()
  },
})*/

serviceWorkerRegistration.register();

// serviceWorkerRegistration.register(toast)       DESACTIVé 231121 - pour essai passage pré-prod
//serviceWorkerRegistration.unregister()

/*   <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      window.location.href = '/';
    }}
  >
      <App />
    </ErrorBoundary> */