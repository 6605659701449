// import {
//   CHECK_AUTH,
//   SIGN_IN,
//   GET_TEMPORARY_CODE,
//   SEND_MAIL,
//   SEND_EMAIL_TO_ADMIN,
//   GET_NAVBAR,
//   GET_POSTS,
//   GET_CONTACTS,
//   GET_PARTNERS,
//   GET_ADMINS,
//   GET_PROFILE,
//   SET_PROFILE,
//   SET_PROFILE_PICTURE,
//   SET_NEW_PASSWORD,
//   GET_POST,
//   SET_LIKE,
//   SET_COMMENT,
//   GET_PDF,
//   ADMIN_SET_POST,
//   ADMIN_DELETE_POST,
//   ADMIN_GET_GROUPS,
//   ADMIN_GET_CATEGORIES,
//   ADMIN_GET_POSTS,
//   ADMIN_GET_POST_FOR_UPDATE
// } from "reduxactions/pci/types";
import {
  GET_POLLS,
  ADMIN_SET_POST,
  SET_POLL_ANSWER,
  ADMIN_DELETE_POST,
  DELETE_POLLS_NOTIFICATION,
} from '../../actions/pci/types'
import update from 'immutability-helper'

const INITIAL_STATE = {
  data: [],
  r: '',
  breadcrumb: false,
}

const pollsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_POLLS:
      // return action.payload.data.posts;
      if (action.payload.page === 1 && action.payload.data.posts.length === 0) {
        return update(state, {
          r: { $set: action.payload.r },
          data: {
            $set: false,
          },
          breadcrumb: {
            $set: action.payload.data.breadcrumb,
          },
        })
      }
      return update(state, {
        r: { $set: action.payload.r },
        data: {
          $set: action.payload.data.posts,
        },
        breadcrumb: {
          $set: action.payload.data.breadcrumb,
        },
      })
    case ADMIN_DELETE_POST:
      if (action.rubric_id === 'sondage') {
        return update(state, {
          data: {
            $unset: [action.post_index],
          },
        })
      } else {
        return state
      }
    case ADMIN_SET_POST:
      if (action.rubric_id === 'sondage') {
        return {
          data: [],
          r: '',
        }
      } else {
        return state
      }
    case SET_POLL_ANSWER:
      const poll_index_to_update = state.data.findIndex((poll) => poll.id === action.poll_id)

      const poll_to_update = state.data[poll_index_to_update]
      const new_total = poll_to_update.total_votes + action.answers.length
      const new_answers = []
      poll_to_update.reponses.forEach((answer, index) => {
        if (action.answers.includes(index)) {
          answer.me = true
          answer.nb_votes += 1
        }
        new_answers.push(answer)
      })
      return update(state, {
        data: {
          [poll_index_to_update]: {
            total_votes: { $set: new_total },
            reponses: { $set: new_answers },
          },
        },
      })
    case DELETE_POLLS_NOTIFICATION:
      const old_polls = state.data
      let polls = old_polls
      if (polls) {
        polls.forEach((poll) => {
          if (poll.new) {
            poll.new = false
          }
        })
        return update(state, {
          data: {
            $set: polls,
          },
        })
      } else {
        return state
      }
    default:
      return state
  }
}

export default pollsReducer
