import SearchIcon from '../../../icons/SearchIcon';
import React from 'react';
import styles from './SearchBarMobile.module.scss';
import { useSpring,animated } from 'react-spring';
import {CSSProperties, useEffect, useState,ChangeEvent,useRef} from 'react';
import { Cross } from '../../../icons/Cross';
import { useSearch } from '../useSearch';
import ListSearchMobile from './list-search-mobile/ListSearchMobile';
import {useLockBodyScroll} from 'react-use';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import {useHistory} from "react-router-dom";
import { useSelector } from 'react-redux';
export default function SearBarMobile({menu_active}){
    const [showSearch,setShowSearch] = useState(false);
    const [focus,setFocus] = useState(false);
    const [textSearch,setTextSearch] = useState('');
    const [finish,setFinish] = useState(false);
    const [heightDocument,setHeightDocument] = useState(document.documentElement.clientHeight);
    //HOOKS
    const {listSearch,setListSearch,loading} = useSearch(textSearch);


    useEffect(()=>{
        window.addEventListener('resize',()=>{
            setHeightDocument(document.documentElement.clientHeight)
        })
   
    },[])

    //useLockBodyScroll(showSearch);
    const history = useHistory();
    const searchSpring = useSpring({
      height:showSearch ? `${heightDocument}px` : '50px',
      onStart: ()=>{setFinish(false)},
      onRest:()=>{setFinish(true)}
    })

    const targetRef = useRef<HTMLDivElement>(null);
    let targetElement = null;
    useEffect(()=>{
        return ()=>{clearAllBodyScrollLocks();}
    },[])

    useEffect(()=>{
        if(showSearch){
            disableBodyScroll( targetRef.current)
        }
        else{
            enableBodyScroll( targetRef.current);  
        }
    },[showSearch])


    const styledInput = {
       '--border-input' : focus ? 'var(--primary-color)' : 'transparent',
       '--icon-color': focus ? '#000':'grey',
       '--border-modal':focus? "0" : "0.4rem",
       '--auto-scroll':showSearch && finish ? 'auto' : 'hidden',
       '--padding-modal':showSearch && finish ? '1rem 1rem 4rem 1rem' : '1rem 1rem 1rem 1rem' 
    } as CSSProperties

    function onChangeSearch(e:ChangeEvent<HTMLInputElement>){
        setTextSearch(e.target.value)
    }

    function onNavigateToSearchPage(){
        setShowSearch(false)
        history.push({
            pathname:'/search',
            search:`?value=${textSearch}&filter=all`
        })
    }

    const modalStyle = {
        '--auto-scroll':showSearch && finish ? 'auto' : 'hidden'
    } as CSSProperties

if(!menu_active){
    return(
        <animated.div  ref={targetRef}  style={{...searchSpring,...modalStyle}}  className={styles["search-bar-mobile-wrapper"]}>
            {!showSearch ? <div onClick={()=>setShowSearch(true)} className={styles["search-bar-mobile-container"]}>
                <SearchIcon/>
                <div>Rechercher</div>
            </div>
            :<div  className={styles["search-mobile-container"]}>
                <div className={styles["header-search"]}><button onClick={()=>setShowSearch(false)} className={styles["button-remove"]}><Cross/></button></div>
                <div className={styles["entitled-search"]}>Rechercher sur l'application :</div>
                <div style={styledInput} className={styles["search-input-container"]}>
                    <input value={textSearch} onChange={onChangeSearch} onFocus={()=>setFocus(true)} onBlur={()=>setFocus(false)} type={'text'} placeholder={`Recherchez sur l'application`}/>
                    <div className={styles["icon-search"]}><SearchIcon/></div>
                </div>
                <ListSearchMobile textSearch={textSearch} listSearch={listSearch} loading={loading}/>
                {textSearch.length > 0 && <button onClick={onNavigateToSearchPage} className={styles["button-more"]}>Voir plus de résultats</button> }
            </div>
            }
        </animated.div>
        )
    }
    return null
}