import BackButtonPage from '../../components/back-button/BackButtonPage'
import Navbar from '../../components/Navbar'
import ShoppingBag from '../../components/shop/ShoppingBag'
import styles from './ClassicLayout.module.scss';
import {useSelector} from 'react-redux';
import {useEffect,useState,Fragment, useRef, CSSProperties} from 'react';
import {useHistory} from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import Loading from '../../components/pgl/Loading';


type ClassicLayoutProps = {
    title:string,
    fetchData?:any,
    has_back:boolean,
    breadcrumb:any,
    children:JSX.Element | JSX.Element[],
    errorText:string | boolean
}

export default function ClassicLayout({
    title,
    fetchData,
    has_back,
    breadcrumb,
    children,
    errorText="Une erreur est survenue"
    }:ClassicLayoutProps):JSX.Element{

    const history = useHistory()

    /*@ts-ignore next-line*/ 
    const authentication = useSelector(state=>state?.auth.authentication);
    const [fetchInfo,setFetchInfo] = useState(false);
    const [error,setError] = useState<boolean | string>(false)

    useEffect(()=>{
        //SI NON AUTH GO CONNEXION
            if (!authentication.id || authentication.type === 'prem') 
                history.replace('/connexion')
            else{
                window.scrollTo(0, 0)
                setFetchInfo((value)=>!value)
            }
            },[]);
    
    useEffect(()=>{
        if(fetchInfo){
           fetchData &&  fetchData()
           .then((response)=>{
            console.log(response)
            setFetchInfo(false);
            if(response === undefined || response.data.hasOwnProperty('error')){
                setFetchInfo(false);
                setError(errorText ? errorText : response.data.error ?  response.data.error  : true)
            }

           })
           .catch((error=>{
            console.log(error)
            setFetchInfo(false);
            setError(errorText??true)
           }))
        }
    },[fetchInfo]);

    
    return(
        <div className={styles["page-container"]}>
              <Navbar title={title} />
              {has_back ? <BackButtonPage classNames={styles["arrowbutton"]}/> : null}
              <ShoppingBag from_global_app={true} />
              <Fragment>
                {
                fetchInfo ?
                    <Loading/>
                    :

                    error ? <div style={{'--variable':'40px'} as CSSProperties}  className={styles['error-page']}>{error}</div>
                    
                    :<Fragment>
                        {breadcrumb ? <Breadcrumb items={breadcrumb} /> : null}
                        {children}
                    </Fragment>
                    }
              </Fragment>

        </div>
    )
}