export default function EngrenageIcon({width,height,fill}){



  return(
    <svg version="1.1" width={width} height={height} x="0px" y="0px"
    viewBox="0 0 28.3 28.3">

 <g>
   <path  d="M14.1,9.8c-2.4,0-4.4,2-4.4,4.4s2,4.4,4.4,4.4s4.4-2,4.4-4.4S16.6,9.8,14.1,9.8z M14.1,17
     c-1.6,0-2.9-1.3-2.9-2.9s1.3-2.9,2.9-2.9s2.9,1.3,2.9,2.9S15.7,17,14.1,17z"/>
   <path d="M25.1,11h-0.2c-0.5,0-0.9-0.3-1.1-0.6c0-0.1,0-0.2-0.1-0.2c-0.2-0.5-0.1-1,0.2-1.4l0.1-0.1
     c0.1,0,0.1-0.1,0.1-0.1c0.6-0.6,0.9-1.4,0.9-2.3c0-0.9-0.4-1.6-1-2.2c-1.3-1.2-3.3-1.1-4.5,0.1l-0.1,0.1c-0.2,0.2-0.5,0.4-0.9,0.4
     c-0.7,0-1.3-0.6-1.3-1.3V3.2c0-1.8-1.4-3.2-3.2-3.2C12.4,0,11,1.4,11,3.2v0.2c0,0.5-0.3,0.9-0.6,1.1c-0.1,0-0.2,0-0.2,0.1
     c-0.5,0.2-1,0.1-1.4-0.2L8.7,4.2c0,0-0.1-0.1-0.1-0.1C7.9,3.5,7.1,3.2,6.2,3.2c-0.9,0-1.6,0.4-2.2,1C2.8,5.5,2.9,7.5,4.1,8.7
     l0.1,0.1c0.4,0.4,0.5,0.9,0.2,1.4c-0.2,0.5-0.6,0.8-1.1,0.8H3.2c-1.8,0-3.2,1.4-3.2,3.2c0,1.8,1.4,3.2,3.2,3.2h0.2
     c0.3,0,0.6,0.1,0.9,0.4c0.5,0.5,0.5,1.3,0,1.8l-0.1,0.1c-1.2,1.2-1.2,3.3,0,4.5c1.2,1.2,3.3,1.2,4.5,0l0.1-0.1
     c0.4-0.4,0.9-0.5,1.4-0.2c0.5,0.2,0.8,0.6,0.8,1.1v0.1c0,1.8,1.4,3.2,3.2,3.2c1.8,0,3.2-1.4,3.2-3.2v-0.2c0-0.3,0.1-0.6,0.4-0.9
     c0.5-0.5,1.3-0.5,1.8,0l0.1,0.1c1.2,1.2,3.3,1.2,4.5,0c1.2-1.2,1.2-3.3,0-4.5l-0.1-0.1c-0.3-0.4-0.5-0.9-0.3-1.3c0,0,0,0,0-0.1
     c0.2-0.5,0.7-0.8,1.2-0.8h0.1c1.8,0,3.2-1.4,3.2-3.2S26.9,11,25.1,11z M25.1,15.8H25c-1.1,0-2,0.6-2.5,1.6c0,0,0,0,0,0.1
     c-0.5,1-0.2,2.2,0.6,3l0.1,0.1c0.7,0.7,0.7,1.7,0,2.4c-0.7,0.7-1.7,0.7-2.4,0l-0.1-0.1c-0.5-0.5-1.2-0.8-2-0.8
     c-0.7,0-1.4,0.3-2,0.8c-0.5,0.5-0.8,1.2-0.8,1.9v0.2c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7V25c0-1.1-0.7-2.1-1.8-2.5
     c-0.4-0.2-0.7-0.2-1.1-0.2c-0.7,0-1.4,0.3-1.9,0.8l-0.1,0.1c-0.6,0.6-1.8,0.6-2.4,0c-0.3-0.3-0.5-0.7-0.5-1.2
     c0-0.5,0.2-0.9,0.5-1.2l0.1-0.1c0.5-0.5,0.8-1.2,0.8-2c0-0.7-0.3-1.4-0.8-2c-0.5-0.5-1.2-0.8-1.9-0.8H3.2c-0.9,0-1.7-0.8-1.7-1.7
     c0-0.9,0.8-1.7,1.7-1.7h0.1c1.1,0,2.1-0.7,2.5-1.8c0.5-1,0.2-2.2-0.6-3.1L5.2,7.7C4.5,7,4.5,6,5.1,5.3c0.3-0.3,0.7-0.5,1.2-0.5
     c0.5,0,0.9,0.2,1.2,0.5c0,0,0.1,0.1,0.1,0.1l0.1,0.1c0.8,0.8,1.9,1,2.9,0.6c0.1,0,0.2,0,0.2-0.1c1-0.4,1.7-1.4,1.7-2.5V3.2
     c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7v0.1c0,1.5,1.2,2.7,2.8,2.8c0.7,0,1.4-0.3,1.9-0.8l0.1-0.1c0.6-0.7,1.7-0.7,2.4-0.1
     c0.3,0.3,0.5,0.7,0.5,1.2c0,0.5-0.1,0.9-0.5,1.2l-0.2,0.2c-0.8,0.8-1,1.9-0.6,2.9c0,0.1,0,0.2,0.1,0.2c0.4,1,1.4,1.7,2.5,1.7h0.2
     c0.9,0,1.7,0.8,1.7,1.7S26.1,15.8,25.1,15.8z"/>
 </g>
 </svg>
 
  )
}


EngrenageIcon.defaultProps = {
  width:'20px',
  height:'20px',
  fill:'#1b1b1b'
}