
import ListTabItem from '../../list-tab-item/ListTabItem';
import ItemSelection from './ItemSelection';
export default function MenuSelection({elements,onSelect,categorieSelected}){
 
    return(
     
          <ListTabItem> 
               {elements.map((item,key)=>{
               return <ItemSelection key={key} onSelect={onSelect}  item={item} categorieSelected={categorieSelected} /> })}
         </ListTabItem>
      
    )
}
