import { forwardRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styles from './ButtonIcon.module.scss';


type ButtonIconProps = {
    icon:JSX.Element,
    onClick:()=>void
}



export default forwardRef(
function ButtonIcon({icon,onClick}:ButtonIconProps,ref){
    const [hover,setHover] = useState(false);
    const styleIcon = useSpring({
       fill:hover ? 'var(--primary-color)' : 'var(--grey-color)'
    })

    const AnimatedIcon = animated(icon)

    return(
        <animated.button 
                ref={ref}
                onMouseEnter={(prev)=>setHover(true)}
                onMouseLeave={(prev)=>setHover(false)}
                onClick={onClick} 
                className={styles["button-icon"]}>
            {<AnimatedIcon style={styleIcon}/>}
        </animated.button>
    )

})