import ButtonWrapper from '../ButtonWrapper';
import { sizeType } from '../type';
import styles from './ButtonText.module.scss';


type ButtonText ={
    text:string,
    onClick:()=>void,
    type:string,
    size:sizeType,
    isFetch:boolean
}

export default function ButtonText({text,onClick,type,isFetch}):JSX.Element{



    return(
        <ButtonWrapper type={type} onClick={onClick} size={undefined}>
            {isFetch ? <div>chargement...</div> :text}
        </ButtonWrapper>
    )
}