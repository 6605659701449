import { useState, useEffect } from 'react'

export default function useWindowDimensions(ptsRupture: number) {
  const [windowDimensions, setWindowDimensions] = useState<number>(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < ptsRupture) {
        setWindowDimensions(window.innerWidth)
      } else {
        setWindowDimensions(window.innerWidth)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return windowDimensions
}
