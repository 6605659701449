const AvatarDefault = ({fill,height,width}) => (
  <svg
    height={height}
    width={width}
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path fill={fill} d="M12-.009c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.382-12-12-12zm-3 8c0-1.656 1.345-3 3-3s3 1.344 3 3v1c0 1.656-1.345 3-3 3s-3-1.344-3-3v-1zm3 13a8.973 8.973 0 0 1-6.355-2.633l.842-2.988a1.992 1.992 0 0 1 1.892-1.379h7.234c.887 0 1.63.58 1.892 1.379l.832 3.004A8.97 8.97 0 0 1 12 20.991z" />
  </svg>
)

export default AvatarDefault
