import ButtonText from "./button-text";
import ButtonIcon from "./button-icon";
import ButtonClose from "./button-close";
import { forwardRef,ComponentProps,ElementType } from "react";

const Components = {
    icon:ButtonIcon,
    text:ButtonText,
    close:ButtonClose

} as {[key:string]:ElementType} & ComponentProps<ElementType>

export default forwardRef(function EasyButton(
    {name,...props}
    :{name:string},
    ref):JSX.Element | null{
    let Component = Components[name];

    if(Component!=undefined) return <Component ref={ref} {...props} />
    return null
});