
import axios from "axios";
import { useState } from "react";
import api from "../../config/pci/api";
import useDidMountEffect from "../../hooks/useDidmountEffect";
import {useSelector} from 'react-redux';
let cancelToken;
export function useSearch(textSearch){
    const [listSearch,setListSearch] = useState([]);
    const [loading,setLoading] = useState(false);
    const authentication = useSelector(state=>state.auth.authentication);

    async function getSearch(result){

        const searchTerm = result;
        //Check if there are any previous pending requests
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.");
        }
    
        //Save the cancel token for the current request
        cancelToken = axios.CancelToken.source();
        setLoading(true)
        try {
           
            const results = await api.get(
            `/litesearch?user=${authentication.id}&nonce=${authentication.nonce}&search=${searchTerm}`,
            { cancelToken: cancelToken.token } //Pass the cancel token to the current request
            )
            // @ts-ignore:next-line
            if(!results?.data.hasOwnProperty('error')){
                if(searchTerm.length==0){
                    setLoading(false)
                    return setListSearch([]) 
                }
                // @ts-ignore:next-line
                setListSearch(results?.data?.results)
               
                setLoading(false)
            }else{
                setListSearch([])
                setLoading(false)
            }
        } catch (error) {
            
            }
    }

    useDidMountEffect(()=>{
        getSearch(textSearch)
    },[textSearch])

    return {listSearch,loading,setListSearch}
}