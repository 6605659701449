import React from 'react';

export default ({...props}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.49 38">
       
          <g transform="translate(-0.26 -1)">
            <g id="mask-4">
              <polygon
                id="path-3"
                fill={'var(--ui-primary-color)'}
                fillRule="evenodd"
                points="8.23 9.59 33.36 9.59 33.36 30.41 8.23 30.41 8.23 9.59"
              />
            </g>
          </g>
   
      <g id="bulle">
        <path
          fillRule="evenodd"
      
          d="M20.74,1A19,19,0,0,0,4.19,29.31,7.07,7.07,0,0,1,.81,35.2a1.17,1.17,0,0,0-.54,1.16,1.15,1.15,0,0,0,.85,1A12.56,12.56,0,0,0,10.46,36l.06,0A19,19,0,1,0,20.74,1Z"
          transform="translate(-0.26 -1)"
        />
      </g>
      <g id="firefox-1">
        <g id="App">
          <g >
            <path
              id="Fill-1"
              fill={'var(--ui-primary-color)'}
              fillRule="evenodd"
              d="M29.11,18.06V29.12H23.57v-5a.65.65,0,0,0-.64-.65H18.67a.65.65,0,0,0-.64.65v5H12.49V18.06L15,16s5.81-4.81,5.85-4.88ZM14.7,12h1.16V13.5l-1.16.95Zm18.43,7.53-11.3-9.22-.62-.53a.64.64,0,0,0-.82,0l-3.26,2.74V11.32a.64.64,0,0,0-.64-.65H14.06a.65.65,0,0,0-.64.65v4.26L8.47,19.67a.66.66,0,0,0-.09.91.64.64,0,0,0,.49.23.62.62,0,0,0,.41-.14l1.93-1.6V29.76a.64.64,0,0,0,.64.65h6.82a.65.65,0,0,0,.64-.65v-5h3v5a.64.64,0,0,0,.64.65h6.82a.65.65,0,0,0,.64-.65V18.91l1.93,1.58a.63.63,0,0,0,.9-.09.66.66,0,0,0-.09-.91Z"
              transform="translate(-0.26 -1)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
