// import {
//   CHECK_AUTH,
//   SIGN_IN,
//   GET_TEMPORARY_CODE,
//   SEND_MAIL,
//   SEND_EMAIL_TO_ADMIN,
//   GET_NAVBAR,
//   GET_POSTS,
//   GET_CONTACTS,
//   GET_PARTNERS,
//   GET_ADMINS,
//   GET_PROFILE,
//   SET_PROFILE,
//   SET_PROFILE_PICTURE,
//   SET_NEW_PASSWORD,
//   GET_POST,
//   SET_LIKE,
//   SET_COMMENT,
//   GET_PDF,
//   ADMIN_SET_POST,
//   ADMIN_DELETE_POST,
//   ADMIN_GET_GROUPS,
//   ADMIN_GET_CATEGORIES,
//   ADMIN_GET_POSTS,
//   ADMIN_GET_POST_FOR_UPDATE
// } from "reduxactions/pci/types";
import {
  GET_PROFILE,
  SET_PROFILE_PICTURE,
  RESET_PROFILE_MESSAGE,
  SET_PROFILE_MESSAGE,
  SET_PROFILE,
  SET_PROFILE_STATUS,
} from '../../actions/pci/types'
import update from 'immutability-helper'

const INITIAL_STATE = {
  data: {},
  message: false,
}

const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        data: action.payload,
      }
    case SET_PROFILE:
      return {
        ...state,
        message: action.payload.mess,
      }
    case RESET_PROFILE_MESSAGE:
      return {
        ...state,
        message: false,
      }
    case SET_PROFILE_MESSAGE:
      return {
        ...state,
        message: action.message,
      }
    case SET_PROFILE_PICTURE:
      return update(state, { data: { photo: { $set: action.payload.photo } } })
    case SET_PROFILE_STATUS:
      const date_of_the_day = new Date()
      const month_of_the_day =
        date_of_the_day.getMonth() + 1 > 9 ? date_of_the_day.getMonth() + 1 : `0${date_of_the_day.getMonth() + 1}`

      const status =
        `${date_of_the_day.getDate()}/${month_of_the_day}/${date_of_the_day.getFullYear()}` === action.date_begin
          ? 'offline'
          : 'online'
      return update(state, {
        data: {
          status: { $set: status },
          date_begin: {
            $set: action.date_begin === false ? null : action.date_begin,
          },
          date_end: {
            $set: action.date_end === false ? null : action.date_end,
          },
        },
        message: { $set: action.payload.msg },
      })
    default:
      return state
  }
}

export default profileReducer
