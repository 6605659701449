import _ from 'lodash';
import {createSelector} from 'reselect';


  export const partnerListByCategorie = createSelector([
    (state) => state.partners,
    (state,categorie) => categorie,
  ],
    (contacts,categorie) =>{
         //FOR LETTER
      if(categorie === "nom"){
   
       return contacts.data.reduce((r, e) => {
         //e.hasOwnProperty("gps") && e.gps.hasOwnProperty(categorie) && categorie!=="nom") || categorie ==="nom" && e.id != "ghost"
        if(e.nom || (e.id != "ghost")){
        let group = e.nom[0]
          if(!r[group]) {
              
                r[group] = [
                  {name:`${e.nom} ${e.prenom}`,
                  additif:e.hasOwnProperty("gps") && e.gps.hasOwnProperty("partner") ? /*e.gps.partner.gp[0].nom*/"ttoto" : "-",
                  id:e.id,
                  favori:e.favori
              }]
    
            }
            else{
                r[group].push({
                name:`${e.nom} ${e.hasOwnProperty("prenom") ? e.prenom : ""}`,
                additif:e.hasOwnProperty("gps") && e.gps.hasOwnProperty("partner") ? /*e.gps.partner.gp[0].nom*/ "titi" : "-",
                id:e.id,
                favori:e.favori
              });  
            } 
            
          }
          return r;
           
          },Object.create(null))
        }
          else if(categorie == "favoris"){
      
           var list =  contacts.data.filter(i=>i.favori === true)
           .map(item=>{
            return {

            name:`${item.nom} ${item.prenom}`, 
            additif:item.hasOwnProperty("gps") && item.gps.hasOwnProperty("partner") ? item.gps.partner.gp[0].nom : "-",
            favori:item.favori,
            id:item.id,
          }
        });
           if(list.length> 0){
            return {
              "Favoris":list
            }
           }else{
             return Object.create({})
           }
         

          }else{
          //FOR GROUP
          return  _.groupBy(contacts.data.filter(adherent => adherent.hasOwnProperty("gps") && adherent.gps.hasOwnProperty(categorie))
          .map((item,i)=>{
              return item.gps[categorie].gp.map(i =>{
                   return {
                 groupe:i.nom,
                 name:`${item.nom} ${item.prenom}`,
                // additif:categorie  === "partner" ?  item.fonction : item.hasOwnProperty("gps") && item.gps.hasOwnProperty("partner") ? item.gps.partner.gp[0].nom : "-",
                additif:(categorie  === "partner" && item.hasOwnProperty('fonction') && item.fonction !=="" ) ? item.fonction :  (categorie  === "partner" && item.hasOwnProperty('fonction') && item.fonction =="" ) ? "-" : item.hasOwnProperty("gps") && item.gps.hasOwnProperty("partner") ? item.gps.partner.gp[0].nom : "-",
                id:item.id,
                 favori:item.favori,
                 id_groupe:i.id,
                 
               }
             })
           }).flat(),"id_groupe")

          }
        }

          
        
  )



  export const partnerListByCategories = createSelector([
    (state) => state.partners,
    (state,categorie) => categorie,
  ],
    (contacts,categorie) =>{
         //FOR LETTER
      if(categorie === "nom"){
       var name_list =  contacts.data.reduce((r, e) => {
        if(( e.hasOwnProperty('gps') && e.gps.hasOwnProperty(categorie) && categorie!=="nom") || categorie ==="nom" && e.id != 'ghost'){
        let group = e.nom[0]
          if(!r[group]) {
              
                r[group] = [
                  {name:`${e.nom} ${e.prenom}`,
                  additif:e.hasOwnProperty("gps") && e.gps.hasOwnProperty("partner") ? e.gps.partner.gp[0].nom : "-",
                  id:e.id,
                  favori:e.favori
              }]
    
            }
            else{
                r[group].push({
                name:`${e.nom} ${e.prenom}`,
                additif:e.hasOwnProperty("gps") && e.gps.hasOwnProperty("partner") ? e.gps.partner.gp[0].nom : "-",
                id:e.id,
                favori:e.favori
              });  
            } 
            
          }
          return r;
           
          },Object.create(null))

          return Object.keys(name_list).map((item,key)=>{
            return {
              groupe:item,
              data:name_list[item]
            }
          }).filter(i=>i.groupe !=='undefined')
        }
          else if(categorie == "favoris"){

           var list =  contacts.data.filter(i=>i.favori === true)
           .map(item=>{
             
            return {

            name:`${item.nom} ${item.prenom}`, 
            additif:item.hasOwnProperty("gps") && item.gps.hasOwnProperty("partner") ? item.gps.partner.gp[0].nom : "-",
            favori:item.favori,
            id:item.id,
          }
        });
           if(list.length> 0){
            return [{
              groupe:'Favoris',
              data:list
            }]
           }else{
             return []
           }
         

          }else{
          //FOR GROUP
       
          var groupes =  _.groupBy(contacts.data.filter(adherent => adherent.hasOwnProperty("gps") && adherent.gps.hasOwnProperty(categorie))
          .map((item,i)=>{

              return item.gps[categorie].gp.map(i =>{
                  
                return {
                 groupe:i.nom,
                 name:`${item.nom} ${item.prenom}`,
                  
                 additif: (categorie  === "partner" && item.hasOwnProperty('fonction') && item.fonction !=="" ) ? item.fonction : item.hasOwnProperty("gps") && item.gps.hasOwnProperty("partner") ? item.gps.partner.gp[0].nom : "-",
                 id:item.id,
                 favori:item.favori,
                 id_groupe:i.id,
                    
               }
             })
           }).flat(),"id_groupe")
           return Object.keys(groupes).map((item,key)=>{
             return {
               groupe:groupes[item][0].groupe,
               data:groupes[item],
               id:groupes[item][0].id_groupe
             }
           })
          }
        }

          
        
  )


  export const nameOfGroup = createSelector([
    (state) => state.partners,
    (state,categorie) => categorie,
  ],
  (contacts,categorie) =>{

    var list = contacts.data.filter(adherent => adherent.hasOwnProperty("gps") && adherent.gps.hasOwnProperty(categorie));
   return _.uniqBy(list.map((item,key)=>{
     return  item.gps[categorie].gp.map(i=>{
            return i
        })  
    }).flat()
    ,'id')
   

  })